import countriesAndTimezones from 'countries-and-timezones';
import currenciesData from 'currency-codes/data';
import codes from 'country-calling-code';

export const countryCallingCodes = codes;

export const STATUS_DEFAULT = 0;
export const STATUS_APPROVED = 1;
export const STATUS_REJECTED = 2;
export const STATUS_DENIED = 2;

export const USER_STATUS_DEFAULT = 0;
export const USER_STATUS_BLOCKED = 1;

export const ORDER_STATUS_PENDING = 0;
export const ORDER_STATUS_COMPLETE = 1;
export const ORDER_STATUS_CANCELED = 2;
export const ORDER_STATUS_REFUNDED = 3;
export const ORDER_STATUS_REFUNDED_BY_SUPPLIER = 4;
export const ORDER_STATUS_PARTIALLY_REFUNDED_BY_SUPPLIER = 5;

export const orderStatusLabels = {
  en: {
    [ORDER_STATUS_PENDING]: 'Pending',
    [ORDER_STATUS_COMPLETE]: 'Complete',
    [ORDER_STATUS_CANCELED]: 'Canceled',
    [ORDER_STATUS_REFUNDED]: 'Refunded',
    [ORDER_STATUS_REFUNDED_BY_SUPPLIER]: 'Refunded by supplier',
    [ORDER_STATUS_PARTIALLY_REFUNDED_BY_SUPPLIER]:
      'Partially refunded by supplier',
  },
  pl: {
    [ORDER_STATUS_PENDING]: 'W trakcie',
    [ORDER_STATUS_COMPLETE]: 'Zapłacone',
    [ORDER_STATUS_CANCELED]: 'Anulowane',
    [ORDER_STATUS_REFUNDED]: 'Zwrócone',
    [ORDER_STATUS_REFUNDED_BY_SUPPLIER]: 'Zwrócone przez organizatora',
    [ORDER_STATUS_PARTIALLY_REFUNDED_BY_SUPPLIER]:
      'Częściowo zwrócone przez organizatora',
  },
};

export const AVATARS_LIMIT_PER_PAGE = 12;

export const noticePeriods = [
  {
    id: 0,
    label: 'None',
  },
  {
    id: 1,
    label: '1 month',
  },
  {
    id: 2,
    label: '2 months',
    isDefault: true,
  },
  {
    id: 3,
    label: '3 months',
  },
  {
    id: 4,
    label: '4 months',
  },
  {
    id: 5,
    label: '5+ months',
  },
];

export const currencies = currenciesData
  .map((i, index) => ({
    id: String(index),
    value: i.code.toLocaleLowerCase(),
    label: i.code,
  }))
  .filter((i) => ['USD', 'EUR', 'PLN'].includes(i.label));

export const salaryUnits = [
  {
    id: '0',
    value: 1 / 12 / 21 / 8,
    reverseValue: 12 * 21 * 8,
    label: 'hour',
  },
  {
    id: '1',
    value: 1 / 12 / 21,
    reverseValue: 12 * 21,
    label: 'day',
  },
  {
    id: '2',
    value: 1 / 12,
    reverseValue: 12,
    label: 'month',
  },
  {
    id: '3',
    value: 1,
    reverseValue: 1,
    label: 'year',
  },
];

export const defaultSalaryCurrencyId = currencies.find(
  (i) => i.label === 'USD'
).id;
export const defaultSalaryUnit = 'hour';
export const defaultSalaryUnitId = salaryUnits.find(
  (i) => i.label === defaultSalaryUnit
).id;

export const testTypes = [
  {
    value: '0',
    label: 'Coding',
  },
  {
    value: '1',
    label: 'Test',
  },
  {
    value: '2',
    label: 'Interview',
  },
];

export const contractTypes = [
  {
    value: '0',
    label: 'Permanent',
  },
  {
    value: '1',
    label: 'Temporary',
  },
];

export const contractTimes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
  (item) => {
    return {
      id: item,
      label: `${item} month(s)`,
    };
  }
);

export const workPaceLabels = [
  '',
  'Very chill',
  'Chill',
  'Normal',
  'Fast',
  'Very fast',
];

export const levelBreaks = [0.3, 0.5, 0.7, 0.9];

export const countries = countriesAndTimezones.getAllCountries();
export const countriesOptions = [];
// eslint-disable-next-line
for (const [key, value] of Object.entries(countries)) {
  countriesOptions.push({
    label: value.name,
    value: value.id,
    timezones: value.timezones,
  });
}
countriesOptions.sort((a, b) => a.label.localeCompare(b.label));

export const timezones = countriesAndTimezones.getAllTimezones();
export const timezonesOptions = [];
// eslint-disable-next-line
for (const [key, value] of Object.entries(timezones)) {
  timezonesOptions.push({
    label: value.name,
    value: value.name,
    countries: value.countries,
    utcOffset: value.utcOffset,
  });
}
timezonesOptions.sort((a, b) => a.label.localeCompare(b.label));

export const USER_JOB_STATUSES = {
  0: 'Pending',
  1: 'Accepted',
  2: 'Rejected',
};

export const MODE_LIST = 0;
export const MODE_PROFILE = 1;

export const subscriptionPlans = [
  {
    id: 0,
    label: 'Free',
  },
  {
    id: 1,
    label: 'Pro',
  },
  {
    id: 2,
    label: 'Business',
  },
];

export const languageCodes = [
  'aa',
  'ab',
  'ae',
  'af',
  'ak',
  'am',
  'an',
  'ar',
  'as',
  'av',
  'ay',
  'az',
  'ba',
  'be',
  'bg',
  'bh',
  'bi',
  'bm',
  'bn',
  'bo',
  'br',
  'bs',
  'ca',
  'ce',
  'ch',
  'co',
  'cr',
  'cs',
  'cu',
  'cv',
  'cy',
  'da',
  'de',
  'dv',
  'dz',
  'ee',
  'el',
  'en',
  'eo',
  'es',
  'et',
  'eu',
  'fa',
  'ff',
  'fi',
  'fj',
  'fo',
  'fr',
  'fy',
  'ga',
  'gd',
  'gl',
  'gn',
  'gu',
  'gv',
  'ha',
  'he',
  'hi',
  'ho',
  'hr',
  'ht',
  'hu',
  'hy',
  'hz',
  'ia',
  'id',
  'ie',
  'ig',
  'ii',
  'ik',
  'io',
  'is',
  'it',
  'iu',
  'ja',
  'jv',
  'ka',
  'kg',
  'ki',
  'kj',
  'kk',
  'kl',
  'km',
  'kn',
  'ko',
  'kr',
  'ks',
  'ku',
  'kv',
  'kw',
  'ky',
  'la',
  'lb',
  'lg',
  'li',
  'ln',
  'lo',
  'lt',
  'lu',
  'lv',
  'mg',
  'mh',
  'mi',
  'mk',
  'ml',
  'mn',
  'mo',
  'mr',
  'ms',
  'mt',
  'my',
  'na',
  'nb',
  'nd',
  'ne',
  'ng',
  'nl',
  'nn',
  'no',
  'nr',
  'nv',
  'ny',
  'oc',
  'oj',
  'om',
  'or',
  'os',
  'pa',
  'pi',
  'pl',
  'ps',
  'pt',
  'qu',
  'rm',
  'rn',
  'ro',
  'ru',
  'rw',
  'sa',
  'sc',
  'sd',
  'se',
  'sg',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sq',
  'sr',
  'ss',
  'st',
  'su',
  'sv',
  'sw',
  'ta',
  'te',
  'tg',
  'th',
  'ti',
  'tk',
  'tl',
  'tn',
  'to',
  'tr',
  'ts',
  'tt',
  'tw',
  'ty',
  'ug',
  'uk',
  'ur',
  'uz',
  've',
  'vi',
  'vo',
  'wa',
  'wo',
  'xh',
  'yi',
  'yo',
  'za',
  'zh',
  'zu',
];

export const languageCodesOptions = languageCodes.map((l) => ({
  label: l,
  value: l,
}));

export const defaultLanguageCode = 'en';
export const defaultLanguageCodeOption = languageCodesOptions.find(
  (i) => i.value === defaultLanguageCode
);
export const priceCategories = [
  { priceCategoryId: 'infant', position: 0, ageFrom: 0, ageTo: 1, discount: 1 },
  {
    priceCategoryId: 'child',
    position: 1,
    ageFrom: 2,
    ageTo: 12,
    discount: 0.5,
  },
  {
    priceCategoryId: 'youth',
    position: 2,
    ageFrom: 13,
    ageTo: 17,
    discount: 0.2,
  },
  {
    priceCategoryId: 'adult',
    position: 3,
    ageFrom: 18,
    ageTo: 64,
    isDefault: true,
  },
  {
    priceCategoryId: 'senior',
    position: 4,
    ageFrom: 65,
    ageTo: 0,
    discount: 0.2,
  },
  {
    priceCategoryId: 'student',
    position: 5,
    ageFrom: 18,
    ageTo: 26,
    discount: 0.2,
  },
  {
    priceCategoryId: 'military',
    position: 6,
    ageFrom: 18,
    ageTo: 0,
    discount: 0.2,
  },
  { priceCategoryId: 'eu', position: 7, ageFrom: 0, ageTo: 0 },
  { priceCategoryId: 'participant', position: 8, ageFrom: 0, ageTo: 0 },
].sort((a, b) => a.position - b.position);

export const MODE_USER = 0;
export const MODE_EVENT = 1;
