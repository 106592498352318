import { call, put, takeLatest } from 'redux-saga/effects';

import * as ordersSlice from '../app/ordersSlice';
import { IS_ENV_DEVELOPMENT, config } from '../../config';
import { showNotification } from '../../core/utils';
import { getAuthHeaders } from '../utils';

// const createOrder = async ({ payload }) => {
//   const response = await fetch(
//     `${config.appApiUrl}/orders/create-order-session`,
//     {
//       method: 'POST',
//       mode: 'cors',
//       body: JSON.stringify(payload),
//       headers: {
//         ...getAuthHeaders(),
//         'Content-Type': 'application/json',
//       },
//     }
//   );
//   const responseJSON = await response.json();
//   return responseJSON;
// };

// export function* createOrderSaga({ payload }) {
//   const { error, data } = yield call(createOrder, { payload });
//   if (error) {
//     showNotification({
//       message: error,
//       type: 'error',
//     });
//     return;
//   }
//   window.location = data.redirectUrl;
//   // yield put(appSlice.setOrder(data.order));
// }

// const fetchLatestOrder = async ({ payload }) => {
//   const response = await fetch(`${config.appApiUrl}/orders/latest`, {
//     method: 'GET',
//     mode: 'cors',
//     headers: {
//       ...getAuthHeaders(),
//       'Content-Type': 'application/json',
//     },
//   });
//   const responseJSON = await response.json();
//   return responseJSON;
// };

// export function* fetchLatestOrderSaga({ payload }) {
//   const { error, data } = yield call(fetchLatestOrder, { payload });
//   if (error) {
//     showNotification({
//       message: error,
//       type: 'error',
//     });
//     return;
//   }
//   yield put(appSlice.setOrder(data));
//   if (data.status) {
//     yield usersSagas.fetchUserDataSaga({ payload: {} });

//     showNotification({
//       message: 'Balance recharged',
//       type: 'success',
//     });

//     window?.gtag?.('event', 'order_completed', {
//       event_category: 'engagement',
//       event_label: 'order_completed',
//       value: 1,
//       currency: 'USD',
//     });

//     if (redirectToLandingPage()) {
//       return;
//     }

//     window.navigateTo(`/characters`);
//   }
// }

const fetchOrders = async () => {
  const response = await fetch(`${config.appApiUrl}/orders/list`, {
    method: 'GET',
    mode: 'cors',
    // body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* fetchOrdersSaga() {
  const payload = yield call(fetchOrders);
  yield put(ordersSlice.setItems(payload));
}

// function* fetchOrdersSuccessSaga({ payload }) {
//   yield put(ordersSlice.setItems(payload));
// }

const createOrder = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/orders`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* createOrderSaga(action) {
  yield put(ordersSlice.setStatusPending());
  const { products } = action.payload;

  const isAdmin = localStorage?.getItem('isAdmin');
  if (!isAdmin && !IS_ENV_DEVELOPMENT) {
    const sumOfAmounts = products.reduce(
      (acc, product) => acc + Number(product.totalAmount),
      0
    );
    const googleAnalyticsEvent = {
      value: sumOfAmounts,
      currency: 'PLN',
      tax: 0,
      shipping: 0,
      items: products.map((product) => ({
        id: product.eventSlotId,
        name: product.event?.name,
        category: 'tour',
        quantity: product.quantity,
        price: Number(product.totalAmount),
      })),
    };

    window?.gtag?.('event', 'begin_checkout', googleAnalyticsEvent);
    window?.gtag?.('event', 'begin_checkout', {
      ...googleAnalyticsEvent,
      send_to: config.googleAdsConversionId,
    });

    products.forEach((product) => {
      const { eventSlotId, event, totalAmount } = product;
      window?.fbq?.('track', 'InitiateCheckout', {
        content_name: event?.name,
        content_ids: [eventSlotId],
        eventref: '',
        num_items: 1,
        currency: 'PLN',
        value: Number(totalAmount),
      });
    });
  }

  const order = yield call(createOrder, action);
  const { error } = order;
  if (error) {
    yield put(ordersSlice.setStatusDefault());
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  window.location.href = order.url;
}

const saveReview = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/reviews`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* saveReviewSaga(action) {
  const { error, url } = yield call(saveReview, action);
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: 'Recenzja zapisana',
    type: 'success',
  });
  if (url) {
    window.location.href = url;
  }
}

// function* saveReviewSuccessSaga() {
//   showNotification({
//     message: 'Recenzja zapisana',
//     type: 'success',
//   });
// }

export function* ordersSagas() {
  yield takeLatest('FETCH_ORDERS', fetchOrdersSaga);
  yield takeLatest('CREATE_ORDER', createOrderSaga);
  yield takeLatest('SAVE_REVIEW', saveReviewSaga);
}
