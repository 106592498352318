import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { registerLocale } from 'react-datepicker';
import { Helmet } from 'react-helmet';
import pl from 'date-fns/locale/pl';
import useLocalStorage from 'use-local-storage';
import classNames from 'classnames';

import { ReactComponent as SolidCopy } from '../../../svgs/solid/copy.svg';

registerLocale('pl', pl);

import * as usersSlice from '../../../store/app/usersSlice';
import { USER_STATUS_BLOCKED, USER_STATUS_DEFAULT } from '../../../consts';
import { config } from '../../../config';
import { showNotification } from '../../../core/utils';

export const AdminUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(usersSlice.selectItems);
  const [searchParams, setSearchParams] = useSearchParams();
  const isSupplier = searchParams.get('isSupplier') || 0;
  const isAll = searchParams.get('isAll') || 0;
  const filter = searchParams.get('filter') || '';

  useEffect(() => {
    dispatch({
      type: 'ADMIN_FETCH_USERS',
      payload: {
        isSupplier,
        isAll,
        filter,
      },
    });
  }, [isSupplier, isAll, filter]);

  // eslint-disable-next-line
  const [impersonateUserId, setImpersonateUserId] = useLocalStorage(
    config.impersonateUserIdKey,
    0
  );

  // if (!users?.length) {
  //   return (
  //     <div className='content-filler'>
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <div className='content admin-content'>
      <Helmet>
        <title>Users | Bimbash</title>
      </Helmet>

      <div className='h3'>Users {users?.length}</div>

      <div className='tabs-actions'>
        <div
          className={classNames('button', {
            active: !isAll && !isSupplier,
          })}
          onClick={() => {
            searchParams.delete('isSupplier');
            searchParams.delete('isAll');
            setSearchParams(searchParams);
          }}
        >
          LAST 100
        </div>
        <div
          className={classNames('button', {
            active: isAll,
          })}
          onClick={() => {
            searchParams.set('isAll', '1');
            searchParams.delete('isSupplier');
            setSearchParams(searchParams);
          }}
        >
          ALL
        </div>
        <div
          className={classNames('button', {
            active: isSupplier === '1',
          })}
          onClick={() => {
            searchParams.delete('isAll');
            searchParams.set('isSupplier', '1');
            setSearchParams(searchParams);
          }}
        >
          SUPPLIERS
        </div>

        <div className='actions-block'>
          <input
            type='text'
            className='input-text'
            placeholder='Find...'
            value={filter}
            onChange={(e) => {
              searchParams.set('filter', e.target.value);
              setSearchParams(searchParams);
            }}
          />
          {filter && (
            <button
              className='button-small'
              onClick={() => {
                searchParams.delete('filter');
                setSearchParams(searchParams);
              }}
            >
              X
            </button>
          )}
        </div>
      </div>

      <table className='users-list-table'>
        <thead>
          <tr>
            {!Boolean(isSupplier) && <th>spent</th>}
            <th>email</th>
            <th>impersonate</th>
            <th>first name</th>
            <th>last name</th>
            <th>username</th>
            <th>created</th>
            {/* <th>is supplier</th> */}
            <th>ip</th>
            <th>utm_source</th>
            {!Boolean(isSupplier) && <th>stripe customer id</th>}
            {Boolean(isSupplier) && <th>stripe account id</th>}
            <th>partner id</th>
            <th>ref partner id</th>
            <th>delete</th>
            <th>ban</th>
          </tr>
        </thead>

        <tbody>
          {users?.map?.((user) => (
            <tr
              className={classNames({
                'user-row-banned': user.status === USER_STATUS_BLOCKED,
                'user-row-supplier': user.isRoleSupplier && !isSupplier,
                'user-row-admin': user.isRoleAdmin,
              })}
              key={user.userId}
            >
              {!Boolean(isSupplier) && <td>{Number(user.amountSum) || 0}</td>}
              <td className='event-slot-data admin-user-data'>
                <Link
                  to={`/@${user.username}`}
                  className=''
                >
                  {user.email}
                </Link>
                <SolidCopy
                  className='icon-copy'
                  onClick={() => {
                    navigator.clipboard.writeText(user.email);
                    showNotification({
                      message: 'Email copied',
                      type: 'success',
                    });
                  }}
                />
              </td>
              <td className='event-slot-data admin-user-data'>
                <a
                  href={`tel:${user.phone}`}
                  className=''
                >
                  {user.phone}
                </a>
                <SolidCopy
                  className='icon-copy'
                  onClick={() => {
                    navigator.clipboard.writeText(user.phone);
                    showNotification({
                      message: 'Email copied',
                      type: 'success',
                    });
                  }}
                />
              </td>
              <td
                className='td-action'
                onClick={() => {
                  const returnPage =
                    window.location.pathname + window.location.search;
                  localStorage.setItem('returnPage', returnPage);
                  localStorage.removeItem('landingPage');
                  setImpersonateUserId(user.userId);
                  if (user.isRoleSupplier) {
                    window.location = `/my-event-slots`;
                    return;
                  }
                  window.location = `/my-tickets`;
                }}
              >
                Impersonate
              </td>
              <td className=''>{user.firstName}</td>
              <td className=''>{user.lastName}</td>
              <td className=''>
                <Link
                  to={`/@${user.username}`}
                  className=''
                >
                  {user.username}
                </Link>
              </td>
              <td>
                {format(new Date(user.created), 'dd.MM.yyyy HH:ii', {
                  locale: pl,
                })}
              </td>
              {/* <td className='tickets-count'>
                {user.isRoleSupplier ? 'yes' : ''}
              </td> */}
              <td
                className='pointer'
                onClick={() => {
                  searchParams.set('filter', user.ip);
                  setSearchParams(searchParams);
                }}
              >
                {user.ip}
              </td>
              <td
                className='pointer'
                onClick={() => {
                  searchParams.set('filter', user.utmSource);
                  setSearchParams(searchParams);
                }}
              >
                {user.utmSource}
              </td>

              <td>{user.partnerId}</td>
              <td>{user.refPartnerId}</td>

              {!Boolean(isSupplier) && (
                <td
                  className='pointer'
                  onClick={() => {
                    searchParams.set('filter', user.utmSource);
                    setSearchParams(searchParams);
                  }}
                >
                  {user.stripeCustomerId}
                </td>
              )}

              {Boolean(isSupplier) && (
                <td
                  className='pointer'
                  onClick={() => {
                    searchParams.set('filter', user.utmSource);
                    setSearchParams(searchParams);
                  }}
                >
                  {user.stripeAccountId}
                </td>
              )}

              <td
                className='td-action'
                onClick={() => {
                  if (!confirm('Are you sure?')) return;
                  dispatch({
                    type: 'ADMIN_DELETE_USER',
                    payload: user,
                  });
                }}
              >
                Delete
              </td>
              {user.status === USER_STATUS_DEFAULT && (
                <td
                  className='td-action'
                  onClick={() => {
                    if (!confirm('Are you sure?')) return;
                    dispatch({
                      type: 'ADMIN_BAN_USER',
                      payload: user,
                    });
                  }}
                >
                  Ban
                </td>
              )}
              {user.status === USER_STATUS_BLOCKED && (
                <td
                  className='td-action'
                  onClick={() => {
                    if (!confirm('Are you sure?')) return;
                    dispatch({
                      type: 'ADMIN_UNBAN_USER',
                      payload: user,
                    });
                  }}
                >
                  UnBan
                </td>
              )}

              {/* <div
                onClick={() => {
                  if (!confirm('Are you sure?')) return;
                  dispatch({
                    type: 'CREATE_PAYOUT',
                    payload: user,
                  });
                }}
              >
                Payout
              </div> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
