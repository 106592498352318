import { useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as eventsSlice from '../../store/app/eventsSlice';
import { language, isMobile } from '../../core/utils';
import { labels } from '../../labels';
import { UploadImage } from '../User/UploadImage';
// import { UploadVideo } from '../User/UploadVideo';
import { Loading } from '../Loading/Loading';
import { Image } from '../Common/Image';
import { Card } from './Card';
import { MODE_EVENT } from '../../consts';

export const Images = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const event = useSelector(eventsSlice.selectItem);
  const eventImages = useSelector(eventsSlice.selectEventImages);
  // const media = useSelector(mediaSlice.selectItems);

  // useEffect(() => {
  //   if (!event?.eventImages) return;
  //   event?.eventImages.find((image) => {
  //     if (image.isProcessing) {
  //       window.mediaTimeout = setTimeout(() => {
  //         dispatchFetchEvent();
  //       }, 1000);
  //     }
  //   });
  //   return () => {
  //     if (window.mediaTimeout) {
  //       clearTimeout(mediaTimeout);
  //     }
  //   };
  // }, [event]);

  const dispatchFetchEvent = () => {
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
        // includePendingImages: true,
        // includePendingVideos: true,
      },
    });
  };

  useEffect(() => {
    dispatchFetchEvent();
  }, []);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const newImages = [...event.eventImages];
      const dragCard = newImages[dragIndex];
      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragCard);

      dispatch({
        type: 'REORDER_EVENT_IMAGES',
        payload: {
          eventImages: newImages,
          eventId: event.eventId,
        },
      });
    },
    [event]
  );

  if (!event) {
    <Loading />;
  }

  return (
    <div className='page-content my-events'>
      <Helmet>
        <title>{labels[language].images} | Bimbash</title>
      </Helmet>

      <div className='h3'>{labels[language].images}</div>
      <div>
        <Link
          to={`/event/${event?.eventId}`}
          className='link-regular'
        >
          {event?.name}
        </Link>
      </div>

      <div className='media-actions'>
        <UploadImage
          title='Wgraj zdjęcie'
          onUploaded={() => {
            dispatchFetchEvent();
          }}
          eventId={eventId}
          mode={MODE_EVENT}
        />
        {/* <UploadVideo
          title='Wgraj video'
          onUploaded={() => {
            dispatchFetchEvent();
          }}
          eventId={eventId}
        /> */}
      </div>

      <div className='box-info'>
        Nie masz zdjęć?{' '}
        <a
          href='/faq.html#images'
          target='_blank'
          className='link-basic'
        >
          Zobacz jak wygenerować ilustracje
        </a>{' '}
        za darmo, aby Twoja wycieczka przyciągała uwagę turystów
      </div>

      <div className='event-image-list'>
        {eventImages?.map((image, index) => (
          <Card
            key={`${image.eventImageId}-${image.position}`}
            index={index}
            id={image.eventImageId}
            moveCard={moveCard}
          >
            <div
              className='event-image'
              // key={image.eventImageId}
            >
              {Boolean(image.isProcessing) && (
                <div className='event-image-processing'>
                  <Loading /> Trwa przetwarzanie pliku...
                </div>
              )}
              <Image
                id={image.imageId}
                forceWidth={image.isProcessing ? 360 : 0}
                forceQuality={image.isProcessing ? 80 : 0}
                defaultWidth={isMobile ? 0 : 400}
                className='event-image'
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (!confirm('Czy na pewno chcesz usunąć?')) return;
                  dispatch({
                    type: 'DELETE_EVENT_IMAGE',
                    payload: {
                      eventId: event.eventId,
                      eventImageId: image.eventImageId,
                    },
                  });
                }}
                className='button-secondary button-danger'
              >
                Usuń
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
