import classNames from 'classnames';

import { ReactComponent as SolidStar } from '../../svgs/solid/star.svg';
import { ReactComponent as RegularStar } from '../../svgs/regular/star.svg';
import { ReactComponent as RegularStarHalfStroke } from '../../svgs/regular/star-half-stroke.svg';

export const Stars = ({ rating, onChange }) => {
  const stars = [];
  let fullStars = Math.floor(rating);
  const decimal = rating - fullStars; // Decimal part of the rating
  const halfStar = decimal >= 0.4 && decimal <= 0.8 ? 1 : 0;
  if (decimal > 0.8) fullStars++; // If decimal part is bigger than 0.8, add a full star

  const fullOrHalfStarsCount = fullStars + halfStar; // Total of full stars and a possible half star

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <SolidStar
        key={i}
        className={classNames('fa-solid fa-star', {
          pointer: Boolean(onChange),
        })}
        onClick={() => onChange?.(i + 1)}
      />
    );
  }

  // Add a half star if needed
  if (halfStar) {
    stars.push(
      <RegularStarHalfStroke
        key={fullStars}
        className='fa-solid fa-star-half-stroke'
      />
    );
  }

  // Add empty stars
  for (let i = fullOrHalfStarsCount; i < (onChange ? 6 : 5); i++) {
    stars.push(
      <RegularStar
        key={i}
        className={classNames('fa-regular fa-star', {
          pointer: Boolean(onChange),
        })}
        onClick={() => onChange?.(i + 1)}
      />
    );
  }

  return <div className='stars'>{stars}</div>;
};
