import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { getAuthHeaders } from '../utils';
import * as appSlice from '../app/appSlice';
import * as aiSlice from '../app/aiSlice';
import { showNotification } from '../../core/utils';
import config from '../../config';

const fetchAI = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { username } = payload;
  if (username) {
    searchParams.set('username', username);
  }

  const response = await fetch(
    `${config.appApiUrl}/ai/details?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchAISaga({ payload } = {}) {
  yield put(appSlice.setStatusPending());
  const data = yield call(fetchAI, {
    payload,
  });
  yield put(appSlice.setStatusDefault());
  const { error } = data;
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(aiSlice.setItem(data));
}

const saveAI = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/ai/save`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* saveAISaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const data = yield call(saveAI, {
    payload,
  });
  yield put(appSlice.setStatusDefault());
  const { error } = data;
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: 'Saved',
    type: 'success',
  });
  yield put(aiSlice.setItem(payload));
}

export function* aiSagas() {
  yield takeLatest('SAVE_AI', saveAISaga);
  yield takeEvery('FETCH_AI', fetchAISaga);
}
