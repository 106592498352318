import { formatByLocale } from '../../core/utils';

import { ReactComponent as SolidChevronDown } from '../../svgs/solid/chevron-down.svg';

export const EventSlotCard = ({
  event,
  eventSlot,
  showIcon,
  onClick,
  className = 'event-selector-item',
  showStartPlace,
  children,
}) => {
  return (
    <>
      <div
        className={className}
        onClick={onClick}
      >
        <div className='event-selector-left'>
          {eventSlot && (
            <>
              <div className='event-selector-item-datetime'>
                {formatByLocale({
                  datetime: eventSlot.startDatetime,
                  endDatetime:
                    event?.durationHoursFrom &&
                    new Date(
                      new Date(eventSlot.startDatetime).getTime() +
                        event?.durationHoursFrom * 60 * 60 * 1000
                    ),
                })}
              </div>
              {showStartPlace && (
                <div className='event-selector-item-label event-selector-item-place'>
                  <a
                    href={eventSlot.startPlaceLink}
                    target='_blank'
                    className='link-basic'
                  >
                    {eventSlot.startPlaceName}
                  </a>
                </div>
              )}
            </>
          )}
        </div>

        {showIcon && (
          <div className='event-selector-icon'>
            <SolidChevronDown />
          </div>
        )}
      </div>
      {children}
    </>
  );
};

export default EventSlotCard;
