import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { Helmet } from 'react-helmet';
import pl from 'date-fns/locale/pl';

import * as placesSlice from '../../../store/app/placesSlice';
import { Loading } from '../../Loading/Loading';

registerLocale('pl', pl);

export const AdminPlaces = () => {
  const dispatch = useDispatch();
  const places = useSelector(placesSlice.selectItems);
  useEffect(() => {
    dispatch({
      type: 'FETCH_PLACES',
    });
  }, []);

  if (!places?.length) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content'>
      <Helmet>
        <title>Places | Bimbash</title>
      </Helmet>
      <div className='h3'>Places</div>

      <div className='page-actions'>
        <Link
          to='/admin/edit-place'
          className='button button-primary'
        >
          Dodaj
        </Link>
      </div>

      <div className='users-list'>
        {places.map((place) => (
          <div
            className='user-card'
            key={place.placeId}
          >
            <a
              href={place.link}
              target='_blank'
            >
              {place.name}
            </a>
            <div>{place.address}</div>

            <div className='user-actions'>
              <Link to={`/admin/edit-place/${place.placeId}`}>Edit</Link>
              <div
                onClick={() => {
                  if (!confirm('Are you sure?')) return;
                  dispatch({
                    type: 'DELETE_PLACE',
                    payload: place,
                  });
                }}
              >
                Delete
              </div>
            </div>

            <div>
              Owner:{' '}
              <Link to={`/@${place.username}`}>
                {place.userFirstName
                  ? `${place.userFirstName} ${place.userLastName}`
                  : place.userUsername}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
