import { config } from '../../config';
import { language } from '../../core/utils';
import { labels } from '../../labels';

export const Media = ({}) => {
  const files = [
    'bimbash-na-nowo.mp3',
    'bimbash-epic-female.mp3',
    'bimbash-country.mp3',
    'bimbash-acoustic.mp3',
    'bimbash-dubstep.mp3',
    'bimbash-rock-slow.mp3',
    'bimbash-pop.mp3',
    'bimbash-dubstep-pop.mp3',
    'bimbash-dance.mp3',
    'bimbash-medieval.mp3',
    'bimbash-epic.mp3',
    'bimbash-epic-2.mp3',
    'bimbash-numetal.mp3',
    'bimbash-metal-violin.mp3',
  ];

  return (
    <div className='page-content'>
      <h3>{labels[language].media}</h3>

      <div>
        <p>
          {labels[language].musicLicence}{' '}
          <a
            href='https://creativecommons.org/licenses/by/4.0/'
            className='link-regular'
            target='_blank'
          >
            Creative Commons / CC BY
          </a>
          .
        </p>
        <p>{labels[language].joinCreators}</p>
        <div>
          <p> {labels[language].files}:</p>
          {files.map((file) => {
            return (
              <p key={file}>
                <a
                  href={`${config.imgHostUrl}/${file}`}
                  target='_blank'
                  className='link-regular'
                >
                  {file}
                </a>
              </p>
            );
          })}
        </div>
      </div>

      <div>
        <h4>Tekst piosenki "Bimbash na nowo"</h4>
        <p>
          Kraków wita nas słonecznym promieniem,
          <br />
          Mariacki hejnał niesie się echem,
          <br />
          Z Bimbasz bilet w dłoni mam,
          <br />
          Mała grupa, uśmiech, to nasz plan.
        </p>
        <p>
          W ten ciepły dzień, przygoda nas czeka
          <br />
          Królewskie miasto pełne smoków i legend,
          <br />
          Chodź z przewodnikiem, co wszystkie je zna,
          <br />Z energią, pasją, rozbawi nas.
        </p>
        <p>
          Z Bimbasz zwiedzaj na nowo
          <br />
          Prawdziwe życie bez nudnych dat, (bez dat)
          <br />
          wszystkie barwne ciekawe historie,
          <br />
          Tak rzeczywiste jakby były Twoje
        </p>
        <p>
          August mocny, co podkowy zginał,
          <br />
          Królowa Bona i jej włoskie warzywa,
          <br />
          Z Bimbasz, wszystkie ciekawe historie,
          <br />
          Poznasz na nowo, bez nudnych dat (bez dat)
        </p>
        <p>
          W katedrze dzwon, co szczęście przynosi,
          <br />
          Smok oginiem zieje, zadziwia gości,
          <br />
          Krakowskie sekrety o których nie wiesz,
          <br />
          mogą być Twoje jeśli tylko je zechcesz.
        </p>
        <p>
          Z Bimbasz zwiedzaj na nowo
          <br />
          Prawdziwe życie bez nudnych dat, (bez dat)
          <br />
          wszystkie barwne ciekawe historie,
          <br />
          Tak rzeczywiste jakby były Twoje
        </p>
      </div>

      <div className='images-list'>
        <h3>Logo</h3>

        <a
          href={`/logo_blue.png`}
          target='_blank'
        >
          <img src='/logo_blue.png' />
        </a>
      </div>
    </div>
  );
};

export default Media;
