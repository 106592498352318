import { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { registerLocale } from 'react-datepicker';

import { Loading } from '../Loading/Loading';
import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';

registerLocale('pl', pl);

export const DeleteEventSlot = ({}) => {
  const params = useParams();
  const { eventSlotId } = params;
  const dispatch = useDispatch();
  const eventSlot = useSelector(eventSlotsSlice.selectItem);
  const isStatusPending = useSelector(eventSlotsSlice.selectIsStatusPending);

  const [data, setData] = useState({
    description: '',
  });

  const send = () => {
    dispatch({
      type: 'DELETE_EVENT_SLOT',
      payload: {
        eventSlotId,
        description: data.description,
      },
    });
  };

  useEffect(() => {
    if (!eventSlotId) return;
    dispatch({
      type: 'FETCH_EVENT_SLOT',
      payload: {
        eventSlotId,
      },
    });
  }, [eventSlotId]);

  if (!eventSlot) return null;

  if (isStatusPending) {
    return <Loading />;
  }

  return (
    <div className='page-content'>
      <div className='h3'>Usuwanie terminu z biletami</div>
      <h3>
        <NavLink to={`/event/${eventSlot.eventId}`}>
          {eventSlot?.eventName}{' '}
          {format(new Date(eventSlot?.startDatetime), 'dd MMMM yyyy HH:mm', {
            locale: pl,
          })}
        </NavLink>
      </h3>
      <div className='box-info'>
        Odwołanie wydarzenia jest bardzo negatywnym doświadczeniem dla naszych
        klientów - w ten sposób często tracimy ich bezpowrotnie. Dlatego
        usuwanie wydarzeń z zakupionymi już biletami powinno być ostatecznością.
      </div>

      <p>
        Napisz wiadomość, która zostanie wysłana do klientów z biletami na ten
        termin:
      </p>
      <textarea
        value={data.description}
        onChange={(e) => setData({ description: e.target.value })}
        className='textarea-default'
        placeholder='np. Niestety warunki pogodowe nie pozwalają na realizację wydarzenia. Przepraszamy za utrudnienia.'
      />
      <button
        onClick={send}
        className='button-secondary'
      >
        Usuń termin i zwróć pieniądze klientom
      </button>
    </div>
  );
};
