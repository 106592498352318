import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import Select from 'react-select';
import { format } from 'date-fns';

import { Loading } from '../Loading/Loading';
import * as eventsSlice from '../../store/app/eventsSlice';
import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';
import * as usersSlice from '../../store/app/usersSlice';
import { showNotification } from '../../core/utils';

registerLocale('pl', pl);

export const EditEventSlot = ({}) => {
  const params = useParams();
  const { eventId, eventSlotId } = params;
  const dispatch = useDispatch();
  const event = useSelector(eventsSlice.selectItem);
  const eventSlots = useSelector(eventSlotsSlice.selectItems);
  const eventSlot = eventSlots?.find(
    (eventSlot) => eventSlot.eventSlotId === eventSlotId
  );
  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);
  const users = useSelector(usersSlice.selectItems);

  const usersOptions = users?.map((user) => ({
    value: user.userId,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const initialData = {
    eventSlotId: nanoid(),
    eventId,
    startDatetime: '',
    startTime: '',
    ticketsCount: 20,
    externalUserId: '',
    externalUser: undefined,
  };
  const [data, setData] = useState(initialData);

  const warnings = {};
  // const errors = {};
  if (!data?.created && data?.startDatetime) {
    const date = new Date(data.startDatetime);
    if (date < new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)) {
      warnings.startDatetime =
        'Terminy najlepiej dodawać z 14 dniowym wyprzedzeniem, aby zwiększyć szansę na sprzedaż biletów.';
    }
    if (date < new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)) {
      warnings.startDatetime =
        'Termin jest zbyt blisko, szansa na sprzedaż biletów jest bardzo mała. Najlepiej dodawać terminy z 14 dniowym wyprzedzeniem.';
    }
  }

  useEffect(() => {
    if (!eventId) return;
    if (eventId === event?.eventId) return;
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
      },
    });
    dispatch({
      type: 'FETCH_EVENT_SLOTS',
      payload: {
        eventId,
      },
    });
  }, [eventId]);

  useEffect(() => {
    if (!eventSlot || !users?.length) return;
    setData({
      ...initialData,
      ...eventSlot,
      externalUser: usersOptions.find(
        (o) => o.value === eventSlot.externalUserId
      ),
      startTime: format(new Date(eventSlot.startDatetime), 'HH:mm'),
    });
  }, [eventSlot, users]);

  const save = () => {
    if (!data.startDatetime) {
      showNotification({
        message: 'Podaj datę',
        type: 'warning',
      });
      return;
    }
    if (!data.startTime) {
      showNotification({
        message: 'Podaj godzinę',
        type: 'warning',
      });
      return;
    }
    if (!data.ticketsCount || Number(data.ticketsCount) <= 0) {
      showNotification({
        message: 'Podaj ilość biletów',
        type: 'warning',
      });
      return;
    }
    dispatch({
      type: 'SAVE_EVENT_SLOT',
      payload: {
        eventSlot: data,
      },
    });
  };

  const combineDateAndTime = () => {
    if (!data.startDatetime || !data.startTime) return;
    const date = new Date(data.startDatetime);
    const time = data.startTime.split(':');
    if (time.length !== 2) {
      showNotification({
        message: 'Podaj godzinę w formacie HH:mm np. 10:00',
        type: 'warning',
      });
      setData({
        ...data,
        startTime: '',
      });
      return;
    }
    date.setHours(time[0]);
    date.setMinutes(time[1]);
    setData({
      ...data,
      startDatetime: date.toISOString(),
    });
  };

  useEffect(() => {
    dispatch({
      type: 'FETCH_USERS',
      payload: {
        page: 0,
      },
    });
  }, []);

  const ticketNumber = Number(data.ticketsCount);

  return (
    <div className='page-content'>
      <div className='h3'>
        {eventSlotId ? 'Edytuj' : 'Dodaj'} termin dla {event?.name}
      </div>

      {Boolean(data?.ticketsSoldCount) && (
        <div className='box-info'>
          Na ten termin sprzedano już bilety ({data.ticketsSoldCount}). Zmiany
          muszą być ustalane z klientami, którzy zakupili te bilety.
        </div>
      )}
      <div className='form-event-slot'>
        <div className='form-line'>
          <div className='form-header'>Data</div>
          <DatePicker
            selected={data.startDatetime && new Date(data.startDatetime)}
            onChange={(date) => {
              setData({
                ...data,
                startDatetime: date?.toISOString?.(),
              });
            }}
            onBlur={combineDateAndTime}
            dateFormat='yyyy-MM-dd'
            locale='pl'
            minDate={new Date()}
          />
        </div>
        {warnings.startDatetime && (
          <div className='box-info'>{warnings.startDatetime}</div>
        )}
        <div className='form-line'>
          <div className='form-header'>Godzina</div>
          <input
            type='text'
            className='input-tickets'
            value={
              data.startTime || data.startTime === ''
                ? data.startTime
                : data.startDatetime &&
                  new Date(data.startDatetime).toLocaleTimeString().slice(0, 5)
            }
            onChange={(e) => {
              if (!e.target.value) {
                setData({
                  ...data,
                  startTime: '',
                });
                return;
              }
              const newValue = e.target.value.replace(' ', ':');
              // allow only numbers and character :
              if (!/^[0-9:]*$/.test(newValue)) return;
              setData({
                ...data,
                startTime: newValue,
              });
            }}
            onBlur={combineDateAndTime}
          />
        </div>
        <div className='tickets-count'>
          <div className='form-header'>Ilość biletów</div>
          <input
            type='number'
            className='input-tickets'
            value={data.ticketsCount}
            onBlur={() => {
              if (ticketNumber <= 0) {
                setData({
                  ...data,
                  ticketsCount: initialData.ticketsCount,
                });
                return;
              }
            }}
            onChange={(e) => {
              setData({
                ...data,
                ticketsCount: e.target.value,
              });
            }}
          />

          {ticketNumber > 20 && (
            <div className='box-info'>
              Grupa powyżej 20 osób powinna dodatkowo otrzymać zestawy
              słuchawkowe w cenie biletu lub zostać podzielona na mniejsze
              grupy.
            </div>
          )}
        </div>

        {usersOptions?.length && (
          <div className=''>
            <div className='form-header'>
              Przewodnik (wybierz tylko jeśli to inna osoba niż Ty)
            </div>
            <Select
              placeholder='Wybierz przewodnika'
              options={usersOptions}
              onChange={(e) => {
                setData({
                  ...data,
                  externalUserId: e?.value,
                  externalUser: e,
                });
              }}
              value={data.externalUser}
              isClearable
            />
          </div>
        )}

        <div className=''>
          <button
            onClick={save}
            className='button-primary button-new-slot'
          >
            Zapisz
          </button>
        </div>
      </div>
      <div className='form form-event-slots'>
        {isStatusPending && <Loading />}
      </div>
    </div>
  );
};
