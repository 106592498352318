import { call, spawn, all } from 'redux-saga/effects';
// import { store } from './store';
// import {
//   saveStateIntoCache,
//   getStateFromCache,
//   getMinState,
//   getReferralFromCache
//  } from './cache';

import { appSagas } from './sagas/app';
import { usersSagas } from './sagas/users';
import { aiSagas } from './sagas/ai';
import { eventsSagas } from './sagas/events';
import { eventSlotsSagas } from './sagas/eventSlots';
import { ordersSagas } from './sagas/orders';
import { placesSagas } from './sagas/places';

// import { feedSagas } from './sagas/feed';
// import { userFriendsSagas } from './sagas/userFriends';
// import { notificationsSagas } from './sagas/notifications';
// import { dialogsSagas } from './sagas/dialogs';
// import { dialogMessagesSagas } from './sagas/dialogMessages';
// import { avatarsSagas } from './sagas/avatars';
// import { mediaServerTasksSagas } from './sagas/mediaServerTasks';

// import { all, fork } from 'redux-saga/effects';

// export default function* rootSaga() {
//   yield all([
//     fork(eventsSagas),
//     fork(listenForSocketEvents), // Add your listener saga here
//   ]);
// }

function* rootSaga() {
  const sagas = [
    appSagas,
    usersSagas,
    aiSagas,
    // avatarsSagas,
    // feedSagas,
    ordersSagas,
    eventsSagas,
    eventSlotsSagas,
    placesSagas,
    // userFriendsSagas,
    // notificationsSagas,
    // dialogsSagas,
    // dialogMessagesSagas,
    // mediaServerTasksSagas,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}

export default rootSaga;
