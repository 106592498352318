import { useState, useEffect, useCallback } from 'react';

// import { config } from '../config';

export const connectionDefaultSpeed = {
  wifi: 50, // experimental
  '5g': 100,
  '4g': 20,
  '3g': 5,
  '2g': 0.5,
  'slow-2g': 0.1,
};

export const getConnectionType = () => {
  // not supported by safari iphone
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (connection) {
    return connection.effectiveType;
  }
  return;
};
/*
function calculateDownloadSpeedWithImage() {
  if (window.isRunningConnectionSpeedTest) return;
  window.isRunningConnectionSpeedTest = true;

  setTimeout(() => {
    const fileSizeInBytes = 524 * 1024; // in bytes
    const url = `${config.imgHostUrl}/524kb.png?x=${Math.random()}`;
    // const startTime = new Date().getTime(); // Record the start time

    const img = new Image(); // Create a new Image object

    img.onload = function () {
      setTimeout(() => {
        // Timeout ensures the performance entry is recorded.
        const performanceEntry = performance.getEntriesByName(url)[0];
        if (performanceEntry) {
          const duration = performanceEntry.duration / 1000; // Duration in seconds
          const speedBps = fileSizeInBytes / duration; // Download speed in Bps (bytes per second)
          const speedKbps = speedBps / 1024; // Convert speed to Kbps
          const speedMbps = (speedKbps / 1024) * 20; // Convert speed to Mbps

          console.log(`Download speed: ${speedMbps.toFixed(2)} Mbps`);

          window.lastConnectionSpeed = speedMbps;
          window.updateConnectionSpeed?.(speedMbps);
          localStorage?.setItem?.('lastConnectionSpeed', speedMbps);
        } else {
          console.log('Performance entry for the image was not found.');
        }
      }, 0);
    };

    img.onerror = function () {
      console.error('Error loading the image.');
    };

    // Start loading the image
    img.src = url;
  }, 5000);
}
*/
// setTimeout(() => {
//   calculateDownloadSpeedWithImage();
// }, 4000);
// setInterval(calculateDownloadSpeedWithImage, 1000 * 60 * 60); // update speed every 60 minutes

window.lastConnectionSpeed = 0;
window.connectionSpeed = 0;
export const useConnectionSpeed = () => {
  const [connectionSpeed, setConnectionSpeed] = useState(
    window.connectionSpeed
  );
  const updateConnectionSpeed = useCallback(
    (speedMbps) => {
      window.connectionSpeed = speedMbps;
      if (connectionSpeed === speedMbps) return;
      setConnectionSpeed(speedMbps);
    },
    [connectionSpeed, setConnectionSpeed]
  );

  window.updateConnectionSpeed = updateConnectionSpeed;

  useEffect(() => {
    if (connectionSpeed) return;
    const connectionType = getConnectionType();
    if (connectionType) {
      const speedMbps = connectionDefaultSpeed[connectionType];
      updateConnectionSpeed(speedMbps);
      console.log('speed from connection type', speedMbps);
      return;
    }

    if (navigator?.connection?.downlink) {
      const speedMbps = navigator.connection.downlink;
      if (speedMbps > 0.5) {
        updateConnectionSpeed(speedMbps);
        console.log('speed from navigator', speedMbps);
        return;
      }
    }

    if (window.lastConnectionSpeed) {
      updateConnectionSpeed(window.lastConnectionSpeed);
      console.log('speed from lastConnectionSpeed', window.lastConnectionSpeed);
      return;
    }

    updateConnectionSpeed(8);
    // calculateDownloadSpeedWithImage();
  }, [updateConnectionSpeed]);

  return [connectionSpeed, updateConnectionSpeed];
};
