import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';

import { showNotification, language } from '../../core/utils';
import { labels } from '../../labels';

export const Settings = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(appSlice.selectUserData);
  const isRoleSupplier = Boolean(loggedUser?.isRoleSupplier);

  const initialData = {
    userId: '',
    email: '',
    phone: '',
    // companyName: '',
    // companyStreet: '',
    // companyPostCode: '',
    // companyCity: '',
    // vatNumber: '',
    username: '',
    password: '',
    notificationTicketSold: 1,
    notificationTicketReturned: 1,
    notificationNewReview: 1,
  };

  const [data, setData] = useState(initialData);
  const [isNewPassword, setIsNewPassword] = useState(false);

  // useEffect(() => {
  //   dispatch({
  //     type: 'FETCH_USER_DATA',
  //     payload: {},
  //   });
  // }, []);

  useEffect(() => {
    if (!loggedUser) return;
    // const values = {};
    // Object.keys(user).forEach((key) => {
    //   values[key] =
    //     user[key] !== null && user[key] !== undefined
    //       ? user[key]
    //       : initialData[key];
    // });
    setData({
      // ...values,
      ...loggedUser,
    });
  }, [loggedUser]);

  const handleSave = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(data.email).toLowerCase())) {
      const element = document.getElementById('email');
      element?.scrollIntoView?.(element);
      showNotification({
        message: labels[language].invalidEmail,
        type: 'error',
      });
      return;
    }

    dispatch({
      type: 'SAVE_SETTINGS',
      payload: data,
    });
  };

  if (!loggedUser) {
    return <Loading />;
  }

  return (
    <div className='content profile'>
      <Helmet>
        <title>{labels[language].settings} | Bimbash</title>
      </Helmet>

      <div className='profile-content'>
        <div className='profile-header'>
          <div className=''>
            <h3>{labels[language].settings}</h3>
            <div className='form form-profile'>
              <div className='form-line'>
                <label
                  htmlFor='email'
                  className='field-label'
                >
                  {labels[language].email}
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={data.email}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value.trim(),
                    });
                  }}
                />
              </div>

              {!isNewPassword && (
                <div
                  className='form-line button-secondary button-secondary-small'
                  onClick={() => setIsNewPassword(true)}
                >
                  {labels[language].changePassword}
                </div>
              )}
              {isNewPassword && (
                <div className='form-line'>
                  <label
                    htmlFor='password'
                    className='field-label'
                  >
                    {labels[language].newPassword}
                  </label>
                  <input
                    type='text'
                    id='password'
                    name='password'
                    autoComplete='new-password'
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value.trim() })
                    }
                  />
                </div>
              )}

              <div className='form-line'>
                <label
                  className='field-label'
                  htmlFor='phone'
                >
                  {labels[language].phone}
                </label>
                <input
                  type='text'
                  id='phone'
                  name='phone'
                  value={data.phone}
                  onChange={(e) =>
                    setData({
                      ...data,
                      phone: e.target.value,
                    })
                  }
                />
              </div>

              <h3>Zarabianie na polecaniu</h3>
              <div>
                Polecaj nas podając swój numer partnerski:{' '}
                {loggedUser.partnerId}
              </div>

              {isRoleSupplier && (
                <>
                  {/* <h3>{labels[language].companyDetails}</h3>
                  <div className='form-line'>
                    <label htmlFor='companyName'>
                      {labels[language].companyName}
                    </label>
                    <input
                      type='text'
                      id='companyName'
                      name='companyName'
                      value={data.companyName}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyName: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        setData({
                          ...data,
                          companyName: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyStreet'>
                      {labels[language].companyStreet}
                    </label>
                    <input
                      type='text'
                      id='companyStreet'
                      name='companyStreet'
                      value={data.companyStreet}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyStreet: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        setData({
                          ...data,
                          companyStreet: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyPostCode'>
                      {labels[language].companyPostCode}
                    </label>
                    <input
                      type='text'
                      id='companyPostCode'
                      name='companyPostCode'
                      value={data.companyPostCode}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyPostCode: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        setData({
                          ...data,
                          companyPostCode: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyCity'>
                      {labels[language].companyCity}
                    </label>
                    <input
                      type='text'
                      id='companyCity'
                      name='companyCity'
                      value={data.companyCity}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyCity: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        setData({
                          ...data,
                          companyCity: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='vatNumber'>
                      {labels[language].vatNumber}
                    </label>
                    <input
                      type='text'
                      id='vatNumber'
                      name='vatNumber'
                      value={data.vatNumber}
                      onChange={(e) =>
                        setData({
                          ...data,
                          vatNumber: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        setData({
                          ...data,
                          vatNumber: e.target.value.trim(),
                        })
                      }
                    />
                  </div> */}

                  <div className='form-line'>
                    <h3>{labels[language].notifications}</h3>
                    <div className='form-line form-line-checkbox'>
                      <input
                        id='notificationTicketSold'
                        type='checkbox'
                        value='1'
                        checked={Boolean(data.notificationTicketSold)}
                        onChange={(e) =>
                          setData({
                            ...data,
                            notificationTicketSold: Number(e.target.checked),
                          })
                        }
                      />
                      <label htmlFor='notificationTicketSold'>
                        {labels[language].ticketSold}
                      </label>
                    </div>

                    <div className='form-line form-line-checkbox'>
                      <input
                        id='notificationTicketReturned'
                        type='checkbox'
                        value='1'
                        // value={Number(data.notificationTicketReturned)}
                        checked={Boolean(data.notificationTicketReturned)}
                        onChange={(e) =>
                          setData({
                            ...data,
                            notificationTicketReturned: Number(
                              e.target.checked
                            ),
                          })
                        }
                      />
                      <label htmlFor='notificationTicketReturned'>
                        {labels[language].ticketReturned}
                      </label>
                    </div>

                    <div className='form-line form-line-checkbox'>
                      <input
                        id='notificationNewReview'
                        type='checkbox'
                        value='1'
                        checked={Boolean(data.notificationNewReview)}
                        onChange={(e) =>
                          setData({
                            ...data,
                            notificationNewReview: Number(e.target.checked),
                          })
                        }
                      />
                      <label htmlFor='notificationNewReview'>
                        {labels[language].newReview}
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div className='form-line form-actions'>
                <button
                  onClick={handleSave}
                  className='button-primary'
                >
                  {labels[language].save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
