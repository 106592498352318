import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  selectIsStatusPending,
  selectIsEmailSent,
} from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { labels } from '../../labels';
import { language } from '../../core/utils';

export const Forgot = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get('email');

  const [email, setEmail] = useState(paramEmail || '');
  const isStatusPending = useSelector(selectIsStatusPending);
  const isEmailSent = useSelector(selectIsEmailSent);

  const onClickSubmit = () => {
    dispatch({
      type: 'PASSWORD_RESET',
      payload: {
        email,
      },
    });
  };

  return (
    <form className='form-basic form-log-in form-centered'>
      <Helmet>
        <title>{labels[language].forgotPassword} | Bimbash</title>
      </Helmet>
      {!isStatusPending && (
        <>
          <div className='form-header'>{labels[language].forgotPassword}</div>

          {isEmailSent && <div>{labels[language].passwordResetLinkSent}</div>}

          {!isEmailSent && (
            <>
              <div className='line'>
                <div className='label'>Email</div>
                <input
                  type='text'
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {!isStatusPending && (
                <input
                  onClick={onClickSubmit}
                  type='button'
                  value={labels[language].resetPassword}
                  className='submit button-submit'
                />
              )}
            </>
          )}
        </>
      )}

      {isStatusPending && <Loading />}
    </form>
  );
};
