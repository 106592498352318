export const IS_ENV_DEVELOPMENT = process?.env?.NODE_ENV === 'development';
export const IS_ENV_PRODUCTION = process?.env?.NODE_ENV === 'production';

const baseConfig = {
  phone: '+48 501 962 037',
  email: 'hi@bimbash.com',
  domain: 'bimbash.com',
  appUrl: 'https://bimbash.com',
  imgHostUrl: 'https://cdn.bimbash.com',
  avatarHostUrl: 'https://cdn.bimbash.com',
  upload: {
    maxFileSize: 1024 * 1024 * 100,
    minDuration: 3, // [s]
    maxDuration: 60 * 20,
    mimeTypes: ['video/mp4', 'video/quicktime'],
    avatar: {
      maxFileSize: 1024 * 1024 * 20,
      mimeTypes: ['image/jpeg', 'image/png'],
    },
  },
  cacheKey: 'cache-state-1',
  intervals: {
    notification: {
      unreadCount: 60 * 1000,
    },
    messages: {
      unreadCount: 60 * 1000,
      refreshChat: 5 * 1000,
    },
  },
  imageWidths: [
    32,
    64,
    128,
    256,
    360, // Common for older iPhones and many Android phones.
    375, // iPhone X and iPhone 6/7/8.
    414, // iPhone Plus models.
    720, // Smaller tablets and large phones in landscape.
    1080, // Larger phones with high DPI in landscape and smaller laptops.
    1200, // For social media sharing and wide enough for many desktops.
    1440, // Standard desktops.
    2160, // High-end desktops and 4K displays.
  ],

  sentry: {
    dsn: 'https://f4dfe89e5888d404cfa9cd508b446b35@o4504844498894848.ingest.sentry.io/4506801499865088',
  },

  stripe: {
    publicKey:
      'pk_live_51PBZc52N239t6F0zPSI4HDUSJJwtJuO6fRQ1JjcyNUTGZURSKoSb0EOTFXmLSqgVSBgDu8TnkKgE5YbVo8y3Sfvs00HLTNSYxk',
  },

  defaultAvatarId: 'yBTst7GODcavcrS19biRA',

  impersonateUserIdKey: 'iui',
  metaSourceKey: 'metasource', // keep it kebab, it's header in requests
};

const devConfig = {
  ...baseConfig,
  appUrl: 'http://localhost:3000',
  appApiUrl: 'http://localhost:3001',
  googleAnalyticsId: '',
  googleAdsId: '',
  googleAdsConversionId: '',
  metaPixelId: '',
  tiktokTwq: '',
  tiktokPixelId: '',
  stripe: {
    publicKey:
      'pk_test_51PBZc52N239t6F0z7oRGuuv41eTqPq3M2OS4EBP1Y3hmRkxLA2qtR9Ybcn1hnG2a97puylriWubM9aPvFHNJXeCl00OZ8URC0f',
  },
};

const prodConfig = {
  ...baseConfig,
  appUrl: 'https://bimbash.com',
  appApiUrl: 'https://api.bimbash.com',
  googleAnalyticsId: 'G-DGQV23FQ8Q',
  googleAdsId: 'AW-16477548766',
  googleAdsConversionId: 'AW-16477548766/1SOXCP2ynZcZEN7hjbE9',
  metaPixelId: '1670794166392770',
  tiktokTwq: 'oeyi4',
  tiktokPixelId: 'CPOMFFBC77U4HF2BRAAG',
};

const isLocalServe = window.location.href.includes('http://localhost:3000');

export const config =
  IS_ENV_DEVELOPMENT || isLocalServe ? devConfig : prodConfig;

export default config;
