import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as eventsSlice from '../../store/app/eventsSlice';
import { Loading } from '../Loading/Loading';

import { isMobile } from '../../core/utils';
import { EventsList } from '../Event/EventsList';
import { language } from '../../core/utils';
import { labels } from '../../labels';
import { DatePickerInputRange } from '../Common/DatePicker';
import { EventCard } from '../Common/EventCard';
import classNames from 'classnames';

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter') || '';
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  return (
    <div
      className={classNames('search-container', {
        'search-container-focused': isSearchFocused,
      })}
    >
      {/* <MagnifyingGlass /> */}
      <input
        type='text'
        placeholder={labels[language].search}
        className={classNames('search-input', {
          'search-input-focused': isSearchFocused,
        })}
        onFocus={() => setIsSearchFocused(true)}
        onBlur={() => setIsSearchFocused(false)}
        value={filter}
        onChange={(e) => {
          searchParams.set('filter', e.target.value);
          searchParams.delete('page');
          setSearchParams(searchParams);
        }}
      />

      {filter && (
        <button
          className='button-small'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            searchParams.delete('filter');
            searchParams.delete('page');
            setSearchParams(searchParams);
          }}
        >
          X
        </button>
      )}
    </div>
  );
};

export const Home = () => {
  const dispatch = useDispatch();
  const events = useSelector(eventsSlice.selectItems);
  const hasMore = useSelector(eventsSlice.selectHasMore);
  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);

  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate') || '';
  const endDate = searchParams.get('endDate') || '';
  const exactDate = searchParams.get('exactDate') || '';
  const page = Number(searchParams.get('page') || 0);
  const filter = searchParams.get('filter') || '';
  const [debouncedFilter, setDebouncedFilter] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedFilter === filter) return;
      if (page) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }
      setDebouncedFilter(filter);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [filter]);

  useEffect(() => {
    dispatch(eventsSlice.setHasMore(true));
  }, [startDate, endDate, exactDate, debouncedFilter]);

  useEffect(() => {
    // console.log('fetch events');
    if (isStatusPending) return;
    // console.log('no pending');
    if (!hasMore) return;
    // console.log('has more');
    dispatch({
      type: 'FETCH_EVENTS',
      payload: {
        startDate,
        endDate,
        page,
        itemsPerPage: isMobile ? 6 : 12,
        filter,
        language,
        // exactDate,
      },
    });
    if (filter) {
      gtag?.('event', 'search', {
        search_term: filter,
      });
      return;
    }
    gtag?.('event', 'search', {
      search_term: `exactDate=${exactDate}&startData=${startDate}&endDate=${endDate}`,
      exactDate,
      startDate,
      endDate,
    });
  }, [startDate, endDate, exactDate, page, hasMore, debouncedFilter]);

  useEffect(() => {
    if (!hasMore || !events) return;
    const handleScroll = () => {
      const offsetMore = isMobile
        ? window?.innerHeight ?? 900
        : (window?.innerHeight ?? 1200) / 2;
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - offsetMore &&
        !isStatusPending
      ) {
        searchParams.set('page', Number(page) + 1);
        setSearchParams(searchParams);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [searchParams, setSearchParams, isStatusPending, page, hasMore, events]);

  // const popularEvents = useSelector(eventsSlice.selectPopularEvents);
  // const showPopularEvents =
  //   !filter &&
  //   !startDate &&
  //   !endDate &&
  //   !exactDate &&
  //   popularEvents?.length > 0;

  if (isMobile) {
    return (
      <div className='main-container home-page'>
        <Helmet>
          <title>{labels[language].titleHome}</title>
        </Helmet>
        <div className='page-filters-panel'>
          <DatePickerInputRange />
          <Search />
        </div>
        {/* {showPopularEvents && (
          <>
            <div className='h3-header'>{labels[language].popularEvents}</div>
            <EventsList events={popularEvents} />
          </>
        )} */}

        {/* {showPopularEvents && (
          <>
            <div className='h3-header h3-header-second'>
              {labels[language].soon}
            </div>
          </>
        )} */}

        {events && <EventsList events={events} />}
        {isStatusPending && <Loading />}
      </div>
    );
  }

  return (
    <div className='main-container home-page'>
      <Helmet>
        <title>{labels[language].titleHome}</title>
      </Helmet>
      {/* <div className='categories'>
          <div className='category-button'>Sightseeing</div>
          <div className='category-button'>Sport</div>
          <div className='category-button'>Food</div>
          <div className='category-button'>Popular</div>
        </div> */}
      {/* <div className='categories categories-cities'>
        <div className='category-button'>Krakow</div>
        <div className='category-button'>Warsaw</div>
        <div className='category-button'>Gdansk</div>
      </div> */}

      {/* <div className='main-categories'>
          <div className='main-category'>Pierwszy raz w Krakowie</div>
          <div className='main-category'>Drugi raz w Krakowie</div>
          <div className='main-category'>Niestandardowe</div>
        </div> */}
      {/* <h1 className='home-subheader'>{labels[language].slogan}</h1> */}

      <div className='page-filters-panel'>
        <DatePickerInputRange />
        <Search />
      </div>

      {/* {showPopularEvents && (
        <>
          <div className='h3-header'>{labels[language].popularEvents}</div>
          <div className='events-list'>
            {popularEvents?.map((event) => {
              return (
                <EventCard
                  event={event}
                  key={event.eventSlotId || event.eventId}
                />
              );
            })}
          </div>
        </>
      )} */}

      {/* {showPopularEvents && (
        <div className='h3-header'>{labels[language].soon}</div>
      )} */}

      <div className='events-list'>
        {events?.map((event) => {
          return (
            <EventCard
              event={event}
              key={`${event.eventSlotId || event.eventId}${
                event.nearestStartDatetime
              }`}
            />
          );
        })}

        {isStatusPending && <Loading />}
      </div>
    </div>
  );
};

export default Home;
