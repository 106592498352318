import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { Helmet } from 'react-helmet';
import pl from 'date-fns/locale/pl';

import * as eventsSlice from '../../../store/app/eventsSlice';
import { Loading } from '../../Loading/Loading';
import { STATUS_DEFAULT } from '../../../consts';

registerLocale('pl', pl);

export const AdminEvents = () => {
  const dispatch = useDispatch();
  const events = useSelector(eventsSlice.selectItems);
  useEffect(() => {
    dispatch({
      type: 'ADMIN_FETCH_EVENTS',
    });
  }, []);

  if (!events?.length) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content admin-content'>
      <Helmet>
        <title>Events | Bimbash</title>
      </Helmet>

      <div className='h3'>Events {events.length}</div>

      <table className='users-list-table'>
        <thead>
          <tr>
            <th>name</th>
            <th>user</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr
              className='event-card'
              key={event.eventId}
            >
              <td>
                <Link
                  target='_blank'
                  to={`/event/${event.eventId}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {event.name}
                </Link>
              </td>
              <td>
                <Link
                  target='_blank'
                  to={`/@${event.supplierUsername}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {event.supplierFirstName} {event.supplierLastName} @
                  {event.supplierUsername}
                </Link>
              </td>
              <td>
                {event.status === STATUS_DEFAULT && (
                  <span className='event-status status-pending'>
                    Waiting for approval
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
