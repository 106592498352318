import { toast } from 'react-toastify';
import { registerLocale } from 'react-datepicker';
// import { format } from 'date-fns';
import {
  //  zonedTimeToUtc,
  utcToZonedTime,
  format as formatTZ,
} from 'date-fns-tz';

import pl from 'date-fns/locale/pl';
import enGB from 'date-fns/locale/en-GB';
import enUs from 'date-fns/locale/en-US';
registerLocale('pl', pl);
registerLocale('en-GB', enGB);
registerLocale('en-US', enUs);

import { config } from '../config';

export const IS_ENV_DEVELOPMENT = process?.env?.NODE_ENV === 'development';
export const IS_ENV_PRODUCTION = process?.env?.NODE_ENV === 'production';
export const isAndroidOS = /Android/i.test(navigator.userAgent);
export const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isMac = /Mac/i.test(navigator.userAgent);
export const isChrome =
  'chrome' in window ||
  'chrome' in navigator ||
  navigator.userAgent.includes('Chrome') ||
  navigator.userAgent.includes('Chromium');

export const isFacebookInternalBrowser =
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent);
export const isInstagramInternalBrowser =
  navigator.userAgent.includes('Instagram');
export const isTwitterInternalBrowser = navigator.userAgent.includes('Twitter');
export const isTikTokInternalBrowser = navigator.userAgent.includes('TikTok');
export const isRedditInternalBrowser = navigator.userAgent.includes('Reddit');
export const isInInternalBrowser =
  isFacebookInternalBrowser ||
  isInstagramInternalBrowser ||
  isTwitterInternalBrowser ||
  isTikTokInternalBrowser ||
  isRedditInternalBrowser;

export let deferredInstallPrompt = null;
export let isPWAIntalled = true;
const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;
if (isIOS && !isInStandaloneMode()) {
  isPWAIntalled = false;
}
// const handleBeforeInstallPrompt = (e) => {
//   e.preventDefault();
//   deferredInstallPrompt = e;
//   isPWAIntalled = false;
// };
// window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js');
// }

export const isMobile =
  /Mobi/i.test(navigator.userAgent) ||
  'ontouchstart' in document.documentElement;

export const isElementHeightGreaterThanViewport = (element) => {
  if (!element) return;
  const elementHeight = element?.offsetHeight + 80;
  const viewportHeight =
    window?.innerHeight ?? document?.documentElement?.clientHeight ?? 500;

  return elementHeight > viewportHeight;
};

export const copyToClipboard = (input) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(input).then(
      () => {
        //  console.log('Copied to clipboard successfully.');
      },
      () =>
        //err
        {
          //   console.log('Failed to copy the text to clipboard.', err);
        }
    );
    return;
  }
  if (window.clipboardData) {
    window.clipboardData.setData('Text', input);
  }
};

export const showNotification = ({
  message,
  type = 'info',
  duration,
  externalLink,
  internalLink,
  onClick,
  className = '',
  customBodyClassName = 'custom-toast-body',
  position = 'bottom-center',
}) => {
  const defaultDuration = duration || 1000 + message.length * 50;

  let onClickAction;
  if (externalLink) {
    onClickAction = () => {
      window.open(externalLink, '_blank').focus();
    };
  }
  if (internalLink) {
    onClickAction = () => {
      window.navigateTo(internalLink);
    };
  }
  const getStartIcon = () => {
    return <></>;
  };
  const getEndIcon = () => {
    if (externalLink) {
      return (
        <i className='fa-solid fa-arrow-up-right-from-square toast-end-icon' />
      );
    }
    if (internalLink) {
      return <i className='fa-solid fa-right-to-bracket toast-end-icon' />;
    }
    return <></>;
  };
  const finalMessage = (
    <div className={customBodyClassName}>
      {getStartIcon()}
      <span className='custom-toast-body-message'>{message}</span>
      {getEndIcon()}
    </div>
  );

  toast[type](finalMessage, {
    position,
    autoClose: defaultDuration,
    hideProgressBar: false,
    closeOnClick: !Boolean(onClick),
    draggable: false,
    progress: undefined,
    onClick: onClickAction,
    className,
    pauseOnHover: true,
  });
};

export const scrollToTop = ({
  // duration = 100,
  // behavior = 'smooth',
  // block = 'start',
} = {}) => {
  // const item = document.querySelector('#header');
  // if (item?.scrollIntoView) {
  //   item?.scrollIntoView?.({ block, duration: 0 });
  //   return;
  // }
  window.scrollTo(0, 0);
  // document.getElementsByTagName('body')?.scrollTo?.(0, 0);
};

export const setLocalStorageLandingPage = (url = window.location.href) => {
  localStorage.setItem('landingPage', url);
};

export const redirectToLandingPage = () => {
  const landingPage = localStorage.getItem('landingPage');
  if (landingPage) {
    localStorage.removeItem('landingPage');
    const finalUrl = landingPage.replace(/"/g, '').replace(config.appUrl, '');
    window.navigateTo(finalUrl);
    return true;
  }
  return false;
};

export const handleResize = () => {
  const viewportHeight = window.visualViewport.height;
  const textareaBottomOffset =
    viewportHeight -
    (window.textareaRef?.current?.getBoundingClientRect().bottom ?? 0);

  if (textareaBottomOffset < 0) {
    window.setPositioning?.((prevPositioning) => ({
      ...prevPositioning,
      bottom: `${-textareaBottomOffset}px`,
    }));
    return;
  }
  window.setPositioning?.({
    bottom: '0px',
  });
};

export const isValidRemoteUrl = (urlString) => {
  let url;

  // Basic URL validation
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  // Check if the protocol is either http or https
  if (['http:', 'https:'].indexOf(url.protocol) === -1) {
    return false;
  }

  // Disallow localhost and certain private IP ranges
  const forbiddenHosts = ['localhost', '127.0.0.1', '0.0.0.0'];
  const forbiddenIPPatterns = [
    /^10\./, // Matches 10.0.0.0 - 10.255.255.255
    /^172\.(1[6-9]|2\d|3[01])\./, // Matches 172.16.0.0 - 172.31.255.255
    /^192\.168\./, // Matches 192.168.0.0 - 192.168.255.255
  ];

  if (
    forbiddenHosts.includes(url.hostname) ||
    forbiddenIPPatterns.some((pattern) => pattern.test(url.hostname))
  ) {
    return false;
  }

  return true;
};

export function formatNumber(n) {
  if (n < 1e3) return n.toString();
  if (n >= 1e3 && n < 1e6) return (n / 1e3).toFixed(n % 1e3 !== 0) + 'k';
  if (n >= 1e6 && n < 1e9) return (n / 1e6).toFixed(n % 1e6 !== 0) + 'm';
  // add more cases for larger numbers if needed, like billions ('b')
  return n.toString();
}

export const getIsDarkTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 1;
  }
  return 0;
};

const width = window.screen.width;
const height = window.screen.height;
const { devicePixelRatio } = window;
const {
  deviceMemory,
  languages,
  hardwareConcurrency,
  doNotTrack,
  maxTouchPoints,
} = navigator || {};

export const extendedHeader =
  'Z' +
  window?.btoa(
    'Z' +
      window?.btoa(
        JSON.stringify({
          a: width,
          b: height,
          c: devicePixelRatio,
          d: isAndroidOS,
          e: isIOS,
          f: isMobile,
          g: deviceMemory,
          h: languages,
          i: hardwareConcurrency,
          j: doNotTrack,
          k: maxTouchPoints,
        })
      ) +
      'Z='
  ) +
  'X=';

export const getCountryCode = () => {
  const lang = navigator.language || navigator.userLanguage;
  const parts = lang.split('-');
  if (parts.length > 1) {
    return parts[1].toUpperCase();
  }
  return 'PL';
};

export const getLanguageCode = () => {
  const langFromLocalStorage = localStorage.getItem('language');
  if (langFromLocalStorage) {
    return langFromLocalStorage;
  }
  const lang = navigator.language || navigator.userLanguage || 'en';
  const supportedLanguages = ['en', 'pl'];
  const finalLang = lang.split('-')[0].toLowerCase();
  if (supportedLanguages.includes(finalLang)) {
    return finalLang;
  }
  return 'pl';
};

export const getFullLanguageCode = () => {
  const langFromLocalStorage = localStorage.getItem('language');
  if (langFromLocalStorage) {
    return langFromLocalStorage;
  }
  const lang = navigator.language || navigator.userLanguage || 'en-US';
  const supportedLanguages = ['en', 'pl'];
  const finalLang = lang.split('-')[0].toLowerCase();
  if (supportedLanguages.includes(finalLang)) {
    return lang;
  }
  return 'pl-pl';
};

export const language = getLanguageCode();
export const languageCode = getFullLanguageCode();

export const getFirstDayOfWeek = () => {
  return languageCode === 'en-US' ? 0 : 1; // 1 for Monday, 0 for Sunday
};

export const locale =
  languageCode === 'en-US' ? enUs : languageCode === 'en-GB' ? enGB : pl;

export const formatByLocale = ({
  datetime,
  endDatetime,
  dateLocale = locale,
  showTime = true,
  showYear,
}) => {
  const dateFormatMap = {
    'en-GB': `EEE, d MMMM${showTime ? ' HH:mm' : ''}`,
    'en-US': `EEE, MMMM d${showYear ? ' yyyy' : ''}${
      showTime ? ' - h:mm a' : ''
    }`,
    pl: `EEE d.MM${showYear ? ' yyyy' : ''}${showTime ? ' HH:mm' : ''}`,
  };
  const endDateFormatMap = {
    'en-GB': `${showTime ? ' HH:mm' : ''}`,
    'en-US': `${showTime ? ' - h:mm a' : ''}`,
    pl: `${showTime ? ' HH:mm' : ''}`,
  };

  const dateFormat = dateFormatMap[languageCode] || dateFormatMap.pl;
  const endDateFormat = endDateFormatMap[languageCode] || endDateFormatMap.pl;

  const timeZone = 'Europe/Warsaw';
  const eventTime = new Date(datetime);
  // Convert event time to UTC
  // const utcTime = zonedTimeToUtc(eventTime, timeZone);
  // Convert UTC time to zoned time (Europe/Warsaw)
  const zonedTime = utcToZonedTime(eventTime, timeZone);
  // Format the zoned time
  const startLabel = formatTZ(zonedTime, dateFormat, {
    locale: dateLocale,
    timeZone,
  });
  // const startLabel = format(new Date(datetime), dateFormat, {
  //   locale: dateLocale,
  // });
  let endLabel = '';
  if (endDatetime) {
    const endEventTime = new Date(endDatetime);
    // Convert event time to UTC
    // const utcTime = zonedTimeToUtc(eventTime, timeZone);
    // Convert UTC time to zoned time (Europe/Warsaw)
    const zonedTime = utcToZonedTime(endEventTime, timeZone);
    // Format the zoned time
    endLabel = formatTZ(zonedTime, endDateFormat, {
      locale: dateLocale,
      timeZone,
    });
    endLabel = endLabel.split(' ');
    // endLabel = format(new Date(endDatetime), endDateFormat, {
    //   locale: dateLocale,
    // }).split(' ');
    if (language === 'en') {
      endLabel = ` - ${endLabel[endLabel.length - 2]} ${
        endLabel[endLabel.length - 1]
      }`;
    } else {
      endLabel = ` - ${endLabel[endLabel.length - 1]}`;
    }
  }
  return `${startLabel}${endLabel}`;
};

export const getCurrency = () => {
  let defaultCurrency = 'eur';
  if (getCountryCode() === 'US') {
    defaultCurrency = 'usd';
  }
  if (getCountryCode() === 'PL') {
    defaultCurrency = 'pln';
  }
  const currency = localStorage.getItem('currency') || defaultCurrency;
  return currency;
};

export const currency = getCurrency();

export const timeAgo = (created) => {
  const now = Date.now();
  const createdAt = new Date(created).getTime();
  const differenceInMilliseconds = now - createdAt;

  const seconds = differenceInMilliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (days >= 1) {
    return Math.floor(days) + 'd';
  } else if (hours >= 1) {
    return Math.floor(hours) + 'h';
  } else if (minutes >= 1) {
    return Math.floor(minutes) + 'm';
  } else {
    return 'Now';
  }
};

export const cleanLink = (linkText = '') =>
  linkText
    .toLowerCase?.()
    .replace(/ą/g, 'a')
    .replace(/ć/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ł/g, 'l')
    .replace(/ń/g, 'n')
    .replace(/ó/g, 'o')
    .replace(/ś/g, 's')
    .replace(/ż/g, 'z')
    .replace(/ź/g, 'z')
    .replace(/[^a-z0-9]/g, '-');

export const smoothScrollTo = ({ element, target, duration }) => {
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);

    element.scrollTop =
      (target - element.scrollTop) * progress + element.scrollTop;

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  requestAnimationFrame(animation);
};

export const getVideoResolution = (speedMbps = window?.connectionSpeed) => {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    screen.width;
  const devicePixelRatio = window.devicePixelRatio || 1;

  // Calculate effective screen width in terms of physical pixels
  const effectiveScreenWidth = screenWidth * devicePixelRatio;

  if (speedMbps >= 10 && effectiveScreenWidth > 720) {
    return '1080p'; // Full HD
  }
  if (speedMbps >= 6 && effectiveScreenWidth > 480) {
    return '720p'; // HD
  }
  if (speedMbps >= 2 && effectiveScreenWidth > 360) {
    return '480p'; // Standard Definition
  }
  if (speedMbps >= 1 && effectiveScreenWidth > 240) {
    return '360p';
  }
  if (speedMbps >= 0.2) {
    return '240p';
  }

  return '480p'; // default
};

export const getImageWidthAndQuality = ({
  speedMbps = window?.connectionSpeed,
  width = 0,
  forceWidth = 0,
  forceQuality = 0,
}) => {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    screen.width;
  const devicePixelRatio = window.devicePixelRatio || 1;

  // Calculate effective screen width in terms of physical pixels
  let effectiveScreenWidth =
    forceWidth || width * devicePixelRatio || screenWidth * devicePixelRatio;
  const { imageWidths } = config;

  let quality = 80;
  if (speedMbps >= 10) {
    quality = 100;
  }

  if (speedMbps < 6) {
    effectiveScreenWidth *= 0.5;
  }
  if (speedMbps < 3) {
    effectiveScreenWidth *= 0.5;
  }
  if (speedMbps < 1) {
    effectiveScreenWidth *= 0.5;
  }

  let finalImageWidth = imageWidths[imageWidths.length - 2];
  for (let i = 0; i < imageWidths.length; i++) {
    if (effectiveScreenWidth <= imageWidths[i]) {
      finalImageWidth = imageWidths[i];
      break;
    }
  }

  const finalImageHeight = 0;

  return `${finalImageWidth}x${finalImageHeight}x${forceQuality || quality}`;
};

export const bufferToHex = (buffer) => {
  return Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};

// Function to hash t with SHA-256
export const hashx = async (t) => {
  // Encode the t string into a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(t);

  // Use the SubtleCrypto API to hash the data
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the ArrayBuffer to a hex string
  const hashHex = bufferToHex(hashBuffer);

  return hashHex;
};

export const formatLink = (link = '') => {
  return link
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '');
};

export const formatRating = ({ rating }) => {
  if (rating === null) return '-';
  if (!rating || isNaN(rating)) return;
  return Number(Number(rating).toFixed(2));
};

export function sendManualPageview() {
  if (IS_ENV_DEVELOPMENT) return;
  gtag?.('event', 'page_view', {
    page_title: document.title,
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_search: window.location.search,
  });
  window?.fbq?.('track', 'PageView');
}
