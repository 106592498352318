import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes.js';
import classNames from 'classnames';

export const Card = ({
  id,
  index,
  moveCard,
  children,
  isSmallDragIcon,
  ...props
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const inputFieldSelector = [
    'textarea:focus',
    'input:not([type=checkbox]):focus',
    'input:not([type=radio]):focus',
    'button:focus',
  ].join(',');

  const [isInputFieldFocused, setInputFieldFocused] = useState(false);

  const onBlur = () => {
    setInputFieldFocused(false);
  };

  const onFocus = () => {
    setInputFieldFocused(
      ref.current.querySelector(inputFieldSelector) !== null
    );
  };

  if (isSmallDragIcon) {
    return (
      <div
        ref={ref}
        onBlur={onBlur}
        onFocus={onFocus}
        draggable={!isInputFieldFocused}
        data-handler-id={handlerId}
        className='drag-item-container drag-item-container-inner'
        {...props}
      >
        {children}
        <div
          {...props}
          className={classNames('drag-icon', {
            'is-dragging': isDragging,
          })}
        >
          <i className='fa-solid fa-bars' />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      onBlur={onBlur}
      onFocus={onFocus}
      draggable={!isInputFieldFocused}
      style={{ opacity }}
      className='drag-item-container'
      data-handler-id={handlerId}
      {...props}
    >
      {children}
    </div>
  );
};
