import { useEffect, useRef, memo } from 'react';

import { useViewportHeight } from '../../core/useViewportHeight';
import { useConnectionSpeed } from '../../core/useConnectionSpeed';
import { getVideoResolution, isMobile } from '../../core/utils';
import { config } from '../../config';

export const VideoBox = ({
  videoId,
  onTimeUpdate,
  isDynamicHeight = true,
  customResolution,
  playsInline = true,
  ...props
}) => {
  const videoRef = useRef(null);
  const [connectionSpeed, updateConnectionSpeed] = useConnectionSpeed();

  // const isInViewport1 = useIsInViewport(ref1);

  /*
  it will be displayed on mobile devices.

  */
  // useEffect(() => {
  //   if (!ref1.current) return;
  //   // if (isInViewport1 && ref1.current.paused) {
  //   //   ref1.current.play();
  //   //   return;
  //   // }
  //   if (
  //     !isInViewport1 &&
  //     ref1.current.played.length > 0 &&
  //     !ref1.current.paused
  //   ) {
  //     ref1.current?.pause?.();
  //   }
  // }, [isInViewport1]);

  // console.log(event.videoFile, isInViewport1);

  useEffect(() => {
    if (!window.lastConnectionSpeed) return;
    // if (window.lastConnectionSpeed > connectionSpeed) {
    // console.log('update to new conn speed');
    updateConnectionSpeed(window.lastConnectionSpeed);
    // }
  }, []);

  const viewportHeight = useViewportHeight();
  // if (isFullyLogged) {
  //   viewportHeight = window.innerHeight - 50 + 'px';
  // }
  let videoStyle = {};
  if (isMobile && isDynamicHeight) {
    videoStyle = {
      height: isMobile && `${viewportHeight}px`,
      maxHeight: isMobile && `${viewportHeight}px`,
    };
  }

  const resolution = customResolution || getVideoResolution(connectionSpeed);
  if (!videoId || !connectionSpeed) return null;

  return (
    <>
      {/* {connectionSpeed} mbps, {resolution}p */}
      <video
        id={`video-${videoId}`}
        className={'video-canvas'}
        muted
        autoPlay={false}
        src={`${config.imgHostUrl}/${videoId}_${resolution}.mp4`}
        type='video/mp4'
        playsInline={playsInline}
        onTimeUpdate={onTimeUpdate}
        ref={videoRef}
        style={videoStyle}
        poster={`${config.imgHostUrl}/${videoId}_poster_${resolution}.0000000.jpg`}
        {...props}
      >
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export const VideoBoxMemo = memo(VideoBox);
