import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ReactComponent as SolidStar } from '../../svgs/solid/star.svg';
// import { ReactComponent as RegularTicket } from '../../svgs/regular/ticket.svg';
import { ReactComponent as CardsBlank } from '../../svgs/regular/cards-blank.svg';

import { cleanLink, formatByLocale } from '../../core/utils';
import { STATUS_DEFAULT } from '../../consts';

import { UserCard } from '../User/UserCard';
import { isMobile, formatRating } from '../../core/utils';
import { Image } from '../Common/Image';

export const EventCard = ({ event, showSupplier = true, isMyEvents }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get('startDate') || '';
  const endDate = searchParams.get('endDate') || '';
  const exactDate = searchParams.get('exactDate') || '';

  const cardSearchParams = new URLSearchParams();
  if (startDate) cardSearchParams.set('startDate', startDate);
  if (endDate) cardSearchParams.set('endDate', endDate);
  if (exactDate) cardSearchParams.set('exactDate', exactDate);

  const eventLink = `/event/${event.eventId}/${cleanLink(
    event.name
  )}?${cardSearchParams.toString()}`;

  return (
    <div className='event-card'>
      <div className='event-image'>
        <Link
          to={eventLink}
          className=''
          target='_blank'
        >
          <Image
            id={event.imageId}
            defaultWidth={isMobile ? 0 : 400}
          />
        </Link>
      </div>
      <div className='event-info'>
        <div
          className='event-info-details'
          // to={eventLink}
          // target='_blank'
        >
          <div className='event-description'>
            <Link
              to={eventLink}
              target='_blank'
              className='event-name'
            >
              {event.name}
            </Link>

            {showSupplier && (
              <UserCard
                user={{
                  username: event.supplierUsername,
                  avatarId: event.supplierAvatarId,
                  videoId: event.supplierVideoId,
                  firstName: event.supplierFirstName,
                  lastName: event.supplierLastName,
                }}
                target='_blank'
              />
            )}

            <Link
              to={eventLink}
              className='event-description'
              target='_blank'
            >
              {event.nearestStartDatetime && (
                <div className='event-description'>
                  <div className='event-price-value date-value'>
                    {formatByLocale({
                      datetime: event.nearestStartDatetime,
                      endDatetime: new Date(
                        new Date(event.nearestStartDatetime).getTime() +
                          event.durationHoursFrom * 60 * 60 * 1000
                      ),
                    })}
                  </div>
                </div>
              )}
            </Link>
            <Link
              to={eventLink}
              target='_blank'
              className='event-header'
            >
              {/* <div className='event-header'> */}
              <div className='event-rating'>
                <SolidStar className='icon' />
                <div className='event-rating-value'>
                  {formatRating({ rating: event.rating })}
                </div>
                {event.reviewsCount && (
                  <>
                    <div className='event-rating-label'>
                      ({event.reviewsCount})
                    </div>
                  </>
                )}
                {/* {event.priceAdult && (
              <div className='event-description'>
                <div className='event-price-value'>
                  {Number(event.priceAdult)} zł{' '}
                  {labels[language].perPerson}
                </div>
              </div>
            )} */}
              </div>

              {event.priceAdult && (
                <div className='event-price'>
                  <CardsBlank className='icon icon-medium' />
                  <span>
                    <span className='event-price-value'>
                      {Number(event.priceAdult)} zł
                    </span>
                    {/* <span> {labels[language].perPerson}</span> */}
                  </span>
                </div>
              )}
            </Link>
          </div>
        </div>

        {isMyEvents && (
          <>
            <div className='event-actions'>
              <Link
                to={`/my-events/edit/${event.eventId}`}
                className='button-secondary'
              >
                Edytuj
              </Link>

              <Link
                to={`/my-events/edit-event-slots/${event.eventId}`}
                className='button-secondary'
              >
                Terminy
              </Link>

              <Link
                to={`/my-events/images/${event.eventId}`}
                className='button-secondary'
              >
                Zdjęcia
              </Link>
              {/* <Link
                to={`/my-events/videos/${event.eventId}`}
                className='button-secondary'
              >
                Filmy
              </Link> */}

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (!confirm('Czy na pewno chcesz usunąć? ')) return;
                  dispatch({
                    type: 'DELETE_EVENT',
                    payload: {
                      eventId: event.eventId,
                    },
                  });
                }}
                className='button-secondary button-danger'
              >
                Usuń
              </div>
            </div>
            {event.status === STATUS_DEFAULT && (
              <div className='status-pending'>
                Oczekuje na zatwierdzenie przez administratora
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
