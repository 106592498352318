import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import useLocalStorage from 'use-local-storage';

import { config } from '../../config';
import * as usersSlice from '../../store/app/usersSlice';
import * as appSlice from '../../store/app/appSlice';
import * as eventsSlice from '../../store/app/eventsSlice';
import { Loading } from '../Loading/Loading';
import { EventsList } from '../Event/EventsList';
import { UploadImage } from './UploadImage';
import { Image } from '../Common/Image';
import { UploadVideo } from './UploadVideo';
import { VideoBox } from '../Feed/VideoBox';
import { formatLink, formatRating, language, isMobile } from '../../core/utils';
import { labels } from '../../labels';
import { UserCard } from './UserCard';
import { Reviews } from '../Common/Reviews';
import { MODE_USER } from '../../consts';

import { ReactComponent as SolidPhone } from '../../svgs/solid/phone.svg';
import { ReactComponent as SolidLink } from '../../svgs/solid/link.svg';
import { ReactComponent as SolidStar } from '../../svgs/solid/star.svg';

function useUsername() {
  let { username } = useParams();

  if (username.startsWith('@')) {
    username = username.substring(1);
  }

  return username;
}

export const UserProfile = () => {
  const username = useUsername();
  const [localStorageAutoplay] = useLocalStorage('autoplay', 0);

  const dispatch = useDispatch();
  const user = useSelector(usersSlice.selectItem);
  const events = useSelector(eventsSlice.selectItems);
  const loggedUser = useSelector(appSlice.selectUserData);
  const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);
  // const isRoleSupplier = Boolean(loggedUser?.isRoleSupplier);

  const isMine = loggedUser?.username === username;
  const isEditable = loggedUser?.userId === user?.userId || isRoleAdmin;

  const initialReviewData = {
    firstName: '',
    lastName: '',
    description: '',
  };
  const [newReview, setNewReview] = useState(initialReviewData);

  const saveReview = () => {
    dispatch({
      type: 'ADMIN_SAVE_USER_REVIEW',
      payload: {
        userId: user.userId,
        username,
        ...newReview,
      },
    });
    setNewReview(initialReviewData);
  };

  const loadProfileData = () => {
    const payload = {};
    if (!isMine) {
      payload.username = username;
    }
    dispatch({
      type: 'FETCH_USER_PROFILE',
      payload,
    });
  };

  useEffect(() => {
    loadProfileData();
  }, [username]);

  useEffect(() => {
    dispatch({
      type: 'FETCH_EVENTS',
      payload: {
        username,
      },
    });
  }, [username]);

  if (!user || user?.username !== username) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content profile'>
      <Helmet>
        <title>
          @{user.username} {user.firstName} {user.lastName} | Bimbash
        </title>
      </Helmet>
      <div className='profile-avatar'>
        {user.videoId && (
          <>
            {user.videoStatus === 0 && (
              <div className='avatar-loading'>
                <Loading />
                <div>
                  Video jest przetwarzane, odśwież stronę za kilka minut
                </div>
              </div>
            )}
            {user.videoStatus === 1 && (
              <VideoBox
                id='profile-video'
                videoId={user.videoId}
                controls
                playsInline
                loop
                autoPlay={Boolean(localStorageAutoplay)}
                muted={false}
              />
            )}
            {user.videoStatus === 9 && (
              <div className='avatar-loading'>Error</div>
            )}
          </>
        )}
        {user.avatarId && (!isMobile || !user.videoId) && (
          // <img
          //   src={`${config.imgHostUrl}/${user.avatarId}_512.jpg`}
          //   alt='guide'
          //   className='avatar'
          // />
          <Image
            id={user.avatarId}
            // forceWidth={image.isProcessing ? 360 : 0}
            // forceQuality={image.isProcessing ? 80 : 0}
            defaultWidth={400}
            className='event-image'
          />
        )}

        {!user.avatarId && !user.videoId && (
          <img
            src={`${config.imgHostUrl}/default-avatar3.jpg`}
            alt='guide'
            className='avatar default-avatar'
          />
        )}

        {isEditable && (
          <div className='profile-media-actions'>
            <UploadImage
              title={labels[language].addImage}
              onUploaded={() => {
                loadProfileData();
              }}
              mode={MODE_USER}
              userId={user.userId}
            />
            <UploadVideo
              onUploaded={loadProfileData}
              title={labels[language].addVideo}
            />
          </div>
        )}
      </div>

      <div className='profile-description'>
        <div className='profile-content'>
          <div className='profile-header'>
            <div className=''>
              <h1>
                {user.firstName && (
                  <UserCard
                    user={user}
                    showAvatar={false}
                  />
                )}
                {!user.firstName && user.username}
              </h1>
              {/* {Boolean(user.reviews?.length) && (
                <div className='event-rating'>
                  <Stars rating={user.rating} />

                  <div className='event-rating-value'>
                    {formatRating({ rating: user.rating })}
                  </div>
                  <div className=''>({user.reviewsCount})</div>
                </div>
              )} */}

              {Boolean(user.rating) && (
                <div className='event-rating'>
                  <SolidStar className='icon' />
                  <div className='event-rating-value'>
                    {formatRating({ rating: user.rating })}
                  </div>
                  {user.reviewsCount && (
                    <div className=''>({user.reviewsCount})</div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='profile-desc'>{user.description}</div>

          {user.publicPhone && (
            <div className='profile-desc icon-line'>
              <SolidPhone />
              <a href={`tel:${user.publicPhone}`}>{user.publicPhone}</a>
            </div>
          )}

          {user.website && (
            <div className='profile-website icon-line'>
              <SolidLink />
              <a
                href={user.website}
                target='_blank'
                rel='noreferrer'
                className='link-basic link-regular'
                onClick={() => {
                  gtag?.('event', 'click', {
                    event_category: 'profile',
                    event_label: 'website',
                    event_action: 'click',
                    event_value: user.website,
                  });
                }}
              >
                {formatLink(user.website)}
              </a>
            </div>
          )}

          {/* {user.vatNumber && (
            <div className='profile-vat'>
              {labels[language].vatNumber}: {user.vatNumber}
            </div>
          )} */}

          {isEditable && (
            <div className='profile-actions'>
              <Link
                to={`/edit-profile/${username}`}
                className='edit-profile-button button-primary'
              >
                {labels[language].edit}
              </Link>
            </div>
          )}
        </div>

        {isRoleAdmin && (
          <div className='form form-simple form-inline'>
            <div>New review</div>
            <div>
              <input
                type='text'
                placeholder='first name'
                className='input-large'
                value={newReview.firstName}
                onChange={(e) => {
                  setNewReview({ ...newReview, firstName: e.target.value });
                }}
              />
              <input
                type='text'
                placeholder='last name'
                className='input-large'
                value={newReview.lastName}
                onChange={(e) => {
                  setNewReview({ ...newReview, lastName: e.target.value });
                }}
              />
            </div>
            <textarea
              className='textarea-large'
              onChange={(e) => {
                setNewReview({ ...newReview, description: e.target.value });
              }}
              value={newReview.description}
            />
            <div>
              <button
                className='button-primary'
                onClick={saveReview}
              >
                Save
              </button>
            </div>
          </div>
        )}

        <Reviews reviews={user.reviews} />

        <EventsList
          events={events}
          title={labels[language].events}
          showSupplier={false}
        />
      </div>
    </div>
  );
};

export default UserProfile;
