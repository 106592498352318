import { useState } from 'react';

import { getAuthHeaders } from '../../store/utils';

import { config } from '../../config';
import { showNotification } from '../../core/utils';
import { Loading } from '../Loading/Loading';

export const UploadVideo = ({ title = 'Upload', onUploaded, eventId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const avatarUploadHandler = async (event) => {
    setIsUploading(true);
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 300 * 1024 * 1024) {
      event.target.value = '';
      setIsUploading(false);
      showNotification({
        type: 'error',
        message: 'Plik nie może być większy niż 300 MB',
      });
      return;
    }

    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = async () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      console.log('duration', duration, typeof duration);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('duration', duration);
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      formData.append('videoWidth', videoWidth);
      formData.append('videoHeight', videoHeight);

      if (eventId) {
        formData.append('eventId', eventId);
      }

      try {
        const response = await fetch(`${config.appApiUrl}/videos/upload`, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: formData,
        });
        const result = await response.json();
        setIsUploading(false);
        // if (!result.avatarId) {
        //   throw new Error('Error uploading avatar');
        //   return;
        // }
        onUploaded({
          result,
        });
      } catch (e) {
        setIsUploading(false);
        showNotification({
          type: 'error',
          message: 'Error occured',
        });
        console.log(e);
      }
    };
    video.src = URL.createObjectURL(file);
  };

  return (
    <div className='upload-file-panel'>
      <div className='actions actions-inline'>
        {!isUploading && (
          <>
            <label
              htmlFor='fileupload2'
              className='button button-secondary'
              data-tooltip-id='tooltip'
              data-tooltip-content={`Accepted formats: MP4, MOV. Maximum file size of 200 MB`}
            >
              {title}
              {/* <FontAwesomeIcon
                      data-tooltip-id='tooltip'
                      data-tooltip-content={revenueInfo}
                      icon={solid('info-circle')}
                      onClick={toggleShowInfo}
                    /> */}
            </label>
            <input
              type='file'
              id='fileupload2'
              onChange={avatarUploadHandler}
              accept='video/mp4, video/quicktime'
              className='hide'
            />
          </>
        )}
      </div>
      {isUploading && <Loading />}
    </div>
  );
};
