import { useState } from 'react';

import { getAuthHeaders } from '../../store/utils';

import { config } from '../../config';
import { showNotification } from '../../core/utils';
import { Loading } from '../Loading/Loading';

export const UploadAvatar = ({ title = 'Upload', onUploaded, eventId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const avatarUploadHandler = async (event) => {
    setIsUploading(true);
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 50 * 1024 * 1024) {
      event.target.value = '';
      setIsUploading(false);
      showNotification({
        type: 'error',
        message: 'Plik nie może być większy niż 50 MB',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    if (eventId) {
      formData.append('eventId', eventId);
    }

    try {
      const response = await fetch(`${config.appApiUrl}/avatars/upload`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: formData,
      });
      const result = await response.json();
      setIsUploading(false);
      // if (!result.avatarId) {
      //   throw new Error('Error uploading avatar');
      //   return;
      // }
      onUploaded({
        result,
      });
    } catch (e) {
      setIsUploading(false);
      showNotification({
        type: 'error',
        message: 'Error occured',
      });
      console.log(e);
    }
  };

  return (
    <div className='upload-file-panel'>
      <div className='actions actions-inline'>
        {!isUploading && (
          <>
            <label
              htmlFor='fileupload1'
              className='button button-secondary'
              data-tooltip-id='tooltip'
              data-tooltip-content={`Accepted formats: JPG, PNG, WEBP. Maximum file size of 25 MB`}
            >
              {title}
              {/* <FontAwesomeIcon
                      data-tooltip-id='tooltip'
                      data-tooltip-content={revenueInfo}
                      icon={solid('info-circle')}
                      onClick={toggleShowInfo}
                    /> */}
            </label>
            <input
              type='file'
              id='fileupload1'
              onChange={avatarUploadHandler}
              accept='image/png, image/jpeg, image/webp'
              className='hide'
            />
          </>
        )}
      </div>
      {isUploading && <Loading />}
    </div>
  );
};
