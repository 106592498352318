import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Image } from '../Common/Image';
import { isMobile, language, showNotification } from '../../core/utils';
import { labels } from '../../labels';

export const Contact = () => {
  return (
    <div className='contact-page'>
      <Helmet>
        <title>{labels[language].contactUs} | Bimbash</title>
      </Helmet>
      <div className='contact-page-content'>
        <div className='contact-page-image'>
          {!isMobile && (
            <Image
              id='aTXRIskE5QdHKsDePstEj'
              className='photo-desktop'
            />
          )}
          {isMobile && (
            <Image
              id='kZK7KVaenk6bJlVCEkRVF'
              className='photo-mobile'
            />
          )}
        </div>
        <div className='contact-page-text'>
          <h3>{labels[language].contactUs}</h3>

          <div>{labels[language].haveQuestions}</div>
          <p>
            <b
              onClick={() => {
                navigator.clipboard.writeText('hi@bimbash.com');
                showNotification({
                  message: labels[language].copiedToClipboard,
                  type: 'success',
                });
              }}
            >
              hi@bimbash.com
            </b>
          </p>
          <br />
          {/* <div>{labels[language].chatWithOurAgent}</div> */}

          <NavLink
            to='/ai/@monika'
            className='link-regular'
          >
            {labels[language].ourGPT}
          </NavLink>

          {/* <div className='company-info'>
        <div>Tensorlab Adam Kasprowicz</div>
        <div>ul. Ludwinowska 11 / 53</div>
        <div>30-331 Kraków</div>
        <div>NIP: PL5050060550</div>
        <div>REGON: 366958751</div>
      </div> */}
        </div>
      </div>
    </div>
  );
};
