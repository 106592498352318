import { call, put, select, takeLatest } from 'redux-saga/effects';

import { showNotification } from '../../core/utils';
import { getAuthHeaders } from '../utils';
import config from '../../config';

import * as eventsSlice from '../app/eventsSlice';

const fetchEvents = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { startDate, endDate, page, itemsPerPage, filter, username, language } =
    payload;
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (filter) {
    searchParams.set('filter', filter);
  }
  if (itemsPerPage) {
    searchParams.set('itemsPerPage', itemsPerPage);
  }
  if (username) {
    searchParams.set('username', username);
  }
  if (language) {
    searchParams.set('language', language);
  }
  const response = await fetch(
    `${config.appApiUrl}/events/list?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

export function* fetchEventsSaga({ payload } = {}) {
  yield put(eventsSlice.setStatusPending());
  const { error, events, popularEvents, hasMore } = yield call(fetchEvents, {
    payload,
  });
  yield put(eventsSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  yield put(eventsSlice.setHasMore(hasMore));
  yield put(eventsSlice.setStatusDefault());
  if (payload.page && Number(payload.page)) {
    yield put(eventsSlice.addItems(events));
    return;
  }

  yield put(eventsSlice.setItems(events));
  if (popularEvents) {
    yield put(eventsSlice.setPopularEvents(popularEvents));
  }
}

const fetchEvent = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { eventId, startDate, endDate, exactDate } = payload;
  searchParams.set('eventId', eventId);
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }
  if (exactDate) {
    searchParams.set('exactDate', exactDate);
  }

  const response = await fetch(
    `${config.appApiUrl}/events/details?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

const fetchMyEvents = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { startDate, endDate, page, itemsPerPage } = payload;
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (itemsPerPage) {
    searchParams.set('itemsPerPage', itemsPerPage);
  }
  const response = await fetch(
    `${config.appApiUrl}/events/my-events?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchMyEventsSaga({ payload = {} } = {}) {
  yield put(eventsSlice.setStatusPending());
  const myEvents = yield call(fetchMyEvents, { payload });
  if (myEvents.error) {
    showNotification({
      message: myEvents.error,
      type: 'error',
    });
    yield put(eventsSlice.setStatusDefault());
    return;
  }
  yield put(eventsSlice.setStatusDefault());
  yield put(eventsSlice.setMyItems(myEvents));
}

const adminFetchEvents = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { startDate, endDate, page, itemsPerPage } = payload;
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }
  if (page) {
    searchParams.set('page', page);
  }
  if (itemsPerPage) {
    searchParams.set('itemsPerPage', itemsPerPage);
  }
  const response = await fetch(
    `${config.appApiUrl}/events/admin/list?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminFetchEventsSaga(action) {
  yield put(eventsSlice.setStatusPending());
  const events = yield call(adminFetchEvents, action);
  if (events.error) {
    showNotification({
      message: events.error,
      type: 'error',
    });
    yield put(eventsSlice.setStatusDefault());
    return;
  }

  yield put(eventsSlice.setItems(events));
  yield put(eventsSlice.setStatusDefault());
}

function* errorSaga({ payload }) {
  console.log(payload);
  showNotification({
    message: payload.error.message,
    type: 'error',
  });
}

function* showNotificationSaga({ payload }) {
  showNotification(payload);
  if (window?.navigateTo && payload.redirectUrl) {
    window.navigateTo(payload.redirectUrl);
  }
}

function* fetchEventSaga({ payload } = {}) {
  yield put(eventsSlice.setStatusPending());
  const event = yield call(fetchEvent, {
    payload,
  });
  yield put(eventsSlice.setStatusDefault());
  const { error } = event;
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  yield put(eventsSlice.setItem(event));
}

const saveEvent = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/events`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* saveEventSaga(action) {
  yield put(eventsSlice.setStatusPending());
  const { error, eventId } = yield call(saveEvent, action);
  yield put(eventsSlice.setStatusDefault());

  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  showNotification({
    message: 'Wydarzenie zapisane',
    type: 'success',
  });

  window.navigateTo(`/event/${eventId}`);

  // window.navigateTo(`/my-events`);
}

const savePrivateEvent = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/events/private`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* savePrivateEventSaga(action) {
  yield put(eventsSlice.setStatusPending());
  const { error } = yield call(savePrivateEvent, action);
  yield put(eventsSlice.setStatusDefault());

  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  window.navigateTo(
    `/private-tour/?tourType=${action.payload.tourType}&sent=1`
  );
}

const adminApproveEvent = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/events/admin/approve`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminApproveEventSaga(action) {
  yield put(eventsSlice.setStatusPending());
  const { error } = yield call(adminApproveEvent, action);
  yield put(eventsSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: 'Wydarzenie zatwierdzone',
  });
  window.navigateTo('/admin/events');
}

const deleteEvent = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/events/delete`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* deleteEventSaga({ payload } = {}) {
  const { error } = yield call(deleteEvent, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  if (payload?.redirectUrl) {
    window.navigateTo?.(payload.redirectUrl);
  }
  yield fetchMyEventsSaga({});
}

const deleteEventImage = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/images/delete`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* deleteEventImageSaga({ payload } = {}) {
  const { error } = yield call(deleteEventImage, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield fetchEventSaga({
    payload: {
      eventId: payload.eventId,
    },
  });
}

const reorderEventImages = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/images/reorder`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* reorderEventImagesSaga({ payload }) {
  const { error } = yield call(reorderEventImages, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  const event = yield select(eventsSlice.selectItem);
  yield put(
    eventsSlice.setItem({ ...event, eventImages: payload.eventImages })
  );
}

const eventAI = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/events/ai`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* eventAISaga(action) {
  const { error, eventId } = yield call(eventAI, action);

  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  showNotification({
    message:
      'Dodaliśmy Cię do listy chętnych - gdy wydarzenie zostanie dodane jako audio dostaniesz email',
    type: 'success',
  });

  // window.navigateTo(`/my-events`);
}

export function* eventsSagas() {
  yield takeLatest('FETCH_EVENTS', fetchEventsSaga);
  yield takeLatest('FETCH_MY_EVENTS', fetchMyEventsSaga);
  yield takeLatest('FETCH_EVENT', fetchEventSaga);
  yield takeLatest('SAVE_EVENT', saveEventSaga);
  yield takeLatest('DELETE_EVENT', deleteEventSaga);
  yield takeLatest('REORDER_EVENT_IMAGES', reorderEventImagesSaga);
  yield takeLatest('DELETE_EVENT_IMAGE', deleteEventImageSaga);

  yield takeLatest('SAVE_PRIVATE_EVENT', savePrivateEventSaga);

  yield takeLatest('ADMIN_FETCH_EVENTS', adminFetchEventsSaga);
  yield takeLatest('ADMIN_APPROVE_EVENT', adminApproveEventSaga);

  yield takeLatest('ERROR', errorSaga);
  yield takeLatest('SHOW_NOTIFICATION', showNotificationSaga);

  yield takeLatest('EVENT_AI', eventAISaga);
}
