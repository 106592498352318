import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import appReducer from './app/appSlice';
import usersReducer from './app/usersSlice';
import feedReducer from './app/feedSlice';
import eventsReducer from './app/eventsSlice';
import eventSlotsReducer from './app/eventSlotsSlice';
import ordersReducer from './app/ordersSlice';
import placesReducer from './app/placesSlice';
import aiReducer from './app/aiSlice';

// import { getStateFromCache } from './cache';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    ai: aiReducer,
    feed: feedReducer,
    events: eventsReducer,
    eventSlots: eventSlotsReducer,
    orders: ordersReducer,
    places: placesReducer,
    // avatars: avatarsReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware);
  },
  //preloadedState: localState
});

sagaMiddleware.run(rootSaga);

// const loadLocalState = async () => {
//   const localState = await getStateFromCache();
//   if (localState) {
//     store.dispatch(
//       Wallet.initLocalWallet(localState.wallet)
//     );
//     store.dispatch(
//       Wallet.initExternalWallet(localState.wallet.externalWallet)
//     );
//   }
//   store.dispatch({
//     type: 'INIT_WALLET'
//   });
// }

// loadLocalState();

store.dispatch({
  type: 'INIT',
});
window.store = store;
