import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  showNotification,
  getCountryCode,
  getLanguageCode,
  language,
  isMobile,
} from '../../core/utils';
import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { labels } from '../../labels';
import { Image } from '../Common/Image';

export const SignUpSupplier = ({ darkTheme, isInline, onChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStatusPending = useSelector(appSlice.selectIsStatusPending);
  const utm = useSelector(appSlice.selectUtm);

  const loggedUser = useSelector(appSlice.selectUserData);
  useEffect(() => {
    if (loggedUser) {
      navigate('/my-events');
    }
  }, [loggedUser]);

  const referralUserId = useSelector(appSlice.selectReferralUserId);

  const languageId = getLanguageCode();
  const initialData = {
    darkTheme: darkTheme ? 1 : 0,
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    companyName: '',
    companyStreet: '',
    companyPostCode: '',
    companyCity: '',
    vatNumber: '',
    isRoleSupplier: 1,
    repeatPassword: '',
    countryId: getCountryCode(),
    languageId,
    isInline,
    referralUserId,
    ...utm,
  };

  const [data, setData] = useState(initialData);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    if (!data.email || !data.email.includes('@')) {
      const element = document.getElementById('email');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].email}`,
        type: 'error',
      });
      // const element = document.getElementById('email');
      // element?.scrollIntoView?.(element);
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(data.email).toLowerCase())) {
      const element = document.getElementById('email');
      element?.scrollIntoView?.(element);
      showNotification({
        message: labels[language].invalidEmail,
        type: 'error',
      });
      return;
    }

    if (!data.password) {
      const element = document.getElementById('password');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].password}`,
        type: 'error',
      });
      return;
    }

    if (!data.phone) {
      const element = document.getElementById('phone');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].phoneGeneral}`,
        type: 'error',
      });
      return;
    }

    dispatch({
      type: 'SIGN_UP',
      payload: data,
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const code = e.which || e.keyCode;
      let charCode = String.fromCharCode(code).toLowerCase();
      if (((e.ctrlKey || e.metaKey) && charCode === 's') || code === 13) {
        handleSave(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleSave]);

  return (
    <div className='main-container'>
      <div className='sign-up-supplier-content'>
        <div>
          {!isMobile && (
            <Image
              id='W2bvKfda76-APsaM0MTe2'
              className='photo'
            />
          )}
          {isMobile && (
            <Image
              id='dI8b-NFkPk-mPaFA2byV1'
              className='photo-mobile'
            />
          )}
        </div>
        <form
          className='form-basic form-sign-up-supplier'
          onSubmit={handleSave}
        >
          {!isInline && (
            <Helmet>
              <title>{labels[language].signUpSupplier} | Bimbash</title>
            </Helmet>
          )}
          {!isStatusPending && (
            <>
              {!isInline && (
                <div className='form-header'>
                  {labels[language].signUpSupplier}
                </div>
              )}
              <div className=''>
                <div>Uzyskaj dostęp do turystów zainteresowanych Krakowem</div>
                <ul className='promo-list'>
                  <li>Dodanie wydarzenia jest proste i bezpłatne</li>
                  <li>Prowizja jest pobierana tylko od sprzedanych biletów</li>
                  <li>Wynagrodzenie jest wypłacane automatycznie co 7 dni</li>
                  <li>
                    Chcesz wiedzieć więcej? Przeczytaj{' '}
                    <a
                      href='/faq.html#supplier'
                      className='link link-regular'
                      target='_blank'
                    >
                      FAQ dla Przewodników
                    </a>
                  </li>
                </ul>
              </div>

              <div className='line line-email'>
                <div className='label'>{labels[language].email}</div>
                <input
                  id='email'
                  type='email'
                  required
                  autoComplete='off'
                  name='email'
                  value={data.email}
                  onChange={(e) => {
                    const trimmedValue = e.target.value?.trim?.();
                    setData({
                      ...data,
                      email: trimmedValue,
                    });
                    onChange?.({
                      ...data,
                      email: trimmedValue,
                    });
                  }}
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].password}</div>
                <input
                  id='password'
                  name='password'
                  type={passwordFocused ? 'text' : 'password'}
                  autoComplete='new-password'
                  value={data.password}
                  required
                  onFocus={() => {
                    setPasswordFocused(true);
                  }}
                  onBlur={() => {
                    setPasswordFocused(false);
                  }}
                  onClick={() =>
                    setData({
                      ...data,
                      password: '',
                      repeatPassword: '',
                    })
                  }
                  onChange={(e) => {
                    const trimmedValue = e.target.value?.trim?.();
                    setData({
                      ...data,
                      password: trimmedValue,
                    });
                    onChange?.({
                      ...data,
                      password: trimmedValue,
                    });
                  }}
                />
              </div>

              <div className='line line-email'>
                <div className='label'>{labels[language].phoneGeneral}</div>
                <input
                  id='phone'
                  name='phone'
                  type='text'
                  required
                  autoComplete='tel'
                  value={data.phone}
                  onChange={(e) => {
                    const trimmedValue = e.target.value?.trim?.();
                    setData({
                      ...data,
                      phone: trimmedValue,
                    });
                    onChange?.({
                      ...data,
                      phone: trimmedValue,
                    });
                  }}
                />
              </div>

              {/* <div className='line'>
                <div className='label'>{labels[language].firstName}</div>
                <input
                  id='firstName'
                  name='firstName'
                  type='text'
                  value={data.firstName}
                  onChange={(e) =>
                    setData({
                      ...data,
                      firstName: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].lastName}</div>
                <input
                  id='lastName'
                  name='lastName'
                  type='text'
                  value={data.lastName}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lastName: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].phone}</div>
                <input
                  id='phone'
                  name='telephone'
                  type='text'
                  value={data.phone}
                  onChange={(e) =>
                    setData({
                      ...data,
                      phone: e.target.value.trim(),
                    })
                  }
                />
              </div>
        
              <div className='line'>
                <h3>{labels[language].companyDetails}</h3>

                <div className='label'>{labels[language].companyName}</div>
                <input
                  id='companyName'
                  name='companyName'
                  type='text'
                  value={data.companyName}
                  onChange={(e) =>
                    setData({
                      ...data,
                      companyName: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].companyStreet}</div>
                <input
                  id='companyStreet'
                  name='companyStreet'
                  type='text'
                  value={data.companyStreet}
                  onChange={(e) =>
                    setData({
                      ...data,
                      companyStreet: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].companyPostCode}</div>
                <input
                  id='companyPostCode'
                  name='companyPostCode'
                  type='text'
                  value={data.companyPostCode}
                  onChange={(e) =>
                    setData({
                      ...data,
                      companyPostCode: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].companyCity}</div>
                <input
                  id='companyCity'
                  name='companyCity'
                  type='text'
                  value={data.companyCity}
                  onChange={(e) =>
                    setData({
                      ...data,
                      companyCity: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='line'>
                <div className='label'>{labels[language].vatNumber}</div>
                <input
                  id='vatNumber'
                  name='vatNumber'
                  type='text'
                  value={data.vatNumber}
                  onChange={(e) =>
                    setData({
                      ...data,
                      vatNumber: e.target.value?.trim?.(),
                    })
                  }
                />
              </div>

                     */}

              <div className='info-lines'>
                <div className='line info'>
                  <input
                    type='checkbox'
                    required
                    checked={true}
                    className='input-checkbox'
                    onClick={() => {
                      alert(
                        'Akceptacja regulaminu jest wymagana. Jeśli nie akceptujesz regulaminu, nie możesz założyć konta.'
                      );
                    }}
                    onChange={() => {}}
                  />
                  {labels[language].agreeWithTerms}{' '}
                  {language === 'en' && (
                    <a
                      href='/terms.html'
                      target='_blank'
                      className='link-basic'
                    >
                      {labels[language].terms}
                    </a>
                  )}
                  {language === 'pl' && (
                    <a
                      href='/regulamin.html'
                      target='_blank'
                      className='link-basic'
                    >
                      {labels[language].terms}
                    </a>
                  )}
                </div>
              </div>
              {/* <input
                onClick={handleSave}
                type='button'
                className='submit button-submit'
                value={labels[language].createAccount}
              /> */}

              <button
                type='submit'
                className='submit button-submit'
              >
                {labels[language].createAccount}
              </button>
            </>
          )}

          {isStatusPending && <Loading />}
        </form>
      </div>
    </div>
  );
};
