import { call, put, takeLatest } from 'redux-saga/effects';

import * as appSlice from '../app/appSlice';
import { showNotification } from '../../core/utils';

import { config } from '../../config';
import { getAuthHeaders } from '../utils';

/*
const uploadVideo = async () => {
  try {
    const formData = new FormData();
    formData.append('file', selectedFile);
    // formData.append('description', description);
    // formData.append('encodedTransaction', encodedTransaction);
    formData.append('videoWidth', videoDetails.videoWidth);

    // if (addedSoundId) {
    // 	formData.append('soundVolume', soundVolume);
    // 	formData.append('addedSoundId', addedSoundId);
    // 	formData.append('addedSoundVolume', addedSoundVolume);
    // }

    const response = await fetch(`${config.appApiUrl}/videos/upload`, {
      method: 'POST',
      mode: 'cors',
      headers: getAuthHeaders(),
      body: formData,
    });
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    console.error('Error:', error);
  }
};
*/

export function* uploadVideoSaga() {
  // yield put(testsSlice.setStatusPending());
  // const { data, error } = yield call(uploadVideo, { payload });
  //   yield put(testsSlice.setStatusDefault());
}

export function* redirectExternalSaga({ payload } = {}) {
  yield put(appSlice.setRedirectUrl(payload.redirectUrl));
  window.location.href = payload.redirectUrl;
}

export function* redirectInternalSaga({ payload } = {}) {
  if (window.navigateTo) {
    window.navigateTo(payload.redirectUrl);
    return;
  }
  window.location.href = payload.redirectUrl;
}

const fetchStats = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { startDate, endDate } = payload;
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }

  const response = await fetch(
    `${config.appApiUrl}/event-slots/stats?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

export function* fetchStatsSaga(action) {
  const data = yield call(fetchStats, action);
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  yield put(appSlice.setStats(data));
}

export function* appSagas() {
  yield takeLatest('UPLOAD_VIDEO', uploadVideoSaga);
  yield takeLatest('REDIRECT_EXTERNAL', redirectExternalSaga);
  yield takeLatest('REDIRECT_INTERNAL', redirectInternalSaga);
  yield takeLatest('FETCH_STATS', fetchStatsSaga);
}
