export const Dialog = ({
  children,
  title,
  actions,
  onClose,
  className = '',
}) => {
  const handleClose = (e) => {
    if (['dialog', 'button-x'].includes(e.target.id)) {
      onClose?.(e);
    }
  };

  return (
    <>
      <div
        className='dialog-bg'
        id='dialog-bg'
        onMouseDown={handleClose}
      >
        <div
          className='dialog'
          id='dialog'
        >
          <div className={`dialog-layout ${className}`}>
            <div className='dialog-header'>
              <div className='dialog-title'>{title}</div>
              {onClose && (
                <div
                  className='x'
                  id='button-x'
                  onClick={handleClose}
                >
                  X
                </div>
              )}
            </div>
            <div className='dialog-content'>{children}</div>
            {actions && (
              <div className='line actions dialog-footer'>{actions}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
