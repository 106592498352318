import { useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { registerLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

import * as eventSlotsSlice from '../../../store/app/eventSlotsSlice';
import { Loading } from '../../Loading/Loading';
import { language, showNotification } from '../../../core/utils';
import { labels } from '../../../labels';

export const AdminEventSlots = () => {
  const dispatch = useDispatch();
  const eventSlots = useSelector(eventSlotsSlice.selectItems);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate =
    searchParams.get('startDate') ||
    format(new Date(), 'yyyy-MM-dd', {
      locale: pl,
    });
  const endDate = searchParams.get('endDate') || '';

  useEffect(() => {
    dispatch({
      type: 'FETCH_EVENT_SLOTS',
      payload: {
        startDate,
        endDate,
        isAdminView: true,
      },
    });
  }, [startDate, endDate]);

  if (!eventSlots) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content'>
      <Helmet>
        <title>{labels[language].myEventSlots} | Bimbash</title>
      </Helmet>

      <div className='page-filters'>
        <div className='input-datepicker-container'>
          <i className='fa-regular fa-calendar-range' />
          <DatePicker
            placeholderText='Wybierz terminy'
            onChange={(dates) => {
              const [start, end] = dates;
              searchParams.set(
                'startDate',
                format(start, 'yyyy-MM-dd', {
                  locale: pl,
                })
              );
              if (!end) {
                searchParams.delete('endDate');
              }
              if (end) {
                searchParams.set(
                  'endDate',
                  format(end, 'yyyy-MM-dd', {
                    locale: pl,
                  })
                );
              }
              setSearchParams(searchParams);
            }}
            dateFormat='MMM dd'
            locale='pl'
            selectsRange
            // inline
            startDate={startDate && new Date(startDate)}
            endDate={endDate && new Date(endDate)}
          />
        </div>
      </div>

      <div className='edit-event-slots'>
        <table className=''>
          <thead>
            <tr>
              <th>Data</th>
              <th>Sprzedane</th>
              <th>Dostepne</th>
            </tr>
          </thead>
          <tbody>
            {eventSlots.map((eventSlot) => {
              return (
                <tr
                  className=''
                  key={eventSlot.eventSlotId}
                >
                  <td className='event-slot-data event-slot-data-content'>
                    <NavLink
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                      className='a-text'
                    >
                      {format(
                        new Date(eventSlot.startDatetime),
                        'EEEE dd.MM.yyyy HH:mm',
                        {
                          locale: pl,
                        }
                      )}
                    </NavLink>

                    <NavLink
                      to={`/@${eventSlot.supplierUsername}`}
                      className='a-text'
                    >
                      {eventSlot.supplierFirstName} {eventSlot.supplierLastName}
                    </NavLink>

                    <NavLink
                      to={`/event/${eventSlot.eventId}`}
                      className='a-text'
                    >
                      {eventSlot.eventName}
                    </NavLink>

                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${window.location.origin}/event/${eventSlot.eventId}`
                        );
                        showNotification({
                          message: 'Skopiowano link do wydarzenia',
                          type: 'success',
                        });
                      }}
                      className='button-default'
                    >
                      Kopiuj link
                    </button>
                  </td>

                  <td className='tickets-count'>
                    <NavLink
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                      className='a-text'
                    >
                      {eventSlot.ticketsSoldCount} / {eventSlot.ticketsCount}
                    </NavLink>
                  </td>
                  <td className='tickets-count'>
                    {eventSlot.ticketsCount - eventSlot.ticketsSoldCount}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
