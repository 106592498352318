import { useEffect } from 'react';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';
import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { language } from '../../core/utils';
import { labels } from '../../labels';

export const MyEventSlots = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedUser = useSelector(appSlice.selectUserData);
  const isAdminView = Boolean(loggedUser?.isRoleAdmin);

  const eventSlots = useSelector(eventSlotsSlice.selectItems);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate =
    searchParams.get('startDate') ||
    format(new Date(), 'yyyy-MM-dd', {
      locale: pl,
    });
  const endDate = searchParams.get('endDate') || '';

  useEffect(() => {
    dispatch({
      type: 'FETCH_EVENT_SLOTS',
      payload: {
        startDate,
        endDate,
        isAdminView,
      },
    });
  }, [startDate, endDate, isAdminView]);

  if (!eventSlots) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content'>
      <Helmet>
        <title>{labels[language].myEventSlots} | Bimbash</title>
      </Helmet>

      <div className='page-filters'>
        <div className='input-datepicker-container'>
          <i className='fa-regular fa-calendar-range' />
          <DatePicker
            placeholderText='Wybierz terminy'
            onChange={(dates) => {
              const [start, end] = dates;
              searchParams.set(
                'startDate',
                format(start, 'yyyy-MM-dd', {
                  locale: pl,
                })
              );
              if (!end) {
                searchParams.delete('endDate');
              }
              if (end) {
                searchParams.set(
                  'endDate',
                  format(end, 'yyyy-MM-dd', {
                    locale: pl,
                  })
                );
              }
              setSearchParams(searchParams);
            }}
            dateFormat='MMM dd'
            locale='pl'
            selectsRange
            // inline
            startDate={startDate && new Date(startDate)}
            endDate={endDate && new Date(endDate)}
          />
        </div>
      </div>

      <div className='edit-event-slots'>
        <table className=''>
          <thead>
            <tr>
              <th>Data</th>
              <th>Wydarzenie</th>
              <th>Sprzedane bilety</th>
              <th>Edytuj</th>
              <th>Usuń</th>
            </tr>
          </thead>
          <tbody>
            {eventSlots.map((eventSlot) => {
              return (
                <tr
                  className=''
                  key={eventSlot.eventSlotId}
                >
                  <td className='event-slot-data'>
                    <NavLink
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                    >
                      {format(
                        new Date(eventSlot.startDatetime),
                        'EEEE dd.MM.yyyy HH:mm',
                        {
                          locale: pl,
                        }
                      )}
                    </NavLink>
                  </td>
                  <td className='event-slot-data'>
                    <NavLink to={`/event/${eventSlot.eventId}`}>
                      {eventSlot.eventName}
                    </NavLink>
                  </td>
                  <td className='tickets-count'>
                    <NavLink
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                    >
                      {eventSlot.ticketsSoldCount} / {eventSlot.ticketsCount}
                    </NavLink>
                  </td>
                  <td>
                    <NavLink
                      className='button-action'
                      to={`/my-events/edit-event-slot/${eventSlot.eventId}/${eventSlot.eventSlotId}`}
                    >
                      Edytuj
                    </NavLink>
                  </td>
                  <td>
                    <a
                      className='button-action'
                      onClick={() => {
                        if (eventSlot.ticketsSoldCount > 0) {
                          navigate(
                            `/my-events/delete-event-slot/${eventSlot.eventSlotId}`
                          );
                          return;
                        }

                        if (!confirm(`Czy na pewno chcesz usunąć?`)) return;

                        dispatch({
                          type: 'DELETE_EVENT_SLOT',
                          payload: {
                            eventSlotId: eventSlot.eventSlotId,
                            isInline: true,
                          },
                        });
                      }}
                    >
                      Usuń
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
