import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  selectIsStatusPending,
  selectAuthToken,
} from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { labels } from '../../labels';
import { language } from '../../core/utils';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const passwordResetHash = searchParams.get('passwordResetHash');
  const [password, setPassword] = useState('');
  const isStatusPending = useSelector(selectIsStatusPending);
  const authToken = useSelector(selectAuthToken);

  useEffect(() => {
    if (authToken) {
      navigate('/');
    }
  }, [authToken]);

  const onClickSubmit = () => {
    const decodedPasswordResetHash = decodeURIComponent(passwordResetHash);

    dispatch({
      type: 'PASSWORD_RESET_UPDATE',
      payload: {
        password,
        passwordResetHash: decodedPasswordResetHash,
      },
    });
  };

  return (
    <div className='container-centered'>
      <Helmet>
        <title>{labels[language].forgotPassword} | Bimbash</title>
      </Helmet>
      <div className='container-basic'>
        <div className='form-basic form-centered'>
          <div className='form-header'>{labels[language].forgotPassword}</div>

          <div className='line'>
            <div className='label'>{labels[language].newPassword}</div>
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => setPassword(e.target.value.trim())}
            />
          </div>

          {!isStatusPending && (
            <input
              onClick={onClickSubmit}
              type='submit'
              className='submit button-submit'
              value={labels[language].save}
            />
          )}

          {isStatusPending && <Loading />}
        </div>
      </div>
    </div>
  );
};
