import { createSlice } from '@reduxjs/toolkit';

const STATUS_DEFAULT = 0;
const STATUS_PENDING = 1;

const initialState = {
  status: STATUS_DEFAULT,
  item: null,
  items: [],
  myItems: [],
  popularEvents: [],
  hasMore: true,
  generatedItem: null,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { app } = payload;
      if (!app) return;
      for (const prop in initialState) {
        if (typeof app[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    reset: (state) => {
      for (const prop in initialState) {
        state[prop] = initialState[prop];
      }
    },
    setStatusPending: (state) => {
      state.status = STATUS_PENDING;
    },
    setStatusDefault: (state) => {
      state.status = STATUS_DEFAULT;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    addItems: (state, { payload }) => {
      state.items = [...state.items, ...payload];
    },
    setMyItems: (state, { payload }) => {
      state.myItems = payload;
    },
    setItem: (state, { payload }) => {
      state.item = payload;
    },
    setHasMore: (state, { payload }) => {
      state.hasMore = payload;
    },
    setPopularEvents: (state, { payload }) => {
      state.popularEvents = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  reset,
  setStatusPending,
  setStatusDefault,
  setItems,
  addItems,
  setMyItems,
  setItem,
  setHasMore,
  setPopularEvents,
} = eventsSlice.actions;

export const selectEvents = (state = window.store?.getState()) => state?.events;
export const selectItems = (state) => selectEvents(state)?.items;
export const selectMyItems = (state) => selectEvents(state)?.myItems;
export const selectItem = (state) => selectEvents(state)?.item;
export const selectEventImages = (state) =>
  selectEvents(state)?.item?.eventImages;
export const selectHasMore = (state) => selectEvents(state)?.hasMore;
export const selectIsStatusPending = (state) =>
  selectEvents(state)?.status === STATUS_PENDING;
export const selectIsStatusDefault = (state) =>
  selectEvents(state)?.status === STATUS_DEFAULT;
export const selectPopularEvents = (state) =>
  selectEvents(state)?.popularEvents;

export default eventsSlice.reducer;
