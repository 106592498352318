import { useEffect, useState } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import classNames from 'classnames';
registerLocale('pl', pl);

import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';
import * as appSlice from '../../store/app/appSlice';
import { config } from '../../config';
import { Loading } from '../Loading/Loading';
import { showNotification, language } from '../../core/utils';

import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_COMPLETE,
  ORDER_STATUS_REFUNDED,
  orderStatusLabels,
  priceCategories,
} from '../../consts';
import { labels } from '../../labels';

export const EventSlotTickets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [impersonateUserId, setImpersonateUserId] = useLocalStorage(
    config.impersonateUserIdKey,
    0
  );

  const { eventSlotId } = useParams();
  const eventSlot = useSelector(eventSlotsSlice.selectItem);
  const isStatusPending = useSelector(eventSlotsSlice.selectIsStatusPending);
  const loggedUser = useSelector(appSlice.selectUserData);

  const initialRefundData = {
    description: '',
    refundAmounts: [],
  };
  const [refundData, setRefundData] = useState(initialRefundData);
  const [isMassRefundFormVisible, setIsMassRefundFormVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'FETCH_EVENT_SLOT',
      payload: {
        eventSlotId,
      },
    });
  }, []);

  useEffect(() => {
    setRefundData({
      ...initialRefundData,
      refundAmounts: eventSlot?.eventPrices?.map((ep) => {
        return {
          priceCategoryId: ep.priceCategoryId,
          price: ep.price,
        };
      }),
    });
  }, [eventSlot]);

  const sendMassRefund = () => {
    if (!confirm(`Czy na pewno chcesz wysłać masowe zwroty?`)) return;

    dispatch({
      type: 'MASS_REFUND_EVENT_SLOT',
      payload: {
        eventSlotId,
        ...refundData,
      },
    });
  };

  // const [inviteData, setInviteData] = useState({
  //   email: '',
  //   adultsNumber: 1,
  //   childrenNumber: 0,
  // });

  // const sendInvitation = () => {
  //   dispatch({
  //     type: 'ADMIN_EVENT_SLOT_INVITE',
  //     payload: {
  //       eventSlotId,
  //       ...inviteData,
  //     },
  //   });
  // };

  if (!eventSlot || isStatusPending) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  const eventSlotDate = format(
    new Date(eventSlot.startDatetime),
    'EEEE dd.MM.yyyy HH:mm',
    {
      locale: pl,
    }
  );

  const priceCategoriesOfOrderProducts = eventSlot?.orderProducts?.reduce(
    (acc, order) => {
      priceCategories.forEach(({ priceCategoryId }) => {
        acc[priceCategoryId] = acc[priceCategoryId] || 0;
        acc[priceCategoryId] += order[priceCategoryId];
      });
      return acc;
    },
    {}
  );

  const orderParticipantColumns = priceCategories.filter(
    ({ priceCategoryId }) => priceCategoriesOfOrderProducts[priceCategoryId]
  );

  const totalRefundedAmountOfOrderProducts = eventSlot?.orderProducts?.reduce(
    (acc, order) => {
      acc += Number(order.refundedAmount);
      return acc;
    },
    0
  );

  const totalAmountOfOrderProducts = eventSlot?.orderProducts?.reduce(
    (acc, order) => {
      acc += Number(order.amount);
      return acc;
    },
    0
  );

  return (
    <div className='content'>
      <Helmet>
        <title>
          {eventSlotDate} {eventSlot.eventName} | Bimbash
        </title>
      </Helmet>
      <div className='event-slots-tickets'>
        <div
          className=''
          key={eventSlot.eventSlotId}
        >
          <div className=''>
            <div className='event-slots-tickets-info'>
              <div>Bilety na wydarzenie</div>
              <div>
                <NavLink
                  to={`/event/${eventSlot.eventId}`}
                  className='event-name'
                >
                  {eventSlot.eventName}
                </NavLink>
              </div>
              <div className='event-date'>{eventSlotDate}</div>

              <div className=''>
                <div>
                  Dostępne bilety:{' '}
                  {eventSlot.ticketsCount - eventSlot.ticketsSoldCount}
                </div>
              </div>

              {Boolean(eventSlot.ticketsSoldCount) && (
                <>
                  <div className=''>
                    <div>Sprzedane:</div>
                    {/* <div>{eventSlot.ticketsSoldCount}</div> */}
                    {orderParticipantColumns.map(({ priceCategoryId }) => {
                      return (
                        <div key={priceCategoryId}>
                          {labels[language][priceCategoryId]}{' '}
                          {priceCategoriesOfOrderProducts[priceCategoryId]}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <span
                      data-tooltip-id='tooltip'
                      data-tooltip-content={`Prowizja Bimbash jest już odjęta od tej kwoty. To jest ostateczna kwota jaką przelewamy na konto organizatora.`}
                    >
                      Wynagrodzenie:{' '}
                      {Number(
                        (
                          (totalAmountOfOrderProducts -
                            totalRefundedAmountOfOrderProducts) *
                          0.8
                        ).toFixed(2)
                      )}{' '}
                      zł
                    </span>
                  </div>
                </>
              )}
            </div>

            {/* <Link
              to={`/@${eventSlot.username}`}
              className='event-guide link-basic'
            >
              {eventSlot.username} {eventSlot.userEmail}
            </Link> */}
            <div className='orders'>
              <table>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Tel</th>
                    {orderParticipantColumns.map(({ priceCategoryId }) => (
                      <th key={priceCategoryId}>
                        {labels[language][priceCategoryId]}
                      </th>
                    ))}
                    <th>Koszt [PLN]</th>
                    <th>Data zakupu</th>
                    <th>Status</th>
                    <th>ID biletu</th>
                    <th>Zwrocono [PLN]</th>
                    <th>Akcje</th>
                  </tr>
                </thead>
                <tbody>
                  {eventSlot.orderProducts?.map((order) => (
                    <tr
                      key={order.orderId}
                      className={classNames('xorder', {
                        'order-pending': !Boolean(order.status),
                        'order-canceled':
                          order.status === ORDER_STATUS_CANCELED,
                        'order-complete':
                          order.status === ORDER_STATUS_COMPLETE,
                        'order-refunded':
                          order.status === ORDER_STATUS_REFUNDED,
                      })}
                    >
                      {order.isInvited && <td className='pointer'>Invited</td>}

                      <td
                        className='pointer'
                        onClick={() => {
                          navigator.clipboard.writeText(order.userEmail);
                          showNotification({
                            message: 'Skopiowano do schowka',
                          });
                        }}
                      >
                        {order.userEmail}{' '}
                        {loggedUser?.isRoleAdmin && (
                          <span
                            className='link-basic'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const returnPage =
                                window.location.pathname +
                                window.location.search;
                              localStorage.setItem('returnPage', returnPage);
                              localStorage.removeItem('landingPage');
                              setImpersonateUserId(order.userUserId);
                              window.location = `/my-tickets`;
                            }}
                          >
                            Impersonate
                          </span>
                        )}
                      </td>
                      <td>{order.userPhone}</td>

                      {orderParticipantColumns.map(({ priceCategoryId }) => (
                        <td key={priceCategoryId}>{order[priceCategoryId]}</td>
                      ))}

                      <td>
                        {Number(order.amount)}
                        {order.extOrderId ? ' PayU' : ''}
                      </td>

                      <td>
                        {format(new Date(order.created), 'dd.MM.yyyy HH:mm', {
                          locale: pl,
                        })}
                      </td>

                      <td>{orderStatusLabels.pl[order.status]}</td>

                      {order.orderId && (
                        <td
                          className='pointer'
                          onClick={() => {
                            navigator.clipboard.writeText(order.orderId);
                            showNotification({
                              message: 'Skopiowano do schowka',
                            });
                          }}
                        >
                          {order.orderId}
                        </td>
                      )}

                      <td>{Number(order.refundedAmount)}</td>

                      {/* 
                      {order.status === ORDER_STATUS_PENDING && (
                        <>
                          <td>
                            <button
                              className='button-submit'
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  order.redirectUrl
                                );
                                showNotification({
                                  message:
                                    'Skopiowano link platnosci do schowka',
                                });
                              }}
                            >
                              Skopiuj link Stripe
                            </button>
                          </td>

                          <td>
                            <button
                              className='button-secondary'
                              onClick={() => {
                                if (!confirm('Czy na pewno chcesz usunac?'))
                                  return;
                                dispatch({
                                  type: 'DELETE_ORDER',
                                  payload: {
                                    orderId: order.orderId,
                                    orderProductId: order.orderProductId,
                                    eventSlotId: eventSlot.eventSlotId,
                                  },
                                });
                              }}
                            >
                              Usun
                            </button>
                          </td>
                        </>
                      )} */}

                      {!order.isInvited &&
                        order.status === ORDER_STATUS_COMPLETE &&
                        order.amount > 0 && (
                          <>
                            <td>
                              <button
                                className='button-secondary'
                                onClick={() => {
                                  if (
                                    !confirm(
                                      'Czy na pewno chcesz anulowac te bilety i zwrocic pieniadze na konto uzytkownika?'
                                    )
                                  )
                                    return;
                                  dispatch({
                                    type: 'REFUND_ORDER',
                                    payload: {
                                      orderId: order.orderId,
                                      orderProductId: order.orderProductId,
                                      eventSlotId: eventSlot.eventSlotId,
                                    },
                                  });
                                }}
                              >
                                Zwróć
                              </button>
                            </td>
                          </>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='panel-actions page-actions'>
              {/* <button
                className='action button button-secondary'
                onClick={() =>
                  setIsMassRefundFormVisible(!isMassRefundFormVisible)
                }
              >
                Zwrot części pieniędzy
              </button> */}

              <button
                className='action button button-secondary'
                onClick={() => {
                  if (eventSlot.ticketsSoldCount > 0) {
                    navigate(
                      `/my-events/delete-event-slot/${eventSlot.eventSlotId}`
                    );
                    return;
                  }

                  if (!confirm(`Czy na pewno chcesz usunąć?`)) return;

                  dispatch({
                    type: 'DELETE_EVENT_SLOT',
                    payload: {
                      eventSlotId: eventSlot.eventSlotId,
                    },
                  });
                }}
              >
                Usuń termin
              </button>
            </div>
            {isMassRefundFormVisible && (
              <div className='form form-inline'>
                <h3>Zwrot części pieniędzy za bilety</h3>
                <div>
                  Ta opcja umożliwia zwrot części pieniędzy za bilety, np. w
                  sytuacji gdy cena biletu obejmowała wejście do synagogi, a nie
                  udało się do niej wejść. Zwrot zostanie wysłany do wszystkich
                  klientów naraz - proces trwa kilka minut.
                </div>
                {orderParticipantColumns.map(({ priceCategoryId }) => {
                  return (
                    <div key={priceCategoryId}>
                      {labels[language][priceCategoryId]} [PLN]{' '}
                      <input
                        type='number'
                        value={
                          refundData?.refundAmounts?.find(
                            (ep) => ep.priceCategoryId === priceCategoryId
                          ).price
                        }
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            return;
                          }
                          const ticketPrice = Number(
                            eventSlot.eventPrices.find(
                              (e) => e.priceCategoryId === priceCategoryId
                            ).price
                          );
                          if (e.target.value > ticketPrice) {
                            showNotification({
                              type: 'warning',
                              message: `Nie mozna zwrocic wiecej niz koszt biletu ${ticketPrice}`,
                            });
                            return;
                          }
                          setRefundData({
                            ...refundData,
                            refundAmounts: [
                              ...refundData.refundAmounts.map((ep) => {
                                if (ep.priceCategoryId === priceCategoryId) {
                                  return {
                                    ...ep,
                                    price: e.target.value,
                                  };
                                }
                                return ep;
                              }),
                            ],
                          });
                        }}
                      />
                    </div>
                  );
                })}
                <div>
                  <div>Wiadomość (opcjonalne):</div>
                  <textarea
                    className='textarea'
                    onChange={(e) =>
                      setRefundData({
                        ...refundData,
                        description: e.target.value,
                      })
                    }
                    value={refundData?.description ?? ''}
                  />
                </div>
                <div>
                  <button
                    className='button-secondary'
                    onClick={sendMassRefund}
                  >
                    Wyślij
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/*
        <div className='form-inline'>
          <h3>Invite (free tickets)</h3>
          <div>
            <input
              type='text'
              placeholder='email'
              value={inviteData.email}
              onChange={(e) =>
                setInviteData({ ...inviteData, email: e.target.value })
              }
            />
          </div>
          <div>
            Tickets adult{' '}
            <input
              type='number'
              value={inviteData.adultsNumber}
              onChange={(e) =>
                setInviteData({ ...inviteData, adultsNumber: e.target.value })
              }
            />
          </div>
          <div>
            Tickets child{' '}
            <input
              type='number'
              value={inviteData.childrenNumber}
              onChange={(e) =>
                setInviteData({ ...inviteData, childrenNumber: e.target.value })
              }
            />
          </div>
          <button
            onClick={sendInvitation}
            className='button-primary'
          >
            Invite
          </button>
        </div>
              */}
      </div>
    </div>
  );
};
