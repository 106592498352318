import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as usersSlice from '../../store/app/usersSlice';
import { Loading } from '../Loading/Loading';

import { language, cleanLink, showNotification } from '../../core/utils';
import { labels } from '../../labels';

export const EditProfile = () => {
  const dispatch = useDispatch();

  const { username } = useParams();
  const user = useSelector(usersSlice.selectItem);
  const isUsernameTaken = Boolean(
    useSelector(usersSlice.selectIsUsernameTaken)
  );
  // const isRoleSupplier = Boolean(user?.isRoleSupplier);

  const initialData = {
    userId: '',
    firstName: '',
    lastName: '',
    description: '',
    website: '',
    username: '',
    publicPhone: '',
  };

  const [data, setData] = useState(initialData);

  const checkUsername = () => {
    if (data?.username === username) return;
    dispatch({
      type: 'USERS_CHECK_USERNAME',
      payload: {
        username: data.username,
        paramUsername: username,
      },
    });
  };

  const replaceDefaultUsername = () => {
    if (!data?.username || data.username.includes('user')) {
      setData({
        ...data,
        username: cleanLink(`${data.firstName}-${data.lastName}`),
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'FETCH_USER_PROFILE',
      payload: { username },
    });
  }, [username]);

  useEffect(() => {
    if (!user) return;
    const values = {};
    Object.keys(user).forEach((key) => {
      values[key] =
        user[key] !== null && user[key] !== undefined
          ? user[key]
          : initialData[key];
    });
    setData({
      ...values,
    });
  }, [user]);

  const handleSave = () => {
    if (!data.username) {
      showNotification({
        message: labels[language].usernameIsRequired,
        type: 'error',
      });
      return;
    }
    if (isUsernameTaken) {
      showNotification({
        message: labels[language].usernameIsTaken,
        type: 'error',
      });
      return;
    }
    dispatch({
      type: 'SAVE_PROFILE',
      payload: data,
    });
  };

  const [debouncedValue, setDebouncedValue] = useState(data.username);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(data.username);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [data.username]); // Only re-run the effect if inputValue changes

  useEffect(() => {
    checkUsername();
  }, [debouncedValue]);

  if (!user) {
    return <Loading />;
  }

  return (
    <div className='content profile'>
      <Helmet>
        <title>{labels[language].editProfile} | Bimbash</title>
      </Helmet>

      <div className='profile-content'>
        <div className='profile-header'>
          <div className=''>
            <div className='h3'>{labels[language].editProfile}</div>
            <div className='form form-profile'>
              <div className='form-line'>
                <label htmlFor='name'>{labels[language].firstName}</label>
                <input
                  type='text'
                  id='name'
                  name='firstName'
                  value={data.firstName}
                  onChange={(e) => {
                    setData({
                      ...data,
                      firstName: e.target.value,
                    });
                  }}
                  onBlur={(e) =>
                    setData({
                      ...data,
                      firstName: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='form-line'>
                <label htmlFor='surname'>{labels[language].lastName}</label>
                <input
                  type='text'
                  id='surname'
                  name='lastName'
                  value={data.lastName}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lastName: e.target.value,
                    })
                  }
                  onBlur={replaceDefaultUsername}
                />
              </div>

              <div className='form-line'>
                <label htmlFor='username'>{labels[language].username}</label>
                <input
                  type='text'
                  id='username'
                  name='username'
                  value={data.username}
                  onChange={(e) =>
                    setData({
                      ...data,
                      username: cleanLink(e.target.value)
                        .replace(/ |\.|\,|\@/gi, '-')
                        .trim(),
                    })
                  }
                />
                {isUsernameTaken && (
                  <div className='box-info'>
                    {labels[language].usernameIsTaken}
                  </div>
                )}
              </div>

              <div className='form-line'>
                <label htmlFor='description'>
                  {labels[language].description}
                </label>
                <textarea
                  id='description'
                  className='description'
                  value={data.description}
                  onChange={(e) =>
                    setData({
                      ...data,
                      description: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    setData({
                      ...data,
                      description: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className='form-line'>
                <label htmlFor='website'>{labels[language].website}</label>
                <input
                  type='text'
                  id='website'
                  name='website'
                  placeholder='https://'
                  value={data.website}
                  onChange={(e) =>
                    setData({
                      ...data,
                      website: e.target.value.trim(),
                    })
                  }
                />
              </div>

              <div className='form-line'>
                <label htmlFor='phone'>{labels[language].publicPhone}</label>
                <input
                  type='text'
                  id='phone'
                  name='phone'
                  value={data.publicPhone}
                  onChange={(e) =>
                    setData({
                      ...data,
                      publicPhone: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    setData({
                      ...data,
                      publicPhone: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              {/*
        

              <div className='form-line'>
                <label htmlFor='email'>{labels[language].email}</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={data.email}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className='form-line'>
                <label htmlFor='name'>{labels[language].username}</label>
                <input
                  type='text'
                  id='username'
                  value={data.username}
                  onChange={(e) =>
                    setData({
                      ...data,
                      username: e.target.value.trim(),
                    })
                  }
                />
              </div>
              {!isNewPassword && (
                <div
                  className='form-line button-default'
                  onClick={() => setIsNewPassword(true)}
                >
                  {labels[language].changePassword}
                </div>
              )}
              {isNewPassword && (
                <div className='form-line'>
                  <label htmlFor='password'>
                    {labels[language].newPassword}
                  </label>
                  <input
                    type='text'
                    id='password'
                    name='password'
                    autoComplete='new-password'
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value.trim() })
                    }
                  />
                </div>
              )}

              {isRoleSupplier && (
                <>
                  <h3>{labels[language].companyDetails}</h3>
                  <div className='form-line'>
                    <label htmlFor='companyName'>
                      {labels[language].companyName}
                    </label>
                    <input
                      type='text'
                      id='companyName'
                      name='companyName'
                      value={data.companyName}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyName: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyStreet'>
                      {labels[language].companyStreet}
                    </label>
                    <input
                      type='text'
                      id='companyStreet'
                      name='companyStreet'
                      value={data.companyStreet}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyStreet: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyPostCode'>
                      {labels[language].companyPostCode}
                    </label>
                    <input
                      type='text'
                      id='companyPostCode'
                      name='companyPostCode'
                      value={data.companyPostCode}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyPostCode: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='companyCity'>
                      {labels[language].companyCity}
                    </label>
                    <input
                      type='text'
                      id='companyCity'
                      name='companyCity'
                      value={data.companyCity}
                      onChange={(e) =>
                        setData({
                          ...data,
                          companyCity: e.target.value.trim(),
                        })
                      }
                    />
                  </div>

                  <div className='form-line'>
                    <label htmlFor='vatNumber'>
                      {labels[language].vatNumber}
                    </label>
                    <input
                      type='text'
                      id='vatNumber'
                      name='vatNumber'
                      value={data.vatNumber}
                      onChange={(e) =>
                        setData({
                          ...data,
                          vatNumber: e.target.value.trim(),
                        })
                      }
                    />
                  </div>
                </>
              )}

                     */}

              <div className='form-line form-actions'>
                <button
                  onClick={handleSave}
                  className='button-primary'
                >
                  {labels[language].save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
