import { createSlice } from '@reduxjs/toolkit';

export const STATUS_DEFAULT = 0;
export const STATUS_FETCHING = 1;
export const STATUS_ERROR = 2;

const initialState = {
  status: STATUS_DEFAULT,
  videos: [],
  currentVideo: null,
  feedUsername: null,
  videoId: null,
  isValidate: false,
  isPlayButtonVisible: false,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { feed } = payload;
      if (!feed) return;
      for (const prop in initialState) {
        if (typeof feed[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    setVideos: (state, { payload }) => {
      state.videos = payload.videos;
      state.status = STATUS_DEFAULT;
    },
    removeVideo: (state, { payload: { videoId } }) => {
      state.videos = state.videos.filter((item) => item.videoId !== videoId);
    },
    addNextVideo: (state, { payload }) => {
      const { video } = payload;
      let { videos } = state;
      const MAX_QUEUE_LENGTH = 2;
      if (videos.length > MAX_QUEUE_LENGTH) {
        videos = videos.slice(1);
      }
      if (videos.find((item) => item.videoId === video.videoId)) {
        console.log('this video is already in the state', video);
        return;
      }
      state.videos = [...videos, video];
      state.status = STATUS_DEFAULT;
    },
    updateVideo: (state, { payload }) => {
      const { videoId, fields } = payload;
      const videos = state.videos.map((video) => {
        if (video.videoId === videoId) {
          return {
            ...video,
            ...fields,
          };
        }
        return video;
      });
      state.videos = videos;
    },
    setCurrentVideo: (state, { payload }) => {
      state.currentVideo = payload;
    },
    setStatusFetching: (state, {}) => {
      state.status = STATUS_FETCHING;
    },
    setStatusDefault: (state, {}) => {
      state.status = STATUS_DEFAULT;
    },
    setStatusError: (state, {}) => {
      state.status = STATUS_ERROR;
    },
    setFeedUsername: (state, { payload }) => {
      state.feedUsername = payload;
    },
    setVideoId: (state, { payload }) => {
      state.videoId = payload;
    },
    setIsValidate: (state, { payload }) => {
      state.isValidate = payload;
    },
    setIsPlayButtonVisible: (state, { payload }) => {
      state.isPlayButtonVisible = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  setVideos,
  setCurrentVideo,
  addNextVideo,
  updateVideo,
  setStatusFetching,
  setStatusDefault,
  setStatusError,
  setFeedUsername,
  setVideoId,
  setIsValidate,
  setIsPlayButtonVisible,
  removeVideo,
} = feedSlice.actions;

export const selectFeed = (state) => state.feed;
export const selectVideos = (state) =>
  selectFeed(state).videos.filter((item) => !item.hide);
export const selectCurrentVideo = (state) => selectFeed(state).currentVideo;
export const selectStatus = (state) => selectFeed(state).status;
export const selectIsStatusError = (state) =>
  selectFeed(state).status === STATUS_ERROR;
export const selectFeedUsername = (state) => selectFeed(state).feedUsername;
export const selectVideoId = (state) => selectFeed(state).videoId;
export const selectIsValidate = (state) => selectFeed(state).isValidate;
export const selectIsPlayButtonVisible = (state) =>
  selectFeed(state).isPlayButtonVisible;

export default feedSlice.reducer;
