import { createRoot } from 'react-dom/client';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import App from './App';
import { store } from './store/store';
import { IS_ENV_PRODUCTION, IS_ENV_DEVELOPMENT, config } from './config';
import {
  isInInternalBrowser,
  isTikTokInternalBrowser,
  // hashx,
} from './core/utils';

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

try {
  gtag('consent', 'default', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
  gtag('js', new Date());
} catch (e) {
  console.error('gtag error', e);
}
window.gtag = gtag;

const params = new URL(document?.location)?.searchParams;
const isAdmin = localStorage?.getItem('isAdmin');

function createGaHtmlTag(id) {
  var js = document.createElement('script');
  js.setAttribute('async', '');
  js.onerror = (e) => {
    console.error('Failed to load Google Tag Manager script', e);
    Sentry.captureException(e);
  };
  js.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  document.head.appendChild(js);
}

function createFbPixelTag() {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.onerror = (e) => {
      console.error('Failed to load Google Tag Manager script', e);
      Sentry.captureException(e);
    };
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
}

function createTiktokPixelTag() {
  !(function (e, t, n, s, u, a) {
    e.twq ||
      ((s = e.twq =
        function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = 'https://static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a));
  })(window, document, 'script');
  twq('config', config.tiktokTwq);
  window.twq = twq;

  !(function (w, d, t) {
    w.TiktokAnalyticsObject = t;
    var ttq = (w[t] = w[t] || []);
    (ttq.methods = [
      'page',
      'track',
      'identify',
      'instances',
      'debug',
      'on',
      'off',
      'once',
      'ready',
      'alias',
      'group',
      'enableCookie',
      'disableCookie',
    ]),
      (ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      });
    for (var i = 0; i < ttq.methods.length; i++)
      ttq.setAndDefer(ttq, ttq.methods[i]);
    (ttq.instance = function (t) {
      for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
        ttq.setAndDefer(e, ttq.methods[n]);
      return e;
    }),
      (ttq.load = function (e, n) {
        var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
        (ttq._i = ttq._i || {}),
          (ttq._i[e] = []),
          (ttq._i[e]._u = i),
          (ttq._t = ttq._t || {}),
          (ttq._t[e] = +new Date()),
          (ttq._o = ttq._o || {}),
          (ttq._o[e] = n || {});
        var o = document.createElement('script');
        (o.type = 'text/javascript'),
          (o.async = !0),
          (o.src = i + '?sdkid=' + e + '&lib=' + t);
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(o, a);
      });

    ttq.load(config.tiktokPixelId);
    ttq.page();
    window.ttq = ttq;
  })(window, document, 'ttq');
}

const mockTrackingFunctions = () => {
  window.gtag = () => {};
  window.fbq = () => {};
  window.ttq = () => {};
};

const initAnalytics = () => {
  if (IS_ENV_DEVELOPMENT) {
    mockTrackingFunctions();
    return;
  }
  if (IS_ENV_PRODUCTION && window?.location?.hostname !== 'localhost') {
    Sentry.init({
      dsn: config.sentry.dsn,
      integrations: isTikTokInternalBrowser
        ? []
        : [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
            }),
          ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.bimbash\.com/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    if (isAdmin) {
      mockTrackingFunctions();
      return;
    }

    // google analytics
    if (config.googleAnalyticsId) {
      createGaHtmlTag(config.googleAnalyticsId);

      gtag('config', config.googleAnalyticsId, {
        campaign: {
          medium:
            params.get('utm_medium') || localStorage?.getItem('utm_source'),
          source:
            params.get('utm_source') || localStorage?.getItem('utm_medium'),
          name:
            params.get('utm_campaign') || localStorage?.getItem('utm_campaign'),
          content:
            params.get('utm_content') || localStorage?.getItem('utm_content'),
        },
        send_page_view: false,
      });

      // already got set default values, but later change it to let user update it:
      // if (localStorage?.getItem?.('gtagc')) {
      //   gtag('consent', 'update', {
      //     ad_user_data: 'granted',
      //     ad_personalization: 'granted',
      //     ad_storage: 'granted',
      //     analytics_storage: 'granted',
      //   });
      // }

      // gtag('config', config.googleAdsId, {
      //   campaign: {
      //     medium: params.get('utm_medium'),
      //     source: params.get('utm_source'),
      //     name: params.get('utm_campaign'),
      //     content: params.get('utm_content'),
      //   },
      // });
    }

    if (!isTikTokInternalBrowser) {
      createFbPixelTag();
      if (config.metaPixelId && window?.fbq) {
        window?.fbq('init', config.metaPixelId);
      }
    }

    if (isTikTokInternalBrowser) {
      createTiktokPixelTag();
    }

    /*
      window?.gtag?.('event', 'purchase', googleAnalyticsEvent);
      window?.gtag?.('event', 'conversion', {
        ...googleAnalyticsEvent,
        send_to: config.googleAdsConversionId,
      });

      */

    // }
  }
};

try {
  initAnalytics();
} catch (e) {
  console.error('initAnalytics error', e);
}

const container = document.getElementById('root');
const root = createRoot(container);

const history = createBrowserHistory({ window });

root.render(
  <HistoryRouter history={history}>
    <Provider store={store}>
      <ToastContainer
        newestOnTop
        transition={Slide}
      />
      <App />
    </Provider>
  </HistoryRouter>
);

if ('serviceWorker' in navigator && !isInInternalBrowser) {
  window.addEventListener('load', () => {
    try {
      navigator.serviceWorker
        .register('/sw.js')
        .then(() =>
          //registration
          {
            // console.log('Service Worker registered: ', registration);
          }
        )
        .catch((error) => {
          console.error('Service Worker registration failed: ', error);
        });
    } catch (error) {
      console.error(
        'Service Worker registration failed with exception: ',
        error
      );
    }
  });
}
