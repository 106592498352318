import { EventCard } from '../Common/EventCard';

export const EventsList = ({ events, title, showSupplier, isMyEvents }) => {
  if (!events?.length > 0) {
    return null;
  }

  return (
    <div className='event-list-container'>
      {title && <div className='event-list-header'>{title}</div>}
      <div className='events-list'>
        {events?.map((event) => {
          return (
            <EventCard
              key={event.eventSlotId || event.eventId}
              event={event}
              showSupplier={!isMyEvents && showSupplier}
              isMyEvents={isMyEvents}
            />
          );
        })}
      </div>
    </div>
  );
};
