import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { showNotification } from '../../core/utils';

export const StripeHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedUser = useSelector(appSlice.selectUserData);
  const isStatusPending = useSelector(appSlice.selectIsStatusPending);

  useEffect(() => {
    dispatch({
      type: 'FETCH_ME',
      payload: {},
    });
  }, []);

  const isOnboarding =
    loggedUser?.stripeAccountId && !loggedUser?.stripeDetailsSubmitted;
  const isOnboardingCompleted =
    loggedUser?.stripeAccountId && loggedUser?.stripeDetailsSubmitted;

  useEffect(() => {
    if (!loggedUser) return;
    if (isOnboarding) {
      dispatch({
        type: 'CREATE_CONNECTED_ACCOUNT_LINK',
        payload: {},
      });
    }
  }, [loggedUser?.stripeDetailsSubmitted]);

  useEffect(() => {
    if (!isOnboardingCompleted) return;
    showNotification({
      message: 'Konto Stripe zostało założone',
      type: 'success',
    });
    navigate('/');
  }, [isOnboardingCompleted]);

  // useEffect(() => {
  //   if (!loggedUser) return;
  //   if (loggedUser.stripeAccountId && loggedUser?.stripeDetailsSubmitted) {
  //     showNotification({
  //       message: 'Konto Stripe zostało założone, teraz uzupełnij swój profil',
  //       type: 'success',
  //     });
  //     navigate(`/@${loggedUser.username}`);
  //   }
  // }, [loggedUser?.stripeDetailsSubmitted]);

  const createConnectedAccount = () => {
    dispatch({
      type: 'CREATE_CONNECTED_ACCOUNT',
      payload: {},
    });
  };

  if (!loggedUser || isStatusPending || isOnboarding) {
    return <Loading />;
  }

  return (
    <div className='container'>
      <div className='content page-content'>
        <h3 className='h3'>Konto Stripe</h3>

        {isOnboardingCompleted && (
          <div>
            <div className='stripe-info'>
              Konto Stripe zostało założone poprawnie
            </div>
          </div>
        )}

        {!loggedUser.stripeAccountId && (
          <div>
            <div className='stripe-info'>
              Załóż konto Stripe, aby móc sprzedawać bilety na Twoje wydarzenia.
              Konto Stripe pozwala na bezpieczne przetwarzanie płatności kartą
              kredytową, BLIKiem itd. Zakładanie konta zajmuje ok. 15 minut
            </div>
            <div className='stripe-info'>
              Przeczytaj:{' '}
              <a
                href='https://bimbash.com/jak-wypelnic-stripe.pdf'
                target='_blank'
                className='link-basic'
              >
                Poradnik jak wypełnić formularz Stripe
              </a>
            </div>
            <div className='panel-actions'>
              <button
                className='button-primary button-inline'
                onClick={createConnectedAccount}
              >
                Załóż konto
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
