import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { nanoid } from 'nanoid';

import { Loading } from '../Loading/Loading';
import * as eventsSlice from '../../store/app/eventsSlice';
import * as placesSlice from '../../store/app/placesSlice';
// import * as appSlice from '../../store/app/appSlice';

import { language, showNotification } from '../../core/utils';
import { priceCategories } from '../../consts';
import { labels } from '../../labels';
import { Card } from './Card';

export const EditEvent = ({}) => {
  const params = useParams();
  const { eventId } = params;
  const dispatch = useDispatch();
  const event = useSelector(eventsSlice.selectItem);
  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);
  const places = useSelector(placesSlice.selectItems);
  const isPlacesStatusPending = useSelector(placesSlice.selectIsStatusPending);

  // const loggedUser = useSelector(appSlice.selectUserData);
  // const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);

  const initialData = {
    name: '',
    description: '',
    included: '',
    important: '',
    durationHoursFrom: 2,
    durationHoursTo: 0,
    currency: 'PLN',
    eventPrices: [
      {
        eventPriceId: nanoid(),
        price: 30,
        ...priceCategories.find((p) => p.isDefault),
      },
    ],
    position: 0,
    startPlaceId: '',
    startPlace: null,
    minParticipants: 0,
  };

  const [data, setData] = useState(initialData);

  const availablePriceCategories = priceCategories.filter(
    (p) =>
      !data?.eventPrices?.find((e) => e.priceCategoryId === p.priceCategoryId)
  );
  const availablePriceCategoriesOptions = availablePriceCategories.map((p) => {
    return {
      value: p.priceCategoryId,
      label: labels[language][p.priceCategoryId],
    };
  });

  const createEventPrice = ({ priceCategoryId }) => {
    const priceCategoryDefaults = priceCategories.find(
      (p) => p.priceCategoryId === priceCategoryId
    );
    return {
      eventPriceId: nanoid(),
      price: 0,
      priceCategoryId,
      ...priceCategoryDefaults,
    };
  };

  useEffect(() => {
    if (!places?.length && !isPlacesStatusPending) {
      dispatch({
        type: 'FETCH_PLACES',
        payload: {},
      });
    }
  }, [places]);

  const placesOptions = places.map((p) => {
    return {
      value: p.placeId,
      label: p.name,
    };
  });

  useEffect(() => {
    if (eventId) {
      dispatch({
        type: 'FETCH_EVENT',
        payload: {
          eventId,
        },
      });
      return;
    }
    setData(initialData);
  }, [eventId]);

  useEffect(() => {
    if (event && event.eventId === eventId) {
      setData({
        ...initialData,
        ...event,
        startPlace: placesOptions.find((p) => p.value === event.startPlaceId),
      });
    }
  }, [event]);

  const save = () => {
    if (!data.eventPrices?.length) {
      showNotification({
        message: 'Dodaj przynajmniej jeden typ biletów',
        type: 'warning',
      });
      return;
    }
    dispatch({
      type: 'SAVE_EVENT',
      payload: data,
    });
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const newItems = [...data.eventPrices];
      const dragCard = newItems[dragIndex];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragCard);

      setData({
        ...data,
        eventPrices: newItems.map((p, index) => ({
          ...p,
          position: index,
        })),
      });
    },
    [event, event?.eventPrices, data, setData]
  );

  const title = eventId ? `Edytuj` : 'Dodaj';

  const nameLengthLimit = 70;

  if (eventId && event?.eventId !== eventId) {
    return <Loading />;
  }

  return (
    <div className='page-content'>
      <Helmet>
        <title>{title} wydarzenie | Bimbash</title>
      </Helmet>
      <div className='h3'>{title} wydarzenie</div>

      <div className='form form-event'>
        <div className='form-line'>
          <div className='field-label'>Nazwa</div>
          <input
            type='text'
            value={data.name}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
            onBlur={(e) => {
              setData({
                ...data,
                name: e.target.value.trim(),
              });
            }}
            maxLength={nameLengthLimit}
            className='input-text'
          />
          <div className='input-legend'>
            {data.name?.length ?? 0} / {nameLengthLimit} znaków
          </div>

          {!eventId && (
            <div className='tips'>
              <div>
                Przykłady dobrej nazwy:
                <ul>
                  <li>Sekrety Kazimierza z degustacją koszernych potraw</li>
                  <li>3 najwyższe punkty widokowe w Krakowie w 1 dzień</li>
                  <li>
                    Romantyczny rejs statkiem po Wiśle z kolacją, muzyką i winem
                  </li>
                  <li>Mrożący krew w żyłach nocny spacer z duchami</li>
                  <li>Ekskluzywna wycieczka VIP do Auschwitz-Birkenau</li>
                </ul>
              </div>
              <div>
                Przykłady złej nazwy:
                <ul>
                  <li>zwiedzanie Krakowa</li>
                  <li>Nowa Huta</li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='form-line'>
          <div className='field-label'>Opis</div>
          <textarea
            onChange={(e) => setData({ ...data, description: e.target.value })}
            value={data.description}
            className='textarea'
          />
          <div className='input-legend'>
            {data.description?.length ?? 0} znaków
          </div>

          {!eventId && (
            <div className='tips'>
              <div>
                Opis powinien mieć co najmniej 500 znaków. Powinien zawierać
                informacje o atrakcjach, które czekają na uczestników. Pisz
                konkretnie, zachęcająco i z pasją. Używaj czasowników i formy
                "Ty", np. "Zobacz najstarszą część Wawelu i dowiedz się,
                dlaczego jest tak ważna dla Polaków"
              </div>
            </div>
          )}
        </div>
        <div className='form-line'>
          <div className='field-label'>Czas trwania [ilość godzin]</div>
          <input
            type='number'
            value={data.durationHoursFrom}
            onChange={(e) => {
              setData({
                ...data,
                durationHoursFrom: e.target.value,
              });
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                setData({
                  ...data,
                  durationHoursFrom: initialData.durationHoursFrom,
                });
              }
            }}
            className='input-text'
          />
        </div>
        <div>
          <div className='field-label'>Miejsce startu</div>

          <Select
            placeholder='Wybierz miejsce'
            options={placesOptions}
            onChange={(e) => {
              setData({
                ...data,
                startPlaceId: e.value,
                startPlace: e,
              });
            }}
            value={data.startPlace}
          />
        </div>

        <div>
          <div className='h3 form-section'>Typy biletów</div>
          <div className='form-tickets'>
            {data.eventPrices.map((eventPrice, index) => {
              return (
                <Card
                  key={eventPrice.eventPriceId + index}
                  index={index}
                  id={eventPrice.eventPriceId}
                  moveCard={moveCard}
                  isSmallDragIcon={true}
                >
                  <div
                    // key={eventPrice.eventPriceId}
                    className='form-line form-line-ticket'
                  >
                    <div className='ticket-name'>
                      {labels[language][eventPrice.priceCategoryId]}
                    </div>

                    <div className='form-input-flex'>
                      <div className='label'>Cena [PLN]</div>
                      <input
                        type='number'
                        value={eventPrice.price}
                        onChange={(e) => {
                          // validate if price is number, negative number is forbidden
                          if (isNaN(e.target.value) || e.target.value < 0) {
                            return;
                          }
                          setData({
                            ...data,
                            eventPrices: data.eventPrices.map((p) => {
                              if (p.eventPriceId === eventPrice.eventPriceId) {
                                return {
                                  ...p,
                                  price: e.target.value,
                                };
                              }
                              return p;
                            }),
                          });
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            setData({
                              ...data,
                              eventPrices: data.eventPrices.map((p) => {
                                if (
                                  p.eventPriceId === eventPrice.eventPriceId
                                ) {
                                  return {
                                    ...p,
                                    price: 0,
                                  };
                                }
                                return p;
                              }),
                            });
                          }
                        }}
                        className='input-text'
                      />
                    </div>
                    <div className='form-input-flex'>
                      <div className='form-input-flex'>
                        <div className='label'>Wiek od</div>
                        <input
                          type='number'
                          value={eventPrice.ageFrom || ''}
                          onChange={(e) => {
                            if (isNaN(e.target.value) || e.target.value < 0) {
                              return;
                            }
                            setData({
                              ...data,
                              eventPrices: data.eventPrices.map((p) => {
                                if (
                                  p.eventPriceId === eventPrice.eventPriceId
                                ) {
                                  return {
                                    ...p,
                                    ageFrom: e.target.value,
                                  };
                                }
                                return p;
                              }),
                            });
                          }}
                          className='input-text input-age'
                        />
                      </div>
                      {/* ageTo number input */}
                      <div className='form-input-flex'>
                        <div className='label'>do</div>
                        <input
                          type='number'
                          value={eventPrice.ageTo || ''}
                          onChange={(e) => {
                            if (isNaN(e.target.value) || e.target.value < 0) {
                              return;
                            }
                            setData({
                              ...data,
                              eventPrices: data.eventPrices.map((p) => {
                                if (
                                  p.eventPriceId === eventPrice.eventPriceId
                                ) {
                                  return {
                                    ...p,
                                    ageTo: e.target.value,
                                  };
                                }
                                return p;
                              }),
                            });
                          }}
                          onBlur={(e) => {
                            const value = Number(e.target.value);
                            if (value > 80) {
                              showNotification({
                                message:
                                  'Nie ma potrzeby podawania wieku większego niż 80, to pole opcjonalne, zostawmy je puste',
                                type: 'info',
                              });
                              setData({
                                ...data,
                                eventPrices: data.eventPrices.map((p) => {
                                  if (
                                    p.eventPriceId === eventPrice.eventPriceId
                                  ) {
                                    return {
                                      ...p,
                                      ageTo: '',
                                    };
                                  }
                                  return p;
                                }),
                              });
                            }
                          }}
                          className='input-text input-age'
                        />
                        <div className='label'>lat</div>
                      </div>
                    </div>

                    <div className='subitems-actions'>
                      <button
                        onClick={() => {
                          setData({
                            ...data,
                            eventPrices: data.eventPrices.filter(
                              (p) => p.eventPriceId !== eventPrice.eventPriceId
                            ),
                          });
                        }}
                        className='button-default button-inline button-delete'
                      >
                        Usuń
                      </button>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>

          {availablePriceCategoriesOptions?.length > 0 && (
            <>
              <div className='field-label'>Dodaj nowy typ biletu</div>
              <Select
                placeholder='Wybierz typ biletu, aby go dodać'
                options={availablePriceCategoriesOptions}
                onChange={(e) => {
                  setData({
                    ...data,
                    eventPrices: [
                      ...data.eventPrices,
                      createEventPrice({
                        priceCategoryId: e.value,
                      }),
                    ],
                  });
                  e.target?.blur?.();
                }}
                value={null}
              />
            </>
          )}
        </div>

        <div className='form-line'>
          <div className='field-label'>
            Co jest wliczone w cenę biletu (pole opcjonalne)
          </div>
          <textarea
            onChange={(e) => setData({ ...data, included: e.target.value })}
            value={data.included}
            className='textarea-medium'
          />

          {/* {!eventId && ( */}
          <div className='tips'>
            Napisz co jest wliczone w cenę biletu. Przykładowo: bilet wstępu do
            muzeum
          </div>
        </div>

        <div className='form-line'>
          <div className='field-label'>
            Ważne informacje dodatkowe (pole opcjonalne)
          </div>
          <textarea
            onChange={(e) => setData({ ...data, important: e.target.value })}
            value={data.important}
            className='textarea-medium'
          />

          <div className='tips'>
            Napisz ważne informacje, które uczestnicy powinni wiedzieć przed
            wydarzeniem. Przykładowo: zalecane ubranie, wymagane dokumenty
          </div>
        </div>

        <div className='form-actions'>
          <button
            onClick={save}
            type='button'
            className='submit button-primary'
          >
            Zapisz
          </button>
        </div>

        {isStatusPending && <Loading />}
      </div>
    </div>
  );
};

export default EditEvent;
