import { lazy, Suspense, useLayoutEffect, useEffect } from 'react';

import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
  NavLink,
  Link,
} from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from 'use-local-storage';
import { Tooltip } from 'react-tooltip';
import { Helmet } from 'react-helmet';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ReactComponent as SolidBars } from './svgs/solid/bars.svg';
import { ReactComponent as BrandInstagram } from './svgs/brands/instagram.svg';
import { ReactComponent as BrandFacebook } from './svgs/brands/facebook.svg';
import { ReactComponent as SolidExclamation } from './svgs/solid/exclamation.svg';

import * as appSlice from './store/app/appSlice';
import { Loading } from './components/Loading/Loading';

import {
  scrollToTop,
  language,
  isMobile,
  isInInternalBrowser,
  isTikTokInternalBrowser,
  isTwitterInternalBrowser,
  isInstagramInternalBrowser,
  isFacebookInternalBrowser,
} from './core/utils';
import { config } from './config';
import { Home } from './components/Home/Home';
import { Contact } from './components/Contact/Contact';
import { SignUp } from './components/SignUp/SignUp';
import { SignUpSupplier } from './components/SignUp/SignUpSupplier';
import { LogIn } from './components/LogIn/LogIn';
import { About } from './components/About/About';
import { MyTickets } from './components/MyTickets/MyTickets';
import { Forgot } from './components/Forgot/Forgot';
import { ResetPassword } from './components/Forgot/ResetPassword';
import { Media } from './components/Media/Media';
import { UserProfile } from './components/User/UserProfile';
import { EditProfile } from './components/User/EditProfile';
import { Settings } from './components/User/Settings';
import { EventDetails } from './components/Event/EventDetails';
import { PrivateTour } from './components/PrivateTour/PrivateTour';

import { MyEvents } from './components/MyEvents/MyEvents';
import { EditEvent } from './components/MyEvents/EditEvent';
import { Images } from './components/MyEvents/Images';
import { Videos } from './components/MyEvents/Videos';
import { EditEventSlot } from './components/MyEvents/EditEventSlot';
import { EditEventMedia } from './components/MyEvents/EditEventMedia';
import { EditEventSlots } from './components/MyEvents/EditEventSlots';
import { DeleteEventSlot } from './components/MyEvents/DeleteEventSlot';
import { EventSlotTickets } from './components/MyEvents/EventSlotTickets';
import { MyEventSlots } from './components/MyEventSlots/MyEventSlots';
import { Guides } from './components/Guides/Guides';

const AI = lazy(() => import('./components/AI/AI'));
const Stats = lazy(() => import('./components/Stats/Stats'));

import { EditAI } from './components/AI/EditAI';

// import { FeedMemo } from './components/Feed/Feed';
import { StripeHome } from './components/Stripe/Home';

// admin
import { AdminEvents } from './components/admin/AdminEvents/AdminEvents';
import { AdminEventSlots } from './components/admin/AdminEventSlots/AdminEventSlots';
import { AdminUsers } from './components/admin/AdminUsers/AdminUsers';
import { AdminPlaces } from './components/admin/AdminPlaces/AdminPlaces';
import { AdminEditPlace } from './components/admin/AdminPlaces/AdminEditPlace';

import { labels } from './labels';
import { getIsDarkTheme, sendManualPageview } from './core/utils';

import './e.css';
import './f.css';
import './App.css';
import './chat.css';

const faqUrls = {
  pl: '/faq.html',
  en: '/faq_en.html',
};
const FAQurl = faqUrls[language] || '/faq.html';
const bestPracticesUrl = '/dobre-praktyki.html';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    scrollToTop();
  }, [location.pathname]);
  let pageviewTimeout;
  useEffect(() => {
    pageviewTimeout = setTimeout(() => {
      // wait for the page to load and helmet to handle page title etc
      sendManualPageview();
    }, 1000);
    return () => {
      if (!pageviewTimeout) return;
      clearTimeout(pageviewTimeout);
    };
  }, [location.pathname]);
  return children;
};

const StripeLink = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(appSlice.selectUserData);

  if (loggedUser?.isRoleAdmin) {
    return;
  }

  if (loggedUser?.stripeDetailsSubmitted) {
    return (
      <NavLink
        className='a-with-box'
        to='#'
        onClick={() => {
          dispatch({
            type: 'STRIPE_LOG_IN',
            payload: {},
          });
        }}
      >
        {Boolean(loggedUser?.stripeActionRequired) && (
          <span className='icon-box'>
            <SolidExclamation />
          </span>
        )}
        <span>Stripe</span>
      </NavLink>
    );
  }
  return (
    <NavLink
      className='a-with-box'
      to='/stripe'
    >
      {(!loggedUser?.stripeAccountId ||
        Boolean(loggedUser?.stripeActionRequired)) && (
        <span className='icon-box'>
          <SolidExclamation />
        </span>
      )}
      <span>Stripe</span>
    </NavLink>
  );
};

const SideMenu = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(appSlice.selectIsMenuOpen);
  const loggedUser = useSelector(appSlice.selectUserData);
  const isFullyLogged = Boolean(loggedUser?.email);
  const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);
  const isRoleSupplier = Boolean(loggedUser?.isRoleSupplier);

  const onClickLogout = () => {
    dispatch({
      type: 'LOG_OUT',
      payload: {},
    });
  };

  const handleCloseMenu = () => {
    dispatch(appSlice.setIsMenuOpen(false));
  };

  return (
    <>
      <div
        className={classNames('menu', {
          'slide-in-right': isMenuOpen,
        })}
        onClick={handleCloseMenu}
      >
        {(!isFullyLogged || !isRoleSupplier) && isMobile && (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'link link-active' : 'link '
              }
              to='/private-tour/?tourType=0'
            >
              {labels[language].privateTour}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'link link-active' : 'link '
              }
              to='/private-tour/?tourType=1'
            >
              {labels[language].companyTour}
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? 'link link-active' : 'link '
              }
              to='/private-tour/?tourType=2'
            >
              {labels[language].schoolTour}
            </NavLink>
          </>
        )}
        {isFullyLogged && (
          <>
            {isRoleSupplier && isMobile && <StripeLink />}

            {isRoleAdmin && (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/admin/users'
                >
                  Users
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/admin/events'
                >
                  Events
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/admin/event-slots'
                >
                  <div className='button-action-lavel'>Event slots</div>
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/ai/edit/@monika'
                >
                  <div className='button-action-lavel'>AI</div>
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/admin/places'
                >
                  Places
                </NavLink>
              </>
            )}

            {isMobile && (
              <>
                {isRoleSupplier && (
                  <>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? 'link link-active' : 'link '
                      }
                      to='/my-events'
                    >
                      {labels[language].myEvents}
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? 'link link-active' : 'link '
                      }
                      to='/my-event-slots'
                    >
                      <div className='button-action-lavel'>
                        {labels[language].myEventSlots}
                      </div>
                    </NavLink>
                  </>
                )}

                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/guides'
                >
                  {labels[language].guides}
                </NavLink>

                <NavLink
                  className='link on-mobile'
                  to='/my-tickets'
                >
                  {labels[language].myTickets}
                </NavLink>

                <NavLink
                  className='link'
                  to={`/@${loggedUser.username}`}
                >
                  {labels[language].myProfile}
                </NavLink>
              </>
            )}

            <NavLink
              className='link'
              to={`/settings`}
            >
              {labels[language].settings}
            </NavLink>

            {isRoleSupplier && (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/stats'
                >
                  {labels[language].stats}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active' : 'link '
                  }
                  to='/guides'
                >
                  {labels[language].guides}
                </NavLink>
              </>
            )}

            {!isMobile && isRoleSupplier && (
              <NavLink
                className='link'
                to='/my-tickets'
              >
                {labels[language].myTickets}
              </NavLink>
            )}

            <NavLink
              onClick={onClickLogout}
              className='link'
            >
              {labels[language].logOut}
            </NavLink>
          </>
        )}

        {!isFullyLogged && isMobile && (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'link link-active' : 'link '
              }
              to='/guides'
            >
              {labels[language].guides}
            </NavLink>

            <NavLink
              className='link'
              to='/log-in'
            >
              {labels[language].logIn}
            </NavLink>

            <NavLink
              to='/sign-up'
              className='link'
            >
              {labels[language].signUp}
            </NavLink>

            <NavLink
              to='/sign-up/supplier'
              className='link'
            >
              {labels[language].signUpSupplier}
            </NavLink>
          </>
        )}

        <NavLink
          to='/contact'
          className='link'
        >
          {labels[language].contactUs}
        </NavLink>

        {/* {!isRoleSupplier && (
          <NavLink
            className='link'
            to='#'
            onClick={(e) => {
              e.preventDefault();
              dispatch({
                type: 'EVENT_AI',
                payload: {
                  eventId: '',
                  product: '',
                },
              });
            }}
          >
            Audio AI wirtualny przewodnik
          </NavLink>
        )} */}
        <a
          href={FAQurl}
          target='_blank'
          className='link'
        >
          {labels[language].faq}
        </a>

        <a
          href={bestPracticesUrl}
          target='_blank'
          className='link'
        >
          {labels[language].bestPractices}
        </a>

        <NavLink
          to='/media'
          className='link'
        >
          {labels[language].media}
        </NavLink>
      </div>
      <div
        className={classNames('menu-bg', {
          'menu-bg-active': isMenuOpen,
        })}
        onClick={handleCloseMenu}
      />
    </>
  );
};

const App = () => {
  const navigate = useNavigate();
  window.navigateTo = navigate;
  const [searchParams] = useSearchParams();
  const emailVerificationHash = searchParams.get('emailVerificationHash');

  const redirectUrl = useSelector(appSlice.selectRedirectUrl);

  const [impersonateUserId, setImpersonateUserId] = useLocalStorage(
    config.impersonateUserIdKey,
    0
  );

  const stopImpersonating = () => {
    setImpersonateUserId('');
    const returnPage = localStorage.getItem('returnPage') || '/admin/users';
    localStorage.removeItem('returnPage');
    window.location = returnPage;
  };

  const showMenu = () => {
    dispatch(appSlice.setIsMenuOpen(true));
  };

  const dispatch = useDispatch();
  const authToken = useSelector(appSlice.selectAuthToken);
  const loggedUser = useSelector(appSlice.selectUserData);
  const isFormBookInViewport = useSelector(appSlice.selectIsFormBookInViewport);
  const isFullyLogged = Boolean(loggedUser?.email);
  const isRoleSupplier = Boolean(loggedUser?.isRoleSupplier);
  const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);
  const isEmailVerified = Boolean(loggedUser?.emailVerified);
  let darkTheme = getIsDarkTheme();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const localAuthToken = localStorage?.getItem('authToken');
        if (Boolean(loggedUser) === Boolean(localAuthToken)) return;
        dispatch({
          type: 'FETCH_ME',
          payload: {},
        });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const [localDarkTheme] = useLocalStorage('darkTheme', darkTheme);
  darkTheme = localDarkTheme;

  if (window.location.href?.includes('setdebug=1')) {
    localStorage.setItem('debug', '1');
  }
  if (window.location.href?.includes('setdebug=0')) {
    localStorage.removeItem('debug');
  }

  useEffect(() => {
    if (!window?.location?.href) return;
    if (window.gtag) {
      gtag('event', 'page_view', {});
    }
    if (window.fbq) {
      fbq('track', 'PageView');
    }
  }, [window.location?.href]);

  useEffect(() => {
    if (
      emailVerificationHash &&
      (!authToken || (isFullyLogged && !isEmailVerified))
    ) {
      dispatch({
        type: 'CONFIRM_EMAIL',
        payload: {
          emailVerificationHash,
        },
      });
    }
  }, [dispatch, emailVerificationHash, isEmailVerified, isFullyLogged]);

  const isMobileIndex = isMobile && window.location.pathname === '/';
  // const isMobileOnUserProfile =
  //   isMobile && window.location.pathname.includes('/@');

  const paramBuyTickets = searchParams.get('buyTickets');
  const isEventDetailsPage =
    window.location.pathname.includes('/event/') && !paramBuyTickets;

  if (redirectUrl) {
    return (
      <div className='content-filler'>
        <div className='info-box-small'>
          <h3>{labels[language].redirectingTitle}</h3>
          <div>
            {labels[language].redirectingDescription}
            <a href={redirectUrl}>{redirectUrl.slice(0, 120)}</a>
          </div>
        </div>
      </div>
    );
  }

  const lightTextPages = ['/'];
  const isLightTextPage = lightTextPages.includes(window.location.pathname);

  const lightMobileTextPages = ['/discover'];
  const isLightMobileTextPage = lightMobileTextPages.includes(
    window.location.pathname
  );

  const getStyle = () => {
    if (!isLightTextPage) {
      return {};
    }
    if (isMobile) {
      return {
        // backgroundImage: `url(./img/home_407.jpg)`,
        // backgroundPositionX: 'center',
        // backgroundRepeat: 'no-repeat',
      };
    }

    return {
      // backgroundImage: `url(./img/home.jpg)`,
      // backgroundImage: `url(./x2.jpg)`,
      // backgroundPositionX: 'center',
      // backgroundRepeat: 'no-repeat',
    };
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Helmet>
          <html lang={language} />
        </Helmet>
        <SideMenu />
        <div
          className={classNames({
            app: !isMobileIndex,
            'app-desktop': !isMobile,
            // dark: isDarkTheme,
          })}
          style={getStyle()}
        >
          {!isMobile && (
            <div
              className={classNames('nav-header', {
                //  'nav-header-light-text': isLightTextPage,
              })}
            >
              <div className='main-container'>
                <Link
                  to='/'
                  className='logo-link'
                >
                  BimBash
                </Link>
                <div className='top-menu'>
                  {(!isFullyLogged || !isRoleSupplier) && (
                    <>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active' : 'link '
                        }
                        to='/guides'
                      >
                        {labels[language].guides}
                      </NavLink>

                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active' : 'link '
                        }
                        to='/private-tour/?tourType=0'
                      >
                        {labels[language].privateTour}
                      </NavLink>

                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active' : 'link '
                        }
                        to='/private-tour/?tourType=1'
                      >
                        {labels[language].companyTour}
                      </NavLink>

                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active' : 'link '
                        }
                        to='/private-tour/?tourType=2'
                      >
                        {labels[language].schoolTour}
                      </NavLink>
                    </>
                  )}

                  {!isFullyLogged && (
                    <>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active ' : 'link text-link '
                        }
                        to='/sign-up'
                      >
                        {labels[language].signUp}
                      </NavLink>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active ' : 'link text-link '
                        }
                        to='/sign-up/supplier'
                      >
                        {labels[language].signUpSupplier}
                      </NavLink>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active ' : 'link text-link '
                        }
                        to='/log-in'
                      >
                        {labels[language].logIn}
                      </NavLink>
                    </>
                  )}

                  {/* <div className='menu-item'>
              <FontAwesomeIcon icon={solid('search')} />
            </div> */}

                  {isFullyLogged && (
                    <>
                      {isRoleSupplier && (
                        <>
                          {!isMobile && <StripeLink />}
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'link link-active' : 'link '
                            }
                            to='/my-events'
                          >
                            {labels[language].myEvents}
                          </NavLink>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'link link-active' : 'link '
                            }
                            to='/my-event-slots'
                          >
                            <div className='button-action-lavel'>
                              {labels[language].myEventSlots}
                            </div>
                          </NavLink>
                        </>
                      )}
                      {!isRoleSupplier && (
                        <>
                          <Link
                            to='/my-tickets'
                            className='button-action'
                          >
                            {labels[language].myTickets}
                          </Link>
                        </>
                      )}
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'link link-active' : 'link '
                        }
                        to={`/@${loggedUser.username}`}
                      >
                        {labels[language].myProfile}
                      </NavLink>
                    </>
                  )}

                  {Boolean(impersonateUserId) && (
                    <span
                      className='link side-menu-text-icon'
                      onClick={stopImpersonating}
                    >
                      X
                    </span>
                  )}

                  <div
                    className='menu-item side-menu-icon-desktop'
                    onClick={showMenu}
                  >
                    <SolidBars />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <>
              <div
                className={classNames('nav-header', {
                  'nav-header-light-text': isLightMobileTextPage,
                })}
              >
                <div className='main-container'>
                  <Link
                    to='/'
                    className='logo-link'
                  >
                    BimBash
                  </Link>

                  {Boolean(impersonateUserId) && (
                    <a
                      className={classNames('side-menu-close-icon')}
                      onClick={stopImpersonating}
                    >
                      X
                    </a>
                  )}

                  <div
                    className={classNames('side-menu-icon', {
                      'side-menu-icon-light': isLightMobileTextPage,
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(appSlice.setIsMenuOpen(true));
                    }}
                  >
                    <SolidBars />
                  </div>
                </div>
              </div>

              <div className='main-bottom-actions'>
                {isEventDetailsPage && !isFormBookInViewport && (
                  <div
                    className='buy-tickets-button'
                    onClick={() => {
                      // scrollinto view form book
                      const element = document.querySelector('.form-book');
                      element?.scrollIntoView?.({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest',
                      });
                    }}
                  >
                    {labels[language].buyTickets}
                  </div>
                )}

                {isFullyLogged && (
                  <div className='buttons'>
                    <Link
                      to='/'
                      className='button-action'
                    >
                      {/* <i className='fa-solid fa-home button-action-icon' /> */}
                      <div className='button-action-lavel'>
                        {labels[language].home}
                      </div>
                    </Link>

                    <Link
                      to='/my-tickets'
                      className='button-action'
                    >
                      {/* <i className='fa-solid fa-tickets button-action-icon' /> */}
                      <div className='button-action-lavel'>
                        {labels[language].myTickets}
                      </div>
                    </Link>

                    {/* <Link
                      to='/discover'
                      className='button-action'
                    >
                      <i className='fa-solid fa-compass button-action-icon' />
                      <div className='button-action-lavel'>
                        {labels[language].explore}
                      </div>
                    </Link> */}

                    <Link
                      to='/contact'
                      className='button-action'
                    >
                      {/* <i className='fa-solid fa-phone button-action-icon' /> */}
                      <div className='button-action-lavel'>
                        {labels[language].contactUs}
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </>
          )}

          <Wrapper>
            <Routes>
              <>
                <Route
                  path='/'
                  exact
                  element={<Home />}
                />
                <Route
                  path='/stripe'
                  exact
                  element={<StripeHome />}
                />
                {/* <Route
                  path='/discover'
                  exact
                  element={<FeedMemo />}
                /> */}
                <Route
                  path='/my-events/event-slot-tickets/:eventSlotId'
                  exact
                  element={<EventSlotTickets />}
                />
                <Route
                  path='/guides'
                  exact
                  element={<Guides />}
                />
                <Route
                  path='/my-events'
                  exact
                  element={<MyEvents />}
                />
                <Route
                  path='/my-event-slots'
                  exact
                  element={<MyEventSlots />}
                />
                <Route
                  path='/my-events/add'
                  element={<EditEvent />}
                />
                <Route
                  path='/my-events/images/:eventId'
                  element={<Images />}
                />
                <Route
                  path='/my-events/videos/:eventId'
                  element={<Videos />}
                />
                <Route
                  path='/my-events/edit/:eventId'
                  element={<EditEvent />}
                />

                <Route
                  path='/my-events/edit-event-slot/:eventId/:eventSlotId'
                  element={<EditEventSlot />}
                />
                <Route
                  path='/my-events/edit-event-slot/:eventId'
                  element={<EditEventSlot />}
                />

                <Route
                  path='/my-events/delete-event-slot/:eventSlotId'
                  element={<DeleteEventSlot />}
                />

                <Route
                  path='/my-events/edit-event-slots/:eventId'
                  element={<EditEventSlots />}
                />

                <Route
                  path='/my-events/edit-event-media/:eventId'
                  element={<EditEventMedia />}
                />
                <Route
                  path='/my-tickets'
                  element={<MyTickets />}
                />

                <Route
                  path='/ai/edit/:username'
                  element={<EditAI />}
                />

                <Route
                  path='/ai/:username'
                  element={
                    <Suspense fallback={<Loading />}>
                      <AI />
                    </Suspense>
                  }
                />

                <Route
                  path='/stats'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Stats />
                    </Suspense>
                  }
                />

                <Route
                  path='/media'
                  element={<Media />}
                />

                <Route
                  path='/contact-us'
                  element={<Contact />}
                />

                <Route
                  path='/sign-up/supplier'
                  element={<SignUpSupplier />}
                />
                <Route
                  path='/sign-up'
                  element={<SignUp />}
                />
                <Route
                  path='/log-in'
                  element={<LogIn />}
                />
                <Route
                  path='/about'
                  element={<About />}
                />
                <Route
                  path='/contact'
                  element={<Contact />}
                />
                <Route
                  path='/event/:eventId'
                  element={<EventDetails />}
                />
                <Route
                  path='/event/:eventId/:eventName'
                  element={<EventDetails />}
                />

                <Route
                  path='/private-tour'
                  element={<PrivateTour />}
                />

                <Route
                  path='/forgot'
                  element={<Forgot />}
                />
                <Route
                  path='/password-reset'
                  element={<ResetPassword />}
                />

                <Route
                  path='/edit-profile/:username'
                  element={<EditProfile />}
                />
                <Route
                  path='/settings'
                  element={<Settings />}
                />
                <Route
                  path='/:username'
                  element={<UserProfile />}
                />

                {/* ADMIN */}

                {isRoleAdmin && (
                  <>
                    <Route
                      path='/admin/events'
                      element={<AdminEvents />}
                    />
                    {/* <Route
                path='/admin/event-slots/:eventSlotId'
                element={<AdminEventSlotDetails />}
              /> */}
                    <Route
                      path='/admin/event-slots'
                      element={<AdminEventSlots />}
                    />
                    <Route
                      path='/admin/users'
                      element={<AdminUsers />}
                    />
                    <Route
                      path='/admin/places'
                      element={<AdminPlaces />}
                    />
                    <Route
                      path='/admin/edit-place/:placeId'
                      element={<AdminEditPlace />}
                    />
                    <Route
                      path='/admin/edit-place'
                      element={<AdminEditPlace />}
                    />
                  </>
                )}
              </>
            </Routes>
          </Wrapper>

          <div className='footer '>
            <div className='footer-content'>
              <div className='footer-sections'>
                {/* <div className='footer-section'>
              <div className='coop links'>
                <div>Work with us</div>
                <div className='link'>Tour Guide</div>
                <div className='link'>Tour Provider</div>
                <div className='link'>Affiliate</div>
              </div>
            </div> */}

                <div className='footer-section footer-section-links'>
                  <div className='footer-section footer-contact-details'>
                    <div>{config.email}</div>
                  </div>
                  <div className='links'>
                    {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active ' : 'link text-link '
                  }
                  to='/contact-us'
                >
                  Contact us
                </NavLink> */}
                    {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? 'link link-active ' : 'link text-link '
                  }
                  to='/about'
                >
                  {labels[language].about}
                </NavLink> */}

                    {/* <a
                  href='/terms.html'
                  target='_blank'
                  rel='noreferrer'
                  className='link'
                >
                  {labels[language].terms}
                </a> */}
                  </div>

                  {/* <div className='select-boxes'>
                <div className='language select-box'>
                  <span>{labels[language].language}</span>
                  <select
                    onChange={(e) => {
                      const language = e.target.value;
                      localStorage.setItem('language', language);
                      window.location.reload();
                    }}
                    value={language}
                  >
                    <option value='en'>English</option>
                    <option value='pl'>Polski</option>
                  </select>
                </div>
                <div className='currency select-box'>
                  <span>{labels[language].currency}</span>
                  <select
                    onChange={(e) => {
                      const currency = e.target.value;
                      localStorage.setItem('currency', currency);
                      window.location.reload();
                    }}
                    value={currency}
                  >
                    <option value='pln'>PLN (zł)</option>
                    <option value='eur'>EUR (€)</option>
                    <option value='usd'>USD ($)</option>
                  </select>
                </div>
              </div> */}
                </div>
              </div>

              <div className='footer-section footer-section-full'>
                <a
                  href='/'
                  className='link-basic'
                >
                  Index
                </a>
                {language === 'en' && (
                  <>
                    <a
                      href='/terms.html'
                      target='_blank'
                      className='link-basic'
                    >
                      {labels[language].terms}
                    </a>
                  </>
                )}

                {!isFullyLogged && (
                  <>
                    <NavLink
                      to='/sign-up'
                      className='link'
                    >
                      {labels[language].signUp}
                    </NavLink>

                    <NavLink
                      to='/sign-up/supplier'
                      className='link'
                    >
                      {labels[language].signUpSupplier}
                    </NavLink>

                    <NavLink
                      to='/log-in'
                      className='link'
                    >
                      {labels[language].logIn}
                    </NavLink>
                  </>
                )}

                <a
                  href={FAQurl}
                  target='_blank'
                  className='link'
                >
                  {labels[language].faq}
                </a>

                <NavLink
                  to='/ai/@monika'
                  className='link'
                >
                  {labels[language].ourGPT}
                </NavLink>

                <a
                  href='https://bimbash.nolt.io'
                  target='_blank'
                  className='link-basic'
                  onClick={() => {
                    window?.gtag?.('event', 'click-feedback', {
                      event_category: 'feedback',
                      event_label: 'feedback',
                    });
                  }}
                >
                  {labels[language].giveFeedback}
                </a>

                <div className='social'>
                  <div className='social-icon'>
                    <a
                      href='https://www.instagram.com/bimbash__/'
                      target='_blank'
                      rel='noreferrer'
                      className='brand-instagram'
                    >
                      <BrandInstagram />
                    </a>
                  </div>
                  <div className='social-icon'>
                    <a
                      href='https://www.facebook.com/people/Bimbash/61556819763999/'
                      target='_blank'
                      rel='noreferrer'
                      className='brand-instagram'
                    >
                      <BrandFacebook />
                    </a>
                  </div>

                  {/* <div className='social-icon'>
                  <a
                    href='https://www.youtube.com/@przewodnikpokrakowie_'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='fa-brands fa-youtube' />
                  </a>
                </div> */}
                  {/* <div className='social-icon'>
                    <a
                      href='https://www.tiktok.com/@bimbashOfficial'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fa-brands fa-tiktok' />
                    </a>
                  </div> */}
                  {/* <div className='social-icon'>
                <FontAwesomeIcon icon={brands('x-twitter')} />
              </div> */}
                </div>
              </div>

              <div className='footer-section footer-section-full'>
                {language === 'pl' && (
                  <>
                    <a
                      href='/regulamin.html'
                      target='_blank'
                      className='link'
                    >
                      {labels[language].terms}
                    </a>
                  </>
                )}

                {language === 'en' && (
                  <>
                    <a
                      href='/privacy.html'
                      target='_blank'
                      className='link-basic'
                    >
                      {labels[language].privacy}
                    </a>
                  </>
                )}

                {language === 'pl' && (
                  <>
                    <a
                      href='/polityka_prywatnosci.html'
                      target='_blank'
                      className='link'
                    >
                      {labels[language].privacy}
                    </a>
                  </>
                )}
              </div>

              <div className='footer-section footer-section-full'>
                <div className='copyrights'>
                  <div>© {new Date().getFullYear()} BimBash.com</div>
                </div>

                {isInInternalBrowser && (
                  <div className='copyrights'>
                    {isTikTokInternalBrowser && (
                      <div className='tiktok-internal-browser'>
                        TikTok browser
                      </div>
                    )}
                    {isTwitterInternalBrowser && (
                      <div className='twitter-internal-browser'>
                        Twitter browser
                      </div>
                    )}
                    {isInstagramInternalBrowser && (
                      <div className='instagram-internal-browser'>
                        Instagram browser
                      </div>
                    )}
                    {isFacebookInternalBrowser && (
                      <div className='facebook-internal-browser'>
                        Facebook browser
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <Tooltip
            id='tooltip'
            delayShow={400}
            style={{
              maxWidth: '300px',
            }}
          />
        )}
      </DndProvider>
    </>
  );
};

export default App;
