import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';

import { Loading } from '../Loading/Loading';
import { SignUp } from '../SignUp/SignUp';
import * as eventsSlice from '../../store/app/eventsSlice';
import * as appSlice from '../../store/app/appSlice';

import { language, showNotification, locale } from '../../core/utils';
import { labels } from '../../labels';

registerLocale('pl', pl);

export const PrivateTour = ({}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const tourType = searchParams.get('tourType');
  const eventId = searchParams.get('eventId');
  const event = useSelector(eventsSlice.selectItem);
  const isEventStatusPending = useSelector(eventsSlice.selectIsStatusPending);

  const guideUserId = searchParams.get('guideUserId');
  const sent = searchParams.get('sent');

  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);

  const tourTypesOptions = [
    { value: 0, label: labels[language].familyAndFriends },
    { value: 1, label: labels[language].company },
    { value: 2, label: labels[language].school },
  ];
  const defaultTourType =
    tourTypesOptions.find((t) => t.value === Number(tourType)) ||
    tourTypesOptions[0];

  const languageOptions = [
    { value: 'pl', label: labels[language].polish },
    { value: 'en', label: labels[language].english },
  ];

  const loggedUser = useSelector(appSlice.selectUserData);
  const isFullyLogged = Boolean(loggedUser?.email);

  // const loggedUser = useSelector(appSlice.selectUserData);
  // const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);

  const initialData = {
    language,
    tourType: defaultTourType.value,
    participants: 4,
    description: '',
    durationHours: Number(event?.durationHoursFrom ?? 2),
    price: 0,
    startDatetime: '',
    eventId,
    guideUserId,
    phone: '',
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData(initialData);
    // scroll to top
    window.scrollTo(0, 0);
  }, [sent]);

  useEffect(() => {
    setData(initialData);
  }, [eventId, guideUserId]);

  useEffect(() => {
    if (isEventStatusPending) return;
    if (eventId === event?.eventId) return;
    if (!eventId) return;
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
      },
    });
  }, [eventId, event, isEventStatusPending]);

  const combineDateAndTime = () => {
    if (!data.startDatetime || !data.startTime) return;
    const date = new Date(data.startDatetime);
    const time = data.startTime.split(':');
    // if (time.length !== 2) {
    //   showNotification({
    //     message: 'Podaj godzinę w formacie HH:mm np. 10:00',
    //     type: 'warning',
    //   });
    //   setData({
    //     ...data,
    //     startTime: '',
    //   });
    //   return;
    // }
    date.setHours(time[0]);
    date.setMinutes(time[1] || 0);
    const newData = {
      ...data,
    };
    if (!time[1]) {
      newData.startTime = `${time[0]}:00`;
    }
    setData({
      ...newData,
      startDatetime: date.toISOString(),
    });
  };

  const priceStart = 100;
  const pricePerHour = 90;

  useEffect(() => {
    const { participants = 0, durationHours = 0 } = data;
    let price = priceStart + durationHours * pricePerHour;
    if (participants >= 20) {
      price += participants * 8;
    }
    if (data.language === 'en') {
      price = Math.round(price * 1.4);
    }

    setData({
      ...data,
      price,
    });
  }, [data.participants, data.durationHours, data.language]);

  const save = () => {
    if (!data.startDatetime) {
      showNotification({
        message: 'Wybierz datę zwiedzania',
        type: 'warning',
      });
      const element = document.getElementById('date-element');
      element?.scrollIntoView?.(element);
      return;
    }

    if (!data.participants) {
      showNotification({
        message: 'Wybierz ilość uczestników',
        type: 'warning',
      });
      return;
    }

    dispatch({
      type: 'SAVE_PRIVATE_EVENT',
      payload: data,
    });
  };

  // const title = eventId ? `Edytuj` : 'Zamów';

  //   if (eventId && event?.eventId !== eventId) {
  //     return <Loading />;
  //   }

  const title = labels[language].orderPrivateTour;

  return (
    <div className='page-content'>
      <Helmet>
        <title>{title} | Bimbash</title>
      </Helmet>

      {sent && (
        <>
          <div className='thanks'>
            {labels[language].thankYouForOrderingPrivateTour}
          </div>
          <br />
        </>
      )}

      {!isFullyLogged && (
        <div className='event-info'>
          <SignUp
            isInline={true}
            subHeader={<div>{labels[language].createAccountToCreateTour}</div>}
          />
        </div>
      )}

      {isFullyLogged && !sent && (
        <>
          <div className='h3'>{title}</div>

          <div className='form form-event'>
            {!loggedUser?.phone && (
              <div className='form-line'>
                <div className='field-label'>
                  {labels[language].phoneGeneral}
                </div>
                <input
                  id='phone'
                  name='phone'
                  type='text'
                  required
                  autoComplete='tel'
                  value={data.phone}
                  onChange={(e) => {
                    const trimmedValue = e.target.value?.trim?.();
                    setData({
                      ...data,
                      phone: trimmedValue,
                    });
                  }}
                />
              </div>
            )}

            <div className='form-line'>
              <div className='field-label'>{labels[language].participants}</div>
              <Select
                placeholder='Wybierz typ uczestników'
                options={tourTypesOptions}
                onChange={(e) => {
                  setData({
                    ...data,
                    tourType: e.value,
                  });
                  searchParams.set('tourType', e.value);
                  setSearchParams(searchParams);
                }}
                value={tourTypesOptions.find((t) => t.value === data.tourType)}
              />
            </div>

            <div className='form-line'>
              <div className='field-label'>{labels[language].language}</div>
              <Select
                placeholder='Wybierz język'
                options={languageOptions}
                onChange={(e) => {
                  setData({
                    ...data,
                    language: e.value,
                  });
                }}
                value={languageOptions.find((t) => t.value === data.language)}
              />
            </div>

            <div className='form-line'>
              <div className='field-label'>
                {labels[language].participantsNumber}
              </div>
              <input
                type='number'
                value={data.participants}
                onChange={(e) => {
                  setData({
                    ...data,
                    participants: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setData({
                      ...data,
                      participants: initialData.participants,
                    });
                  }
                }}
                className='input-text input-number-small'
                maxLength={4}
              />

              {data.participants >= 20 && (
                <div className='tips'>
                  {labels[language].largeGroupAudioGuide}
                </div>
              )}
            </div>

            <div
              className='form-line'
              id='date-element'
            >
              <div className='field-label'>{labels[language].tourDate}</div>
              <DatePicker
                selected={data.startDatetime && new Date(data.startDatetime)}
                onChange={(date) => {
                  setData({
                    ...data,
                    startDatetime: date?.toISOString?.(),
                  });
                }}
                onBlur={combineDateAndTime}
                dateFormat='yyyy-MM-dd'
                locale={locale}
                minDate={new Date()}
              />
            </div>
            {/* {warnings.startDatetime && (
              <div className='box-info'>{warnings.startDatetime}</div>
            )} */}
            <div className='form-line'>
              <div className='field-label'>{labels[language].tourTime}</div>
              <input
                type='text'
                className='input-tickets input-number-small'
                maxLength={5}
                placeholder='00:00'
                value={
                  data.startTime || data.startTime === ''
                    ? data.startTime
                    : data.startDatetime &&
                      new Date(data.startDatetime)
                        .toLocaleTimeString()
                        .slice(0, 5)
                }
                onChange={(e) => {
                  if (!e.target.value) {
                    setData({
                      ...data,
                      startTime: '',
                    });
                    return;
                  }
                  const newValue = e.target.value.replace(' ', ':');
                  // allow only numbers and character :
                  if (!/^[0-9:]*$/.test(newValue)) return;
                  setData({
                    ...data,
                    startTime: newValue,
                  });
                }}
                onBlur={combineDateAndTime}
              />
            </div>

            {eventId && (
              <div className='form-line'>
                <div className='field-label'>{labels[language].event}</div>
                <div className=''>
                  <Link
                    to={`/event/${event?.eventId}`}
                    className='link-regular'
                  >
                    {event?.name}
                  </Link>
                </div>
              </div>
            )}

            <div className='form-line'>
              <div className='field-label'>
                {eventId
                  ? labels[language].moreInfo
                  : labels[language].whatToDo}
              </div>
              <textarea
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                value={data.description}
              />
            </div>
            <div className='form-line'>
              <div className='field-label'>{labels[language].howManyHours}</div>
              <input
                type='number'
                value={data.durationHours}
                onChange={(e) => {
                  setData({
                    ...data,
                    durationHours: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setData({
                      ...data,
                      durationHours: initialData.durationHours,
                    });
                  }
                }}
                className='input-text input-number-small'
              />
            </div>

            <div className='form-line'>
              <div className='field-label'>{labels[language].priceLabel}</div>

              {data.participants >= 20 && (
                <div className='tips'>
                  {labels[language].priceIncludesAudioGuide}
                </div>
              )}

              <input
                type='number'
                value={data.price}
                onChange={(e) => {
                  setData({
                    ...data,
                    price: e.target.value,
                  });
                }}
                // onBlur={(e) => {
                //   if (e.target.value === '') {
                //     setData({
                //       ...data,
                //       price: initialData.price,
                //     });
                //   }
                // }}
                className='input-text input-number-small'
              />
            </div>

            {isStatusPending && <Loading />}
            {!isStatusPending && (
              <>
                <div className='form-actions'>
                  <button
                    onClick={save}
                    type='button'
                    className='submit button-primary'
                  >
                    {labels[language].order}
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateTour;
