import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { ReactComponent as SolidStar } from '../../svgs/solid/star.svg';
import { ReactComponent as SolidCalendarCheck } from '../../svgs/solid/calendar-check.svg';

import * as usersSlice from '../../store/app/usersSlice';
import { Loading } from '../Loading/Loading';
import { UserCardMedium } from '../User/UserCard';
import { language } from '../../core/utils';
import { labels } from '../../labels';

export const Guides = () => {
  const dispatch = useDispatch();
  const users = useSelector(usersSlice.selectItems);
  const isStatusPending = useSelector(usersSlice.selectIsStatusPending);

  const [
    searchParams,
    //    setSearchParams
  ] = useSearchParams();
  const page = searchParams.get('page') || 0;

  useEffect(() => {
    dispatch({
      type: 'FETCH_USERS',
      payload: {
        page,
      },
    });
  }, [page]);

  return (
    <div className='main-container guides-page'>
      <Helmet>
        <title>{labels[language].guides}</title>
      </Helmet>

      <div className='h3'>{labels[language].guides}</div>

      <div className='guides-list'>
        {users?.map((u) => {
          return (
            <UserCardMedium
              user={u}
              key={u.userId}
              suffixElement={
                <div className='guide-details'>
                  {Boolean(u.eventCount) && (
                    <div
                      className='icon-pair'
                      data-tooltip-id='tooltip'
                      data-tooltip-content='Wydarzenia'
                    >
                      <SolidCalendarCheck className='icon' />
                      {u.eventCount}
                    </div>
                  )}
                  {Boolean(u.reviewsCount) && (
                    <div
                      className='icon-pair'
                      data-tooltip-id='tooltip'
                      data-tooltip-content='Ocena'
                    >
                      <SolidStar className='icon' />
                      {Number(u.rating).toFixed(2)} ({u.reviewsCount})
                    </div>
                  )}
                </div>
              }
            />
          );
        })}
      </div>
      {isStatusPending && <Loading />}
    </div>
  );
};

export default Guides;
