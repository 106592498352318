import { call, put, takeLatest } from 'redux-saga/effects';

import { showNotification } from '../../core/utils';

import * as eventSlotsSlice from '../app/eventSlotsSlice';
import * as appSlice from '../app/appSlice';
import config from '../../config';
import { getAuthHeaders } from '../utils';
import { fetchOrdersSaga } from './orders';

const fetchEventSlotParticipants = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { eventSlotId } = payload;
  searchParams.set('eventSlotId', eventSlotId);

  const response = await fetch(
    `${config.appApiUrl}/event-slots/participants?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchEventSlotParticipantsSaga({ payload } = {}) {
  yield put(appSlice.setStatusPending());
  const data = yield call(fetchEventSlotParticipants, {
    payload,
  });
  yield put(appSlice.setStatusDefault());
  const { error, participants } = data;
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(eventSlotsSlice.setParticipants(participants));
}

const fetchEventSlot = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { eventSlotId } = payload;
  searchParams.set('eventSlotId', eventSlotId);

  const response = await fetch(
    `${config.appApiUrl}/event-slots/details?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchEventSlotSaga(action) {
  yield put(eventSlotsSlice.setStatusPending());
  const data = yield call(fetchEventSlot, action);
  yield put(eventSlotsSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  yield put(eventSlotsSlice.setItem(data));
}

const refundOrder = async ({ payload = {} } = {}) => {
  const response = await fetch(`${config.appApiUrl}/orders/refund`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* refundOrderSaga(action) {
  yield put(eventSlotsSlice.setStatusPending());
  const refund = yield call(refundOrder, action);
  yield put(eventSlotsSlice.setStatusDefault());
  if (refund.error) {
    showNotification({
      message: refund.error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: 'Proces zwrotu został zainicjowany.',
    type: 'success',
  });
  if (action.payload.userId) {
    yield fetchOrdersSaga();
    return;
  }
  yield fetchEventSlotSaga(action);
}

const saveEventSlot = async ({ payload = {} } = {}) => {
  const response = await fetch(`${config.appApiUrl}/event-slots`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* saveEventSlotSaga(action) {
  yield put(eventSlotsSlice.setStatusPending());
  const data = yield call(saveEventSlot, action);
  yield put(eventSlotsSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  window?.navigateTo(
    `/my-events/edit-event-slots/${action.payload.eventSlot.eventId}`
  );
}

const deleteEventSlot = async ({ payload = {} } = {}) => {
  const response = await fetch(`${config.appApiUrl}/event-slots/delete`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* deleteEventSlotSaga(action) {
  yield put(eventSlotsSlice.setStatusPending());
  const data = yield call(deleteEventSlot, action);
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    yield put(eventSlotsSlice.setStatusDefault());
    return;
  }
  showNotification({
    message: 'Termin został usunięty',
    type: 'success',
  });
  yield put(eventSlotsSlice.setStatusDefault());
  yield fetchEventSlotsSaga({ payload: { eventId: action.payload.eventId } });
}

const fetchEventSlots = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { eventId, startDate, endDate, isAdminView } = payload;
  if (eventId) {
    searchParams.set('eventId', eventId);
  }
  if (startDate) {
    searchParams.set('startDate', startDate);
  }
  if (endDate) {
    searchParams.set('endDate', endDate);
  }
  if (isAdminView) {
    searchParams.set('isAdminView', isAdminView);
  }

  const response = await fetch(
    `${config.appApiUrl}/event-slots/list?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchEventSlotsSaga(action) {
  const data = yield call(fetchEventSlots, action);
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  yield put(eventSlotsSlice.setItems(data));
}

const massRefundEventSlot = async ({ payload = {} } = {}) => {
  const response = await fetch(`${config.appApiUrl}/orders/mass-refund`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* massRefundEventSlotSaga(action) {
  yield put(eventSlotsSlice.setStatusPending());
  const data = yield call(massRefundEventSlot, action);
  yield put(eventSlotsSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }

  showNotification({
    message: 'Zwroty wysłano poprawnie',
    type: 'success',
  });
}

export function* eventSlotsSagas() {
  yield takeLatest('FETCH_EVENT_SLOT', fetchEventSlotSaga);
  yield takeLatest('REFUND_ORDER', refundOrderSaga);
  yield takeLatest('FETCH_EVENT_SLOTS', fetchEventSlotsSaga);
  yield takeLatest('SAVE_EVENT_SLOT', saveEventSlotSaga);
  yield takeLatest('DELETE_EVENT_SLOT', deleteEventSlotSaga);
  yield takeLatest(
    'FETCH_EVENT_SLOT_PARTICIPANTS',
    fetchEventSlotParticipantsSaga
  );
  yield takeLatest('MASS_REFUND_EVENT_SLOT', massRefundEventSlotSaga);
}
