import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as eventsSlice from '../../store/app/eventsSlice';
import { EventsList } from '../Event/EventsList';
import { language } from '../../core/utils';
import { labels } from '../../labels';

export const MyEvents = () => {
  const dispatch = useDispatch();
  const events = useSelector(eventsSlice.selectMyItems);

  useEffect(() => {
    dispatch({
      type: 'FETCH_MY_EVENTS',
      payload: {},
    });
  }, []);

  return (
    <div className='page-content my-events'>
      <Helmet>
        <title>{labels[language].myEvents} | Bimbash</title>
      </Helmet>

      <div className='h3'>{labels[language].myEvents}</div>

      <div className='page-actions'>
        <Link
          to='/my-events/add'
          className='button button-primary'
        >
          {labels[language].add}
        </Link>
      </div>

      <EventsList
        events={events}
        isMyEvents
      />
    </div>
  );
};

export default MyEvents;
