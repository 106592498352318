import { Helmet } from 'react-helmet';

export const About = () => {
  return (
    <div className='content-page'>
      <Helmet>
        <title>O nas | Bimbash</title>
      </Helmet>
      <div></div>
    </div>
  );
};
