import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  language,
  isMobile,
  getFirstDayOfWeek,
  locale,
} from '../../core/utils';
import { labels } from '../../labels';

const CustomInput = React.forwardRef(
  ({ value, placeholder = labels[language].selectDate, onClick }, ref) => (
    <button
      className='custom-input'
      onClick={onClick}
      ref={ref}
      readOnly
    >
      {value || placeholder}
    </button>
  )
);

const MyContainer = ({ className, children, datePickerRef }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hide = () => {
    datePickerRef?.current?.setOpen?.(false);
  };

  return (
    <div>
      <CalendarContainer className={className}>
        <div className='calendar-header'>
          <button
            className='button button-secondary'
            onClick={() => {
              searchParams.set(
                'startDate',
                format(new Date(), 'yyyy-MM-dd', {
                  locale,
                })
              );
              searchParams.set(
                'endDate',
                format(new Date(), 'yyyy-MM-dd', {
                  locale,
                })
              );
              searchParams.delete('page');
              setSearchParams(searchParams);
              hide();
            }}
          >
            {labels[language].today}
          </button>
          <button
            className='button button-secondary'
            onClick={() => {
              searchParams.set(
                'startDate',
                format(
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                  'yyyy-MM-dd',
                  {
                    locale,
                  }
                )
              );
              searchParams.set(
                'endDate',
                format(
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                  'yyyy-MM-dd',
                  {
                    locale,
                  }
                )
              );
              searchParams.delete('page');
              setSearchParams(searchParams);
              hide();
            }}
          >
            {labels[language].tomorrow}
          </button>
          <button
            className='button button-secondary'
            onClick={() => {
              searchParams.delete('page');
              searchParams.set(
                'startDate',
                format(new Date(), 'yyyy-MM-dd', {
                  locale,
                })
              );
              searchParams.set(
                'endDate',
                format(
                  new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
                  'yyyy-MM-dd',
                  {
                    locale,
                  }
                )
              );
              searchParams.delete('page');
              setSearchParams(searchParams);
              hide();
            }}
          >
            {labels[language].next7Days}
          </button>
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          {children}
        </div>
      </CalendarContainer>
    </div>
  );
};

export const DatePickerInputRange = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startDate') || '';
  const endDate = searchParams.get('endDate') || '';
  const datePickerRef = useRef(null);

  return (
    <div className='input-datepicker-container'>
      <i className='fa-regular fa-calendar-range' />
      <DatePicker
        ref={datePickerRef}
        calendarContainer={(props) => {
          return (
            <MyContainer
              {...props}
              datePickerRef={datePickerRef}
            />
          );
        }}
        placeholderText={labels[language].pickDate}
        disabledKeyboardNavigation
        customInput={<CustomInput />}
        onChange={(dates) => {
          let [start, end] = dates;

          const formattedStartDate = format(start, 'yyyy-MM-dd', {
            locale,
          });

          const urlStartDate = searchParams.get('startDate');
          if (urlStartDate && urlStartDate === formattedStartDate && !end) {
            end = start;
            datePickerRef?.current?.setOpen?.(false);
          }

          searchParams.set('startDate', formattedStartDate);
          if (!end) {
            searchParams.delete('endDate');
          }
          if (end) {
            searchParams.set(
              'endDate',
              format(end, 'yyyy-MM-dd', {
                locale,
              })
            );
          }
          searchParams.delete('page');
          setSearchParams(searchParams);
        }}
        dateFormat='MMM dd'
        locale={locale}
        selectsRange
        // inline
        startDate={startDate && new Date(startDate)}
        endDate={endDate && new Date(endDate)}
        minDate={new Date()}
        monthsShown={isMobile ? 1 : 2}
        calendarStartDay={getFirstDayOfWeek(locale)}
      />
    </div>
  );
};

export const DatePickerInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const exactDate = searchParams.get('exactDate') || '';

  return (
    <div className='input-datepicker-container'>
      <i className='fa-regular fa-calendar-range' />
      <DatePicker
        calendarContainer={MyContainer}
        placeholderText={labels[language].pickDate}
        disabledKeyboardNavigation
        customInput={<CustomInput />}
        onChange={(exactDate) => {
          searchParams.set(
            'exactDate',
            format(exactDate, 'yyyy-MM-dd', {
              locale,
            })
          );
          searchParams.delete('page');
          setSearchParams(searchParams);
        }}
        dateFormat='dd MMMM'
        locale={locale}
        selected={exactDate && new Date(exactDate)}
        minDate={new Date()}
        calendarStartDay={getFirstDayOfWeek(locale)}
      />
    </div>
  );
};
