import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

import { labels } from '../../labels';
import { language } from '../../core/utils';
import { Stars } from './Stars';
import { UserCard } from '../User/UserCard';
registerLocale('pl', pl);

export const Reviews = ({ title = 'Opinie', reviews, limit = 5 }) => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setShowAll(false);
  }, []);

  if (!reviews?.length) return;

  const reviewsToShow = showAll ? reviews : reviews.slice(0, limit);

  return (
    <div className='event-reviews'>
      <div className='h3 header-semi'>{title}</div>

      {reviewsToShow.map((review) => {
        return (
          <div
            className='event-review'
            key={review.reviewId}
          >
            <div className='event-review-header'>
              <div className='event-review-rating'>
                <Stars rating={review.rating} />
              </div>
              <div className='event-subheader'>
                <div className='event-review-username'>
                  <UserCard
                    user={review}
                    showAvatar={true}
                    avatarWidth={32}
                  />
                </div>
                <div className='event-review-date'>
                  {format(new Date(review.created), 'dd MMMM yyyy', {
                    locale: pl,
                  })}
                </div>
              </div>
            </div>
            <div className='event-review-content'>{review.description}</div>
          </div>
        );
      })}

      {reviews.length > limit && !showAll && (
        <button
          className='button-small'
          onClick={() => setShowAll(!showAll)}
        >
          {labels[language].showMore} ({reviews.length - limit})
        </button>
      )}
    </div>
  );
};
