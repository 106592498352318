import { select, call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { getAuthHeaders } from '../utils';
import * as appSlice from '../app/appSlice';
import * as usersSlice from '../app/usersSlice';
import {
  showNotification,
  redirectToLandingPage,
  language,
  hashx,
  isTikTokInternalBrowser,
} from '../../core/utils';
import config from '../../config';
import { labels } from '../../labels';
import { USER_STATUS_BLOCKED, USER_STATUS_DEFAULT } from '../../consts';

const saveAuthTokenToLocalStorage = ({ authToken }) => {
  localStorage.setItem('authToken', authToken);
};

export function* saveAuthToken({ authToken }) {
  yield put(appSlice.setAuthToken({ authToken }));
  yield call(saveAuthTokenToLocalStorage, { authToken });
  yield initSaga({
    authToken,
  });
}

export function* setAuthTokenSaga({ payload }) {
  const { authToken } = payload;
  yield saveAuthToken({
    authToken,
  });
}

const gtagc = () => {
  window.gtag?.('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
  localStorage?.setItem?.('gtagc', '1');
};

const signUp = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/sign-up`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* signUpSaga({ payload }) {
  yield put(appSlice.setStatusPending());

  const user = yield call(signUp, { payload });
  const { error, errorType, authToken, isRoleSupplier, redirectUrl } = user;

  if (redirectUrl) {
    window.navigateTo(redirectUrl);
  }

  if (errorType) {
    yield put(appSlice.setStatusDefault());
    showNotification({
      message: labels[language][errorType],
      type: 'warning',
    });
    return;
  }

  if (error) {
    yield put(appSlice.setStatusDefault());
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  gtagc();

  yield saveAuthToken({
    authToken,
    isSignUp: true,
  });

  yield put(appSlice.setStatusDefault());

  gtag?.('event', 'sign_up', {
    event_category: 'engagement',
    event_label: 'new_user',
  });
  if (isRoleSupplier) {
    gtag?.('event', 'sign_up_supplier', {
      event_category: 'engagement',
      event_label: 'new_supplier',
    });
  }
  fbq?.('track', 'CompleteRegistration');
}

export function* createTemporaryAccountSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { authToken, error, redirectUrl } = yield call(createAccount, {
    payload,
  });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    if (redirectUrl) {
      window.navigateTo(redirectUrl);
    }
    return;
  }
  yield call(saveAuthTokenToLocalStorage, { authToken });
  yield put(appSlice.setStatusDefault());
  if (payload.redirectUrl) {
    yield call(window.navigateTo, payload.redirectUrl);
  }
}

const confirmEmail = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/confirm-email`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* confirmEmailSaga({ payload }) {
  const { error, authToken } = yield call(confirmEmail, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield saveAuthToken({
    authToken,
  });
  showNotification({
    message: labels[language].accountEmailConfirmed,
    type: 'success',
  });
}

export function* checkAuthTokenSaga() {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) return;
  yield put(appSlice.setAuthToken({ authToken }));
  yield initSaga({
    authToken,
  });
}

const logIn = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/log-in`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* logInSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const user = yield call(logIn, { payload });
  const { error, authToken, isRoleSupplier } = user;

  // if (redirectUrl) {
  //   window.navigateTo(redirectUrl);
  // }

  // if (errorType) {
  //   yield put(appSlice.setStatusDefault());
  //   showNotification({
  //     message: labels[language][errorType],
  //     type: 'warning',
  //   });
  //   return;
  // }

  if (error) {
    yield put(appSlice.setStatusDefault());
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }

  yield saveAuthToken({
    authToken,
  });

  gtagc?.();
  gtag?.('event', 'login', {
    event_category: 'engagement',
    event_label: 'existing_user',
  });
  if (isRoleSupplier) {
    gtag?.('event', 'login_supplier', {
      event_category: 'engagement',
      event_label: 'existing_user',
    });
  }
  fbq?.('trackCustom', 'Login', { content_name: 'UserLoggedIn' });
}

// export function* logInSuccessSaga({ payload }) {
//   const { authToken, isRoleSupplier } = payload;
//   yield saveAuthToken({
//     authToken,
//   });
//   gtag?.('event', 'login', {
//     event_category: 'engagement',
//     event_label: 'existing_user',
//   });
//   if (isRoleSupplier) {
//     gtag?.('event', 'login_supplier', {
//       event_category: 'engagement',
//       event_label: 'existing_user',
//     });
//   }
//   fbq?.('trackCustom', 'Login', { content_name: 'UserLoggedIn' });
// }

// export function* fetchUserDataSuccessSaga({ payload }) {
//   const { trackOrders, userData } = payload;
//   yield put(appSlice.setUserData(userData));
//   yield put(appSlice.setStatusDefault());
//   if (payload?.isRoleAdmin) {
//     window.gtag = () => {};
//     window.fbq = () => {};
//     localStorage?.setItem('isAdmin', '1');
//     return;
//   }
//   if (trackOrders?.length) {
//     trackOrders.forEach((trackOrder) => {
//       const { order, orderProducts = [] } = trackOrder;
//       if (!order) return;

//       const googleAnalyticsEvent = {
//         transaction_id: order.orderId,
//         // affiliation: 'bimbash',
//         value: Number(order.amount),
//         currency: 'PLN',
//         tax: 0,
//         shipping: 0,
//         items: orderProducts.map((product) => ({
//           id: product.eventSlotId,
//           name: product.eventName,
//           category: 'tour',
//           quantity: 1,
//           price: Number(product.amount),
//         })),
//       };

//       window?.gtag?.('event', 'purchase', googleAnalyticsEvent);

//       orderProducts.forEach((product) => {
//         window?.fbq?.('track', 'Purchase', {
//           currency: 'PLN',
//           value: Number(product.amount),
//           content_type: 'product',
//           content_ids: [product.eventSlotId],
//           content_name: product.eventName,
//           num_items: 1,
//         });
//       });
//     });
//   }

//   if (userData?.isRoleSupplier && !userData.stripeAccountId) {
//     // showNotification({
//     //   message: 'Załóż konto Stripe',
//     //   type: 'info',
//     // });
//     window.navigateTo?.('/stripe');
//   }
// }

// export function* fetchUserDataErrorSaga({ payload }) {
//   showNotification(payload);
//   yield put(appSlice.setStatusDefault());
// }

// export function* logInErrorSaga({ payload }) {
//   yield put(appSlice.setStatusDefault());
//   const { message } = payload;
//   showNotification({
//     message,
//     type: 'error',
//   });
// }

const passwordReset = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/password-reset`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* passwordResetSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(passwordReset, { payload });
  if (error) {
    yield put(appSlice.setStatusDefault());
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(appSlice.setStatusDefault());
  yield put(appSlice.setIsEmailSent(true));
}

const passwordResetUpdate = async ({ payload }) => {
  const response = await fetch(
    `${config.appApiUrl}/users/password-reset-update`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

export function* passwordResetUpdateSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const response = yield call(passwordResetUpdate, { payload });
  const { authToken, error } = response;
  if (error) {
    yield put(appSlice.setStatusDefault());
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(appSlice.setStatusDefault());
  yield saveAuthToken({
    authToken,
  });
  showNotification({
    message: labels[language].passwordChanged,
    type: 'success',
  });
}

const fetchMe = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { username } = payload;
  if (username) {
    searchParams.set('username', username);
  }

  try {
    const response = await fetch(
      `${config.appApiUrl}/users/me?${searchParams}`,
      {
        mode: 'cors',
        headers: {
          ...getAuthHeaders(),
        },
      }
    );
    const responseJSON = await response.json();
    return responseJSON;
  } catch (e) {
    console.log(e);
    return { error: e };
  }
};

export function* fetchMeSaga({ payload } = {}) {
  yield put(appSlice.setStatusPending());
  const userData = yield call(fetchMe, { payload });

  if (userData.error) {
    yield logOutSaga();
    yield put(appSlice.setStatusDefault());
    return;
  }

  yield put(appSlice.setUserData(userData));
  yield put(appSlice.setStatusDefault());

  const { trackOrders } = userData;

  if (userData?.isRoleAdmin) {
    window.gtag = () => {};
    window.fbq = () => {};
    localStorage?.setItem('isAdmin', '1');
    return;
  }

  if (trackOrders?.length) {
    trackOrders.forEach((trackOrder) => {
      const { order, orderProducts = [] } = trackOrder;
      if (!order) return;

      orderProducts.forEach(async (product) => {
        window?.fbq?.('track', 'Purchase', {
          currency: 'PLN',
          value: Number(product.amount),
          content_type: 'product',
          content_ids: [product.eventSlotId],
          content_name: product.eventName,
          num_items: 1,
        });

        // if (userData?.utmSource !== 'tiktok') return;
        if (!isTikTokInternalBrowser) return;

        // window.twq?.('event', 'tw-oeyi4-ol5jl', {
        //   value: totalAmount, // use this to pass the value of the conversion (e.g. 5.00)
        //   currency: 'PLN',
        //   contents: [
        //     {
        //       content_id: product.eventSlotId, // string. ID of the product. Example: "1077218".
        //       content_type: 'product', // string. Either product or product_group.
        //       content_name: product.eventName, // string. The name of the page or product. Example: "shirt".
        //     },
        //   ],
        //   conversion_id: orderProducts.orderId,
        //   email_address: userData.email,
        // });

        // add this before event code to all pages where PII data postback is expected and appropriate
        window?.ttq?.identify({
          email: '<hashed_email_address>', // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
          phone_number: '<hashed_phone_number>', // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
          external_id: '<hashed_extenal_id>', // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
        });

        const hashedEmail = await hashx(userData.email);
        const hashedUserId = await hashx(userData.userId);

        window?.ttq?.identify?.({
          email: hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
          external_id: hashedUserId,
        });
        window?.ttq?.track?.('CompletePayment', {
          contents: [
            {
              content_id: product.eventSlotId, // string. ID of the product. Example: "1077218".
              content_type: 'product', // string. Either product or product_group.
              content_name: product.eventName, // string. The name of the page or product. Example: "shirt".
            },
          ],
          value: Number(product.amount), // number. Value of the order or items sold. Example: 100.
          currency: 'PLN', // string. The 4217 currency code. Example: "USD".
        });
      });

      const googleAnalyticsEvent = {
        transaction_id: order.orderId,
        // affiliation: 'bimbash',
        value: Number(order.amount),
        currency: 'PLN',
        tax: 0,
        shipping: 0,
        items: orderProducts.map((product) => ({
          id: product.eventSlotId,
          name: product.eventName,
          category: 'tour',
          quantity: 1,
          price: Number(product.amount),
        })),
      };

      window?.gtag?.('event', 'purchase', googleAnalyticsEvent);
    });
  }

  if (!userData?.phone) {
    showNotification({
      message: labels[language].addPhone,
    });
    window.navigateTo?.('/settings');
    return;
  }

  if (userData?.isRoleSupplier && !userData.stripeAccountId) {
    window.navigateTo?.('/stripe');
  }
}

export function* logOutSaga({ payload } = {}) {
  yield put(appSlice.reset());
  yield put(usersSlice.reset());
  yield put(appSlice.setAuthToken({ authToken: null }));
  localStorage.removeItem('authToken');
  localStorage.removeItem('tmpAuthToken');
  if (payload?.redirectUrl) {
    window.navigateTo(payload.redirectUrl);
    return;
  }
  window.location = '/';
}

const fetchCurrencies = async () => {
  try {
    const coinPriceRequest = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=pln,eur`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const pricesResponse = await coinPriceRequest.json();
    return {
      eur: pricesResponse.usd.eur,
      pln: pricesResponse.usd.pln,
      usd: 1,
    };
  } catch (e) {
    console.log(e);
  }
};

export function* fetchCurrenciesSaga() {
  const price = yield call(fetchCurrencies);
  yield put(appSlice.setPrices(price));
}

export function* initSaga({
  authToken = localStorage.getItem('authToken'),
} = {}) {
  if (!authToken) return;

  yield fetchMeSaga();

  const isRedirected = yield call(redirectToLandingPage);
  if (isRedirected) {
    return;
  }

  const loginActionPaths = [
    '/log-in',
    '/sign-up',
    '/sign-up/supplier',
    '/verify-email',
  ];
  if (loginActionPaths.includes(window.location?.pathname)) {
    const user = yield select(appSlice.selectUserData);
    if (user?.isRoleAdmin) {
      // turns off google analytics, sentry and other tracking tools
      localStorage?.setItem('isAdmin', '1');
      window?.navigateTo?.('/admin/users?limit=50');
      return;
    }
    window?.navigateTo?.('/');
  }
}

// const saveProfile = async ({ payload }) => {
//   const response = await fetch(`${config.appApiUrl}/users/profile`, {
//     method: 'POST',
//     mode: 'cors',
//     body: JSON.stringify(payload),
//     headers: {
//       ...getAuthHeaders(),
//       'Content-Type': 'application/json',
//     },
//   });
//   const responseJSON = await response.json();
//   return responseJSON;
// };

// export function* saveProfileSaga({ payload }) {
//   yield put(appSlice.setStatusPending());
//   const { error } = yield call(saveProfile, { payload });
//   yield put(appSlice.setStatusDefault());
//   if (error) {
//     showNotification({
//       message: error,
//       type: 'error',
//     });
//     return;
//   }
//   yield fetchUserDataSaga();
//   const params = new URL(document.location).searchParams;
//   const isOnboarding = params.get('isOnboarding');
//   if (isOnboarding) {
//     if (redirectToLandingPage()) {
//       return;
//     }
//     window.navigateTo(`/`);
//     return;
//   }

//   window.navigateTo(`/users/@${payload.data.username}`);
// }

const saveProfile = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/profile`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* saveProfileSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(saveProfile, { payload });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  window.navigateTo(`/@${payload.username}`);
}

const saveSettings = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/settings`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* saveSettingsSaga(action) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(saveSettings, { payload: action.payload });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: labels[language].changesSaved,
  });
}

// const fetchProfileData = async ({ payload }) => {
//   const { sharedToken, userId, username } = payload;
//   const searchParams = new URLSearchParams('');
//   if (userId) {
//     searchParams.set('userId', userId);
//   }
//   if (username) {
//     searchParams.set('username', username);
//   }
//   if (sharedToken) {
//     searchParams.set('sharedToken', sharedToken);
//   }
//   const response = await fetch(
//     `${config.appApiUrl}/users/profile?${searchParams}`,
//     {
//       mode: 'cors',
//       headers: {
//         ...getAuthHeaders(),
//       },
//     }
//   );
//   const responseJSON = await response.json();
//   return responseJSON;
// };

const fetchUserProfile = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { username } = payload;
  if (username) {
    searchParams.set('username', username);
  }

  const response = await fetch(
    `${config.appApiUrl}/users/profile?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchUserProfileSaga({ payload } = {}) {
  yield put(usersSlice.setStatusPending());
  const userProfile = yield call(fetchUserProfile, {
    payload,
  });
  yield put(usersSlice.setStatusDefault());

  const { error, redirectUrl } = userProfile;
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  if (redirectUrl) {
    window.navigateTo(redirectUrl);
    return;
  }
  yield put(usersSlice.setItem(userProfile));
}

const fetchUsers = async ({ payload = {} }) => {
  const { filters = [], allRoles, isAdminView, limit, search } = payload;
  const searchParams = new URLSearchParams('');
  searchParams.set('filters', JSON.stringify(filters));

  if (allRoles) {
    searchParams.set('allRoles', allRoles);
  }
  if (isAdminView) {
    searchParams.set('isAdminView', isAdminView);
  }
  if (limit) {
    searchParams.set('limit', limit);
  }
  if (search) {
    searchParams.set('search', search);
  }

  const response = await fetch(
    `${config.appApiUrl}/users/list?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchUsersSaga({ payload }) {
  yield put(usersSlice.setStatusPending());
  const { data, error } = yield call(fetchUsers, {
    payload,
  });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(usersSlice.setItems(data));
  yield put(usersSlice.setStatusDefault());
}

const createConnectedAccount = async ({ payload }) => {
  const response = await fetch(
    `${config.appApiUrl}/users/stripe/connected-account`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'application/json',
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* createConnectedAccountSaga(action) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(createConnectedAccount, {
    payload: action.payload,
  });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield fetchMeSaga();
}

const createConnectedAccountLink = async ({ payload }) => {
  const response = await fetch(
    `${config.appApiUrl}/users/stripe/connected-account-link`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'application/json',
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* createConnectedAccountLinkSaga(action) {
  yield put(appSlice.setStatusPending());
  const data = yield call(createConnectedAccountLink, {
    payload: action.payload,
  });
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }

  window.location.href = data.url;
}

const stripeLogIn = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/stripe/log-in`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* stripeLogInSaga({ payload } = {}) {
  yield put(appSlice.setStatusPending());
  const { error, url } = yield call(stripeLogIn, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  window.location.href = url;
}

const adminFetchUsers = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/admin/list`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminFetchUsersSaga({ payload }) {
  yield put(usersSlice.setStatusPending());
  const data = yield call(adminFetchUsers, { payload });
  yield put(usersSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  yield put(usersSlice.setItems(data));
}

const adminDeleteUser = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/admin/delete`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminDeleteUserSaga({ payload }) {
  yield put(usersSlice.setStatusPending());
  const data = yield call(adminDeleteUser, { payload });
  yield put(usersSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  const users = yield select(usersSlice.selectItems);
  const newUsers = users.filter((user) => user.userId !== payload.userId);
  yield put(usersSlice.setItems(newUsers));
}

const adminBanUser = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/admin/ban`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminBanUserSaga({ payload }) {
  yield put(usersSlice.setStatusPending());
  const data = yield call(adminBanUser, { payload });
  yield put(usersSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  const users = yield select(usersSlice.selectItems);
  const newUsers = users.map((user) => {
    if (user.userId === payload.userId) {
      return {
        ...user,
        status: USER_STATUS_BLOCKED,
      };
    }
    return user;
  });
  yield put(usersSlice.setItems(newUsers));
}

const adminUnbanUser = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/admin/unban`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminUnbanUserSaga({ payload }) {
  yield put(usersSlice.setStatusPending());
  const data = yield call(adminUnbanUser, { payload });
  yield put(usersSlice.setStatusDefault());
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
  const users = yield select(usersSlice.selectItems);
  const newUsers = users.map((user) => {
    if (user.userId === payload.userId) {
      return {
        ...user,
        status: USER_STATUS_DEFAULT,
      };
    }
    return user;
  });
  yield put(usersSlice.setItems(newUsers));
}

const adminSaveUserReview = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/reviews/admin`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* adminSaveUserReviewSaga({ payload }) {
  const data = yield call(adminSaveUserReview, { payload });
  if (data.error) {
    showNotification({
      message: data.error,
      type: 'error',
    });
    return;
  }
}

const setUserStatus = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/status`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* setUserStatusSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(setUserStatus, { payload });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield fetchUsersSaga();
}

const setUserAvatar = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/avatar`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* setUserAvatarSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(setUserAvatar, { payload });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield fetchMe();
}

const deleteUser = async ({ payload }) => {
  const response = await fetch(
    `${config.appApiUrl}/users/delete/${payload.userId}`,
    {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

export function* deleteUserSaga({ payload } = {}) {
  // eslint-disable-next-line
  if (!confirm('Are you sure?')) return;
  const { error } = yield call(deleteUser, { payload });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  if (payload.logout) {
    showNotification({
      message: 'Account has been deleted',
      type: 'warning',
    });
    yield logOutSaga();
    return;
  }
  yield fetchUsersSaga();
}

// const setUserAccountUsd = async ({ payload }) => {
//   const response = await fetch(`${config.appApiUrl}/users/account-usd`, {
//     method: 'POST',
//     mode: 'cors',
//     body: JSON.stringify(payload),
//     headers: {
//       ...getAuthHeaders(),
//       'Content-Type': 'application/json',
//     },
//   });
//   const responseJSON = await response.json();
//   return responseJSON;
// };

// export function* setUserAccountUsdSaga({ payload }) {
//   yield put(appSlice.setStatusPending());
//   const { error } = yield call(setUserAccountUsd, { payload });
//   yield put(appSlice.setStatusDefault());
//   if (error) {
//     showNotification({
//       message: error,
//       type: 'error',
//     });
//     return;
//   }
//   showNotification({
//     message: 'Account updated',
//   });
// }

const sendUserInvite = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/users/send-invite`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export function* sendUserInviteSaga({ payload }) {
  yield put(appSlice.setStatusPending());
  const { error } = yield call(sendUserInvite, { payload });
  yield put(appSlice.setStatusDefault());
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  showNotification({
    message: 'invited',
  });
}

const checkUsernameFetch = async ({ payload }) => {
  const { username, paramUsername } = payload;
  const searchParams = new URLSearchParams('');
  searchParams.set('username', username);
  if (paramUsername) {
    searchParams.set('paramUsername', paramUsername);
  }
  const response = await fetch(
    `${config.appApiUrl}/users/check-username?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

export function* checkUsernameSaga({ payload }) {
  const { data, error } = yield call(checkUsernameFetch, {
    payload,
  });
  if (error) {
    showNotification({
      message: error,
      type: 'error',
    });
    return;
  }
  yield put(usersSlice.setIsUsernameTaken(data));
}

export function* usersSagas() {
  yield takeLatest('INIT', initSaga);
  yield takeLatest('SIGN_UP', signUpSaga);
  yield takeLatest('SET_AUTH_TOKEN', setAuthTokenSaga);
  yield takeLatest('CHECK_AUTH_TOKEN', checkAuthTokenSaga);

  yield takeLatest('CREATE_TEMPORARY_ACCOUNT', createTemporaryAccountSaga);
  yield takeLatest('CONFIRM_EMAIL', confirmEmailSaga);

  yield takeLatest('LOG_IN', logInSaga);
  yield takeLatest('LOG_OUT', logOutSaga);

  yield takeLatest('PASSWORD_RESET', passwordResetSaga);
  yield takeLatest('PASSWORD_RESET_UPDATE', passwordResetUpdateSaga);

  yield takeLatest('FETCH_CURRENCIES', fetchCurrenciesSaga);

  yield takeLatest('SAVE_PROFILE', saveProfileSaga);
  yield takeLatest('SAVE_SETTINGS', saveSettingsSaga);

  yield takeLatest('USERS_CHECK_USERNAME', checkUsernameSaga);
  // yield takeLatest('USER_SET_AVATAR', setUserAvatarSaga);
  // yield takeLatest('SET_USER_ACCOUNT_USD', setUserAccountUsdSaga);
  // yield takeLatest('SEND_USER_INVITE', sendUserInviteSaga);

  yield takeEvery('FETCH_ME', fetchMeSaga);
  yield takeEvery('FETCH_USER_PROFILE', fetchUserProfileSaga);

  yield takeLatest('FETCH_USERS', fetchUsersSaga);

  yield takeLatest('ADMIN_FETCH_USERS', adminFetchUsersSaga);
  yield takeLatest('ADMIN_DELETE_USER', adminDeleteUserSaga);
  yield takeLatest('ADMIN_BAN_USER', adminBanUserSaga);
  yield takeLatest('ADMIN_UNBAN_USER', adminUnbanUserSaga);

  yield takeLatest('ADMIN_SAVE_USER_REVIEW', adminSaveUserReviewSaga);
  // yield takeLatest('SET_USER_STATUS', setUserStatusSaga);
  // yield takeLatest('DELETE_USER', deleteUserSaga);

  // STRIPE
  yield takeLatest('CREATE_CONNECTED_ACCOUNT', createConnectedAccountSaga);
  yield takeLatest(
    'CREATE_CONNECTED_ACCOUNT_LINK',
    createConnectedAccountLinkSaga
  );
  yield takeLatest('STRIPE_LOG_IN', stripeLogInSaga);
}
