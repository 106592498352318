export const labels = {
  en: {
    add: 'Add',
    signUp: 'Sign Up',
    signUpSupplier: 'Sign Up Supplier',
    logIn: 'Log In',
    logInAction: 'Log In',
    logOut: 'Log Out',
    support: 'Support',
    contactUs: 'Contact us',
    language: 'Language',
    currency: 'Currency',
    about: 'About',
    terms: 'Terms & Conditions',
    supplierTerms: 'Supplier Terms & Conditions',
    privacy: 'Privacy Policy',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    newPassword: 'New Password',
    changePassword: 'Change Password',
    createAccount: 'Create Account',
    newAccount: 'New account',
    newAccountTourist: 'New Tourist Account',
    agreeWithTerms: 'I agree to ',
    forgotPassword: 'Forgot password',
    ourGPT: 'Chat with us',
    myTickets: 'My Tickets',
    home: 'Home',
    explore: 'Explore',
    buyTickets: 'Buy Tickets',
    myEvents: 'My Events',
    myProfile: 'My Profile',
    editProfile: 'Edit Profile',
    description: 'Description',
    website: 'Website',
    redirectingTitle: 'Redirecting...',
    redirectingDescription: `If your browser did not redirect you automatically click on the link `,
    firstName: 'First Name',
    lastName: 'Last Name',
    username: 'Username',
    email: 'Email',
    phone: 'Phone number',
    publicPhone: 'Phone number (public)',
    createAccountSupplier: 'Create Supplier Account',
    createAccount: 'Create Account',
    companyDetails: 'Company Details',
    companyName: 'Company Name',
    companyStreet: 'Street',
    companyCity: 'City',
    companyPostCode: 'Post Code',
    vatNumber: 'VAT Number',
    fieldIsRequired: 'This field is required',
    save: 'Save',
    events: 'Events',
    media: 'Images and Videos',
    images: 'Images',
    videos: 'Videos',
    selectDate: 'Select date',
    myEventSlots: 'My bookings',
    invalidEmail: 'Invalid email',
    settings: 'Settings',
    settingsSaved: 'Settings saved',
    changesSaved: 'Changes saved',
    forgotPassword: 'Forgot password',
    resetPassword: 'Reset password',
    passwordResetLinkSent: 'Password reset link sent to email',
    passwordChanged: 'Password changed',
    newPassword: 'New password',
    notifications: 'Notifications',
    ticketSold: 'Ticket sold',
    ticketReturned: 'Ticket returned',
    infant: 'Infant',
    child: 'Child',
    youth: 'Youth',
    adult: 'Adult',
    senior: 'Senior',
    student: 'Student (with ID)',
    military: 'Military (with ID)',
    eu: 'EU citizen',
    participant: 'Participant',
    discountRequirement:
      'Remember to take a document confirming the discount on the trip (e.g. student ID)',
    total: 'Total',
    stats: 'Stats',
    showMore: 'Show more',
    newReview: 'New review',
    faq: 'FAQ',
    giveFeedback: 'Give feedback',
    haveQuestions: 'Have questions? Contact us at ',
    haveQuestionsSimple: 'Have questions? ',
    chatWithOurAgent: 'Chat with our AI agent',
    noAccount: 'New to Bimbash? ',
    areYouASupplier: 'Are you a supplier? ',
    details: 'Details',
    duration: 'Duration',
    hours: 'h',
    smallGroups: 'Small groups',
    guidedTour: 'Guided tour',
    createAccountToCheckout: 'Create an account to buy tickets',
    datetime: 'Date and time',
    date: 'Date',
    slogan: 'Guided Tours in Krakow',
    pickDate: 'Select Date',
    perPerson: 'per person',
    similarEvents: 'Similar events',
    smallGroupsDescription: 'Enjoy a comfortable tour in a group of up to ',
    people: 'people',
    simpleReturn: 'Simple ticket return',
    simpleReturnDescription: 'Easy returns up to 24 hours before the event',
    startPlace: 'Meeting point',
    titleHome: 'BimBash | Krakow guided tours and experiences',
    today: 'Today',
    tomorrow: 'Tomorrow',
    next7Days: 'Next 7 days',
    popularEvents: 'Bestsellers',
    soon: 'Upcoming events',
    guides: 'Guides',
    usernameIsRequired: 'Username is required',
    usernameIsTaken: 'This username is already taken',
    includedInTicet: 'Ticket includes',
    addImage: 'Add image',
    addVideo: 'Add video',
    firstMessageText: 'First message',
    promptShared: 'Shared prompt',
    participants: 'Participants',
    otherParticipants: 'Other participants',
    userAlreadyExists: 'This user already exists',
    accountEmailConfirmed: 'Account has been fully activated',
    search: 'Search',
    media: 'Media',
    musicLicence: 'You can download and use music in any way - license',
    joinCreators:
      'Join artists, creators and influencers who promote Bimbash - hi@bimbash.com',
    files: 'Files',
    copiedToClipboard: 'Copied to clipboard',
    bestPractices: 'Best practices',
    privateTour: 'Private tour',
    schoolTour: 'School tour',
    companyTour: 'Company tour',
    lookingForPrivateTour: 'Looking for a private tour?',
    lookingForPrivateTourCTA:
      'Order a private group for your family, friends, company or school',
    createAccountToCreateTour: 'Create an account to order a tour',
    phoneGeneral: 'Phone number',
    important: 'Important',
    addPhone: 'Add phone number to your profile',
    edit: 'Edit',
    forSuchLargeGroup: `Is it a large group? `,
    noSlotForSuchLargeGroup: `No available slots for this amount of participants. You can ask the guide to add a new slot or `,
    orderPrivateTour: 'Order a private tour',
    thankYouForOrderingPrivateTour: `Order has been sent to guides. We will contact you by phone or email within a few hours.`,
    participantsNumber: 'Number of participants',
    largeGroupAudioGuide: `For large groups, Audio Guide headsets are needed - 8 PLN per person`,
    tourDate: 'Tour date',
    tourTime: 'Tour start time',
    event: 'Event',
    moreInfo: 'More info',
    whatToDo: 'What do you want to see?',
    howManyHours: 'How many hours do you want to tour?',
    priceLabel: 'Tour price [PLN]',
    priceIncludesAudioGuide: `The price of the audio guide headset is already included in this amount.`,
    order: 'Send',
    familyAndFriends: 'Family or friends',
    company: 'Company',
    school: 'School',
    polish: 'Polish',
    english: 'English',
    refPartnerId: 'Reference number (optional field)',
  },
  pl: {
    add: 'Dodaj',
    signUp: 'Nowy Turysta',
    signUpSupplier: 'Nowy Organizator',
    logIn: 'Logowanie',
    logInAction: 'Zaloguj',
    logOut: 'Wyloguj',
    support: 'Pomoc',
    contactUs: 'Kontakt',
    language: 'Język',
    currency: 'Waluta',
    about: 'O nas',
    terms: 'Regulamin',
    supplierTerms: 'Regulamin Organizatora',
    privacy: 'Polityka prywatności',
    password: 'Hasło',
    repeatPassword: 'Powtórz hasło',
    createAccount: 'Utwórz konto',
    newPassword: 'Nowe hasło',
    changePassword: 'Zmień hasło',
    newAccount: 'Nowe konto',
    newAccountTourist: 'Nowe konto Turysty',
    agreeWithTerms: 'Akceptuję ',
    forgotPassword: 'Nie pamiętasz hasła',
    ourGPT: 'Chat z Moniką',
    myTickets: 'Moje bilety',
    home: 'Index',
    explore: 'Przeglądaj',
    buyTickets: 'Kup bilety',
    myEvents: 'Moje wydarzenia',
    myProfile: 'Mój profil',
    editProfile: 'Edytuj profil',
    description: 'Opis',
    website: 'Strona internetowa (np. profil na facebooku)',
    redirectingTitle: 'Przekierowywanie...',
    redirectingDescription: `Jeśli przeglądarka nie przekierowała Cię automatycznie kliknij na
    link `,
    firstName: 'Imię',
    lastName: 'Nazwisko',
    username: 'Nazwa użytkownika (zmienia adres @URL profilu)',
    email: 'Email',
    phone: 'Telefon (prywatny)',
    publicPhone: 'Telefon (publiczny)',
    createAccountSupplier: 'Nowe konto Organizatora',
    createAccount: 'Załóż konto',
    companyDetails: 'Dane firmy',
    companyName: 'Nazwa firmy',
    companyStreet: 'Ulica',
    companyCity: 'Miasto',
    companyPostCode: 'Kod pocztowy',
    vatNumber: 'Numer NIP',
    fieldIsRequired: 'To pole jest wymagane',
    save: 'Zapisz',
    events: 'Wydarzenia',
    media: 'Zdjęcia i filmy',
    images: 'Zdjęcia',
    videos: 'Filmy',
    selectDate: 'Wybierz datę',
    myEventSlots: 'Moje terminy',
    invalidEmail: 'Niepoprawny email',
    settings: 'Ustawienia',
    settingsSaved: 'Ustawienia zapisane',
    changesSaved: 'Zmiany zapisane',
    forgotPassword: 'Nie pamiętam hasła',
    resetPassword: 'Zresetuj hasło',
    passwordResetLinkSent: 'Link do resetu hasła został wysłany na email',
    passwordChanged: 'Hasło zmienione',
    notifications: 'Powiadomienia',
    newPassword: 'Nowe hasło',
    ticketSold: 'Sprzedaż biletu',
    ticketReturned: 'Zwrot biletu',
    infant: 'Niemowlę',
    child: 'Dziecko',
    youth: 'Młodzież',
    adult: 'Dorosły',
    senior: 'Emeryt',
    student: 'Student',
    military: 'Wojskowy',
    eu: 'Obywatel UE',
    participant: 'Uczestnik',
    discountRequirement:
      'Pamiętaj, aby zabrać na wycieczkę dokument potwierdzający zniżkę (np. legitymację)',
    total: 'Suma',
    stats: 'Statystyki',
    showMore: 'Pokaż więcej',
    newReview: 'Nowa opinia',
    faq: 'Najczęstsze pytania',
    giveFeedback: 'Zgłoś pomysł',
    haveQuestions: 'Masz pytania? Napisz do nas',
    chatWithOurAgent: 'Chat z Moniką (AI)',
    noAccount: 'Nie masz konta? ',
    haveQuestionsSimple: 'Masz pytania? ',
    areYouASupplier: 'Jesteś Organizatorem? ',
    details: 'Szczegóły',
    duration: 'Czas trwania',
    hours: 'godziny',
    smallGroups: 'Małe grupy',
    guidedTour: 'Wycieczka z Przewodnikiem',
    createAccountToCheckout: 'Utwórz konto, aby zamówić bilety',
    datetime: 'Termin',
    date: 'Data',
    slogan: 'Zwiedzaj Kraków z Przewodnikami',
    pickDate: 'Wybierz termin',
    perPerson: 'za osobę',
    similarEvents: 'Podobne wydarzenia',
    smallGroupsDescription: 'Zwiedzaj komfortowo w grupie do ',
    people: 'osób',
    simpleReturn: 'Prosty zwrot biletu',
    simpleReturnDescription: 'Łatwe zwroty do 24 godzin przed wydarzeniem',
    startPlace: 'Miejsce startu',
    titleHome: 'BimBash | Zwiedzanie Krakowa z Przewodnikiem',
    today: 'Dzisiaj',
    tomorrow: 'Jutro',
    next7Days: 'Najbliższe 7 dni',
    popularEvents: 'Najczęściej kupowane',
    soon: 'Najbliższe wydarzenia',
    guides: 'Przewodnicy',
    usernameIsRequired: 'Nazwa użytkownika jest wymagana',
    includedInTicet: 'Bilet zawiera',
    usernameIsTaken: 'Ta nazwa użytkownika jest już zajęta',
    addImage: 'Dodaj zdjęcie',
    addVideo: 'Dodaj video',
    firstMessageText: 'Wiadomość powitalna',
    promptShared: 'Prompt wspólny',
    participants: 'Uczestnicy',
    otherParticipants: 'Inni uczestnicy',
    userAlreadyExists: 'Ten użytkownik już istnieje',
    accountEmailConfirmed: 'Konto zostało w pełni aktywowane',
    search: 'Szukaj po nazwie',
    media: 'Muzyka i Media',
    musicLicence: 'Muzykę możesz pobrać i używać w dowolny sposób - licencja',
    joinCreators:
      'Dołącz do artystów, kreatorów i influencerów, którzy promują Bimbash - hi@bimbash.com',
    files: 'Pliki',
    copiedToClipboard: 'Skopiowano do schowka',
    bestPractices: 'Dobre praktyki',
    privateTour: 'Wycieczka prywatna',
    schoolTour: 'Wycieczka szkolna',
    companyTour: 'Wycieczka firmowa',
    lookingForPrivateTour: 'Chcesz zwiedzać indywidualnie?',
    lookingForPrivateTourCTA:
      'Zamów prywatną grupę dla swojej rodziny, znajomych, firmy lub szkoły',
    createAccountToCreateTour: 'Załóż konto, aby zamówić wycieczkę',
    phoneGeneral: 'Telefon',
    important: 'Ważne',
    addPhone: 'Uzupełnij numer telefonu w swoim profilu',
    edit: 'Edytuj',
    forSuchLargeGroup: 'Duża grupa? ',
    noSlotForSuchLargeGroup: `Brak dostępnych terminów dla takiej ilości uczestników. Możesz poprosić przewodnika o dodanie nowego terminu lub `,
    orderPrivateTour: 'Zamów prywatną wycieczkę',
    thankYouForOrderingPrivateTour: `Zamówienie zostało wysłane do przewodników. Skontaktujemy się z Tobą telefonicznie lub emailowo w ciągu kilku godzin.`,
    participantsNumber: 'Liczba uczestników',
    largeGroupAudioGuide: `Dla dużych grup potrzebne są zestawy słuchawkowe Audio Guide - 8 zł za osobę`,
    tourDate: 'Data wycieczki',
    tourTime: 'Godzina startu',
    event: 'Wydarzenie',
    moreInfo: 'Dodatkowe uwagi',
    whatToDo: 'Co chcecie zobaczyć?',
    howManyHours: 'Ile godzin chcecie zwiedzać?',
    priceLabel: 'Cena zwiedznia [zł]',
    priceIncludesAudioGuide: `Cena zestawu słuchawkowego audio guide jest już wliczona w tę kwotę.`,
    order: 'Zamów',
    familyAndFriends: 'Rodzina lub znajomi',
    company: 'Firma',
    school: 'Szkoła',
    polish: 'Polski',
    english: 'Angielski',
    refPartnerId: 'Numer referencji (pole opcjonalne)',
  },
};
