import { call, put, takeLatest } from 'redux-saga/effects';

import * as placesSlice from '../app/placesSlice';
import { showNotification } from '../../core/utils';
import { getAuthHeaders } from '../utils';
import { config } from '../../config';

const fetchPlaces = async () => {
  const searchParams = new URLSearchParams('');
  const response = await fetch(
    `${config.appApiUrl}/places/list?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchPlacesSaga(action) {
  yield put(placesSlice.setStatusPending());
  const places = yield call(fetchPlaces, action);
  if (places?.error) {
    showNotification({
      message: places.error,
      type: 'error',
    });
    return;
  }
  yield put(placesSlice.setItems(places));
  yield put(placesSlice.setStatusDefault());
}

const fetchPlace = async ({ payload = {} } = {}) => {
  const searchParams = new URLSearchParams('');
  const { placeId } = payload;
  if (placeId) {
    searchParams.append('placeId', placeId);
  }
  const response = await fetch(
    `${config.appApiUrl}/places/details?${searchParams}`,
    {
      mode: 'cors',
      headers: {
        ...getAuthHeaders(),
      },
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
};

function* fetchPlaceSaga(action) {
  yield put(placesSlice.setStatusPending());
  const place = yield call(fetchPlace, action);
  if (place?.error) {
    showNotification({
      message: place.error,
      type: 'error',
    });
    return;
  }
  yield put(placesSlice.setItem(place));
  yield put(placesSlice.setStatusDefault());
}

const deletePlace = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/places/delete`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* deletePlaceSaga(action) {
  const place = yield call(deletePlace, action);
  if (place?.error) {
    showNotification({
      message: place.error,
      type: 'error',
    });
    return;
  }
  yield fetchPlacesSaga();
}

const savePlace = async ({ payload }) => {
  const response = await fetch(`${config.appApiUrl}/places`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(payload),
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

function* savePlaceSaga(action) {
  yield put(placesSlice.setStatusPending());
  const place = yield call(savePlace, action);
  yield put(placesSlice.setStatusDefault());
  if (place?.error) {
    showNotification({
      message: place.error,
      type: 'error',
    });
    return;
  }
  window?.navigateTo?.(`/admin/places`);
}

export function* placesSagas() {
  yield takeLatest('FETCH_PLACES', fetchPlacesSaga);
  yield takeLatest('FETCH_PLACE', fetchPlaceSaga);
  yield takeLatest('DELETE_PLACE', deletePlaceSaga);
  yield takeLatest('SAVE_PLACE', savePlaceSaga);
}
