import { useEffect } from 'react';
import {
  Link,
  useParams,
  useSearchParams,
  NavLink,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { registerLocale } from 'react-datepicker';
import useLocalStorage from 'use-local-storage';

import { Loading } from '../Loading/Loading';
import * as eventsSlice from '../../store/app/eventsSlice';
import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';
import * as appSlice from '../../store/app/appSlice';

registerLocale('pl', pl);

export const EditEventSlots = ({}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { eventId } = params;
  const dispatch = useDispatch();
  const event = useSelector(eventsSlice.selectItem);
  const eventSlots = useSelector(eventSlotsSlice.selectItems);
  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);
  const loggedUser = useSelector(appSlice.selectUserData);
  const isAdminView = Boolean(loggedUser?.isRoleAdmin);
  const [searchParams] = useSearchParams();
  const startDate =
    searchParams.get('startDate') ||
    format(new Date(), 'yyyy-MM-dd', {
      locale: pl,
    });
  const endDate = searchParams.get('endDate') || '';

  const [isTip1Hidden, setIsTip1Hidden] = useLocalStorage('isTip1Hidden', 0);
  const handleHideTip = () => {
    setIsTip1Hidden(1);
  };

  useEffect(() => {
    if (!eventId) return;
    if (eventId === event?.eventId) return;
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
      },
    });
  }, [eventId]);

  useEffect(() => {
    if (!eventId) return;
    dispatch({
      type: 'FETCH_EVENT_SLOTS',
      payload: {
        eventId,
        startDate,
        endDate,
        isAdminView,
      },
    });
  }, [eventId, startDate, endDate, isAdminView]);

  if (!event) return null;

  return (
    <div className='page-content'>
      <div className='h3'>Terminy</div>
      <div>
        <NavLink to={`/event/${eventId}`}>{event?.name}</NavLink>
      </div>
      <div className='page-actions'>
        <Link
          className='button button-primary'
          to={`/my-events/edit-event-slot/${event.eventId}`}
        >
          Dodaj
        </Link>
      </div>

      {!isTip1Hidden && (
        <div
          className='box-tip'
          onClick={handleHideTip}
        >
          Jeśli nie wiesz jakie dodać terminy, spójrz na stronę{' '}
          <Link
            to='/stats'
            className='link-regular'
          >
            Statystyki
          </Link>
          <span className='box-tip-close'>
            <i className='fa-solid fa-xmark' />
          </span>
        </div>
      )}

      {/* <div className='page-filters'>
        <div className='input-datepicker-container'>
          <i className='fa-regular fa-calendar-range' />
          <DatePicker
            placeholderText='Wybierz terminy'
            onChange={(dates) => {
              const [start, end] = dates;
              searchParams.set(
                'startDate',
                format(start, 'yyyy-MM-dd', {
                  locale: pl,
                })
              );
              if (!end) {
                searchParams.delete('endDate');
              }
              if (end) {
                searchParams.set(
                  'endDate',
                  format(end, 'yyyy-MM-dd', {
                    locale: pl,
                  })
                );
              }
              setSearchParams(searchParams);
            }}
            dateFormat='MMM dd'
            locale='pl'
            selectsRange
            // inline
            startDate={startDate && new Date(startDate)}
            endDate={endDate && new Date(endDate)}
          />
        </div>
      </div> */}

      <div className='edit-event-slots'>
        <table className='event-slots-table'>
          <thead>
            <tr>
              <th>Data</th>
              <th>Ilość biletów</th>
              <th>Sprzedane</th>
              <th>Dostępne</th>
              <th>Edytuj</th>
              <th>Usun</th>
            </tr>
          </thead>
          <tbody>
            {eventSlots.map((eventSlot) => {
              return (
                <tr
                  className=''
                  key={eventSlot.eventSlotId}
                >
                  <td className='event-slot-data'>
                    <Link
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                    >
                      {format(
                        new Date(eventSlot.startDatetime),
                        'EEEE dd.MM.yyyy HH:mm',
                        {
                          locale: pl,
                        }
                      )}
                    </Link>
                  </td>
                  <td className='tickets-count'>{eventSlot.ticketsCount}</td>
                  <td className='tickets-count'>
                    <Link
                      to={`/my-events/event-slot-tickets/${eventSlot.eventSlotId}`}
                    >
                      {eventSlot.ticketsSoldCount}
                    </Link>
                  </td>
                  <td className='tickets-count'>
                    {eventSlot.ticketsCount - eventSlot.ticketsSoldCount}
                  </td>

                  <td className=''>
                    <Link
                      // onClick={(e) => {
                      //   if (eventSlot.ticketsSoldCount > 0 && !isRoleAdmin) {
                      //     e.preventDefault();
                      //     alert(
                      //       'Nie można edytować terminu ze sprzedanymi biletami. Najpierw anuluj zakupione bilety.'
                      //     );
                      //   }
                      // }}
                      to={`/my-events/edit-event-slot/${eventId}/${eventSlot.eventSlotId}`}
                      className='button-action'
                    >
                      Edytuj
                    </Link>
                  </td>
                  <td>
                    <a
                      className='button-action'
                      onClick={() => {
                        if (eventSlot.ticketsSoldCount > 0) {
                          navigate(
                            `/my-events/delete-event-slot/${eventSlot.eventSlotId}`
                          );
                          return;
                        }

                        if (!confirm(`Czy na pewno chcesz usunąć?`)) return;

                        dispatch({
                          type: 'DELETE_EVENT_SLOT',
                          payload: {
                            eventId: event.eventId,
                            eventSlotId: eventSlot.eventSlotId,
                            isInline: true,
                          },
                        });
                      }}
                    >
                      Usuń
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isStatusPending && <Loading />}
      </div>
    </div>
  );
};
