import { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import classNames from 'classnames';
registerLocale('pl', pl);

import * as ordersSlice from '../../store/app/ordersSlice';
import * as appSlice from '../../store/app/appSlice';
import { ReactComponent as SolidPhone } from '../../svgs/solid/phone.svg';

import { cleanLink, language, showNotification } from '../../core/utils';
import { UserCard } from '../User/UserCard';
import {
  ORDER_STATUS_PENDING,
  ORDER_STATUS_COMPLETE,
  ORDER_STATUS_REFUNDED_BY_SUPPLIER,
  orderStatusLabels,
  priceCategories,
  ORDER_STATUS_REFUNDED,
} from '../../consts';
import { Stars } from '../Common/Stars';
import { labels } from '../../labels';

const ReviewForm = ({ order }) => {
  const dispatch = useDispatch();
  const initialData = {
    rating: 5,
    description: '',
    tip: 0,
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData({
      ...order,
      rating: order.reviewRating || initialData.rating,
      description: order.reviewDescription || initialData.description,
    });
  }, [order]);

  const handleSave = ({ tip } = {}) => {
    if (!data.rating) {
      showNotification({
        message: 'Kliknij na gwiazdy, aby ocenić wydarzenie',
        type: 'warning',
      });
      return;
    }
    dispatch({
      type: 'SAVE_REVIEW',
      payload: {
        orderId: order.orderId,
        orderProductId: order.orderProductId,
        tip,
        ...data,
      },
    });
  };

  const ratingLabels = [
    {
      label: '-',
      description: '',
    },
    {
      label: 'Nigdy więcej',
      description: 'Niewybaczalne błędy ze strony organizatora',
    },
    {
      label: 'Słabo',
      description:
        'Tylko część wycieczki została zrealizowana zgodnie z opisem',
    },
    {
      label: 'Tak sobie',
      description: 'Sporo rzeczy do poprawy, ale jest nadzieja',
    },
    {
      label: 'Dobrze',
      description: 'Kilka drobnych potknięć, ale przewodnik się stara',
    },
    {
      label: 'Świetnie! Polecam!',
      description: 'Wycieczka zgodna z opisem, przewodnik godny polecenia',
    },
    {
      label: 'Niesamowite przeżycie! Znacznie powyżej oczekiwań!',
      description:
        'Uwaga - ta ocena powinna być używana tylko w wyjątkowych sytuacjach! :)',
    },
  ];
  return (
    <div className='form-review'>
      <div className='form-item h3'>Recenzja</div>
      <div className='review-stars'>
        <Stars
          rating={data.rating}
          onChange={(rating) => {
            setData({
              ...data,
              rating,
            });
          }}
        />
        {/* <div>{data.rating} / 6</div> */}
      </div>
      <div className='rating-info'>
        <div className='rating-label'>{ratingLabels[data.rating].label}</div>
        <div className='rating-description'>
          {ratingLabels[data.rating].description}
        </div>
      </div>
      <textarea
        placeholder='Tu możesz napisać tekst recenzji, najlepiej z poradą co można ulepszyć np. "Dużo zabawnych historii, polecam! Co można ulepszyć? Przerwa na kawę może być krótsza."'
        value={data.description}
        onChange={(e) => {
          setData({
            ...data,
            description: e.target.value,
          });
        }}
        onBlur={(e) => {
          setData({
            ...data,
            description: e.target.value.trim(),
          });
        }}
      />
      {data.rating > 4 && (
        <div className='tip'>
          <span>Zasługuje na napiwek?</span>
          <input
            type='number'
            min={2}
            max={2000}
            value={data.tip}
            onChange={(e) => {
              setData({
                ...data,
                tip: e.target.value,
              });
            }}
            onBlur={(e) => {
              if (e.target.value) {
                const tipValue = Number(e.target.value);
                if (tipValue < 3) {
                  setData({
                    ...data,
                    tip: 3,
                  });
                }
              }
            }}
          />
          <span>zł</span>
        </div>
      )}

      <div className='review-actions'>
        <button
          onClick={handleSave}
          className={classNames('button-default', {
            'button-primary': !order.reviewId,
          })}
        >
          Zapisz
        </button>
      </div>
    </div>
  );
};

export const MyTickets = () => {
  const dispatch = useDispatch();
  const orders = useSelector(ordersSlice.selectItems);
  const loggedUser = useSelector(appSlice.selectUserData);

  useEffect(() => {
    dispatch({
      type: 'FETCH_ORDERS',
    });
  }, []);

  const [searchParams] = useSearchParams();
  const isThanks = searchParams.get('thanks');
  const isTipThanks = searchParams.get('tipSuccess');
  const extOrderId = searchParams.get('extOrderId');
  const sessionId = searchParams.get('sessionId');

  return (
    <div className='tickets-page'>
      <Helmet>
        <title>Moje bilety | Bimbash</title>
      </Helmet>
      <div className='h3'>Moje bilety</div>

      {isThanks && <div className='thanks'>Dziękujemy za zakup biletu!</div>}
      {isTipThanks && (
        <div className='thanks'>Dziękujemy za napiwek dla przewodnika!</div>
      )}

      <div className='tickets-list'>
        {orders.map((order) => {
          const simulateIsCompleted =
            isThanks &&
            ((extOrderId && order.extOrderId === extOrderId) ||
              (sessionId && order.paymentSessionId === sessionId));
          return (
            <div
              className={classNames('ticket', {
                'ticket-refunded':
                  order.status === ORDER_STATUS_REFUNDED_BY_SUPPLIER ||
                  order.status === ORDER_STATUS_REFUNDED,
              })}
              key={order.orderId}
            >
              <div className='line'>
                <div className='event-name'>
                  <Link
                    to={`/event/${order.eventId}/${cleanLink(order.eventName)}`}
                    className='link-basic'
                  >
                    {order.eventName}
                  </Link>
                </div>
              </div>
              <div className='line'>
                {order.status !== ORDER_STATUS_REFUNDED_BY_SUPPLIER && (
                  <div className='ticket-datetime'>
                    {format(
                      new Date(order.startDatetime),
                      'EEEE dd.MM.yyyy HH:mm',
                      {
                        locale: pl,
                      }
                    )}
                  </div>
                )}

                {order.status === ORDER_STATUS_REFUNDED_BY_SUPPLIER && (
                  <div className='box-alert'>
                    Ten termin został odwołany przez Organizatora. Pieniądze za
                    bilet zostaną zwrócone na Twoje konto.
                  </div>
                )}
              </div>
              <div className='line'>
                <div className='form-item h3'>Miejsce startu</div>
                <a
                  href={order.startPlaceLink}
                  target='_blank'
                  className='link-basic'
                >
                  {order.startPlaceName}
                  {/* {selectedEventSlot.startPlace.address && (
                      <div>{selectedEventSlot.startPlace.address}</div>
                    )} */}
                </a>
              </div>
              <div className='supplier-box'>
                <div className='user-header h3'>Organizator</div>
                <UserCard
                  user={{
                    username: order.supplierUsername,
                    avatarId: order.supplierAvatarId,
                    videoId: order.supplierVideoId,
                    firstName: order.supplierFirstName,
                    lastName: order.supplierLastName,
                  }}
                  avatarWidth={order.externalUserUserId ? 80 : 160}
                  className={
                    order.externalUserUserId
                      ? 'user-card-small'
                      : 'user-card-medium'
                  }
                />
                {order.supplierPublicPhone &&
                  !order.externalUserPublicPhone && (
                    <div className='profile-desc icon-line'>
                      <SolidPhone />
                      <a
                        href={`tel:${order.supplierPublicPhone}`}
                        className='link-basic'
                      >
                        {order.supplierPublicPhone}
                      </a>
                    </div>
                  )}
              </div>
              {order.externalUserUserId && (
                <div className='supplier-box'>
                  <div className='user-header h3'>Przewodnik</div>
                  <UserCard
                    user={{
                      userId: order.externalUserUserId,
                      username: order.externalUserUsername,
                      avatarId: order.externalUserAvatarId,
                      videoId: order.externalUserVideoId,
                      firstName: order.externalUserFirstName,
                      lastName: order.externalUserLastName,
                    }}
                    avatarWidth={160}
                    className='user-card-medium'
                  />
                  {order.externalUserPublicPhone && (
                    <div className='profile-desc icon-line'>
                      <SolidPhone />
                      <a
                        href={`tel:${order.externalUserPublicPhone}`}
                        className='link-basic'
                      >
                        {order.externalUserPublicPhone}
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className='line'>
                <div className='form-item h3'>Bilety</div>
                {priceCategories.map(({ priceCategoryId }) => {
                  return (
                    Boolean(order[priceCategoryId]) && (
                      <div key={priceCategoryId}>
                        {order[priceCategoryId]}x{' '}
                        {labels[language][priceCategoryId]}
                      </div>
                    )
                  );
                })}
              </div>
              <div className='line'>
                <div className='event-name qr-line'>
                  <img
                    src='/qr.png'
                    className='qr'
                  />{' '}
                  ID biletu: {order.orderId}
                </div>
              </div>

              <div className='event-name qr-line'>
                Status:{' '}
                {
                  orderStatusLabels.pl[
                    simulateIsCompleted ? ORDER_STATUS_COMPLETE : order.status
                  ]
                }
              </div>
              {Boolean(order.isReviewable) &&
                order.status === ORDER_STATUS_COMPLETE && (
                  <ReviewForm order={order} />
                )}
              <div className='ticket-actions'>
                <div className='line'>
                  {order.status === ORDER_STATUS_PENDING &&
                    !simulateIsCompleted && (
                      <>
                        <div className='line'>
                          <a
                            href={order.redirectUrl}
                            className='link-basic'
                            target='_blank'
                          >
                            Zapłać teraz
                          </a>
                        </div>
                      </>
                    )}
                  {order.status === ORDER_STATUS_COMPLETE &&
                    Boolean(order.isReturnable) && (
                      <button
                        onClick={() => {
                          if (!confirm('Czy na pewno chcesz zwrócić bilet?')) {
                            return;
                          }
                          dispatch({
                            type: 'REFUND_ORDER',
                            payload: {
                              orderId: order.orderId,
                              orderProductId: order.orderProductId,
                              eventSlotId: order.eventSlotId,
                              userId: loggedUser.userId,
                            },
                          });
                          gtag?.('event', 'refund', {
                            currency: 'PLN',
                            value: order.amount,
                            transaction_id: order.orderId,
                          });
                        }}
                        className='button-default'
                      >
                        Zwróć bilet
                      </button>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyTickets;
