import { useState, useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { labels } from '../../labels';
import { language } from '../../core/utils';

export const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get('email') || '';

  const [email, setEmail] = useState(paramEmail);
  const [password, setPassword] = useState('');
  const isStatusPending = useSelector(appSlice.selectIsStatusPending);
  // const isStatusSent = useSelector(appSlice.selectIsStatusSent);

  const loggedUser = useSelector(appSlice.selectUserData);
  useEffect(() => {
    if (loggedUser) {
      navigate('/');
    }
  }, [loggedUser]);

  const handleSave = (e) => {
    e.preventDefault();
    dispatch({
      type: 'LOG_IN',
      payload: {
        email,
        password,
      },
    });
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        dispatch({
          type: 'CHECK_AUTH_TOKEN',
        });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <form
      className='form-basic form-log-in form-centered'
      onSubmit={handleSave}
    >
      <Helmet>
        <title>{labels[language].logIn} | Bimbash</title>
      </Helmet>

      {!isStatusPending && (
        <>
          <div className='form-header'>{labels[language].logIn}</div>

          {/* {isStatusSent && (
            <div className='box-info'>
              Check your email for an activation link
            </div>
          )} */}

          <div className='line'>
            <div className='label'>Email</div>
            <input
              type='text'
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete='email'
            />
          </div>

          <div className='line'>
            <div className='label'>{labels[language].password}</div>
            <input
              type='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPassword('')}
              autoComplete='current-password'
            />

            <Link
              to={`/forgot?email=${encodeURIComponent(email)}`}
              className='forgot'
            >
              {labels[language].forgotPassword}?
            </Link>
          </div>

          {/* <input
            onClick={onClickSubmit}
            type='submit'
            className='submit button-submit'
            value={labels[language].logInAction}
          /> */}

          <button
            type='submit'
            className='submit button-submit'
          >
            {labels[language].logInAction}
          </button>

          <div className='form-info-box'>
            <div>
              {labels[language].noAccount}{' '}
              <Link
                to='/sign-up'
                className='link-basic'
              >
                {labels[language].createAccount}
              </Link>
            </div>
          </div>
        </>
      )}

      {isStatusPending && <Loading />}
    </form>
  );
};
