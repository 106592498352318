import { createSlice } from '@reduxjs/toolkit';

const STATUS_DEFAULT = 0;
const STATUS_PENDING = 1;

const initialState = {
  status: STATUS_DEFAULT,
  item: null,
  items: [],
  generatedItem: null,
  participants: [],
};

export const eventSlotsSlice = createSlice({
  name: 'eventSlots',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { app } = payload;
      if (!app) return;
      for (const prop in initialState) {
        if (typeof app[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    reset: (state) => {
      for (const prop in initialState) {
        state[prop] = initialState[prop];
      }
    },
    setStatusPending: (state) => {
      state.status = STATUS_PENDING;
    },
    setStatusDefault: (state) => {
      state.status = STATUS_DEFAULT;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    setItem: (state, { payload }) => {
      state.item = payload;
    },
    setParticipants: (state, { payload }) => {
      state.participants = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  reset,
  setStatusPending,
  setStatusDefault,
  setItems,
  setItem,
  setParticipants,
} = eventSlotsSlice.actions;

export const selectEventSlots = (state = window.store?.getState()) =>
  state?.eventSlots;
export const selectItems = (state) => selectEventSlots(state)?.items;
export const selectItem = (state) => selectEventSlots(state)?.item;
export const selectIsStatusPending = (state) =>
  selectEventSlots(state)?.status === STATUS_PENDING;
export const selectIsStatusDefault = (state) =>
  selectEventSlots(state)?.status === STATUS_DEFAULT;
export const selectParticipants = (state) =>
  selectEventSlots(state)?.participants;

export default eventSlotsSlice.reducer;
