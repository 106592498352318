import * as appSlice from './app/appSlice';
import { extendedHeader } from '../core/utils';
import { config } from '../config';

export const getAuthHeaders = ({ isSignUp } = {}) => {
  const state = window?.store?.getState();
  const authToken =
    appSlice?.selectAuthToken(state) || localStorage?.getItem('authToken');

  const impersonateUserId = JSON.parse(
    localStorage.getItem(config.impersonateUserIdKey) || '""'
  );
  const headers = {
    'auth-token': authToken,
  };
  if (impersonateUserId) {
    headers.impersonate = impersonateUserId;
  }
  const metaSource = localStorage.getItem(config.metaSourceKey);
  if (metaSource) {
    headers[config.metaSourceKey] = metaSource;
  }
  if (isSignUp) {
    headers.extended = extendedHeader;
  }
  return headers;
};
