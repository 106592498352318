import { Link } from 'react-router-dom';

import { Image } from '../Common/Image';
import { config } from '../../config';

export const UserCard = ({
  user,
  avatarWidth = 64,
  showAvatar = true,
  showPublicPhone,
  showWebsite,
  suffix,
  suffixElement,
  target,
  className = '',
}) => {
  let fullName = `${user.firstName} ${user.lastName}`;
  if (fullName.trim() === '') {
    fullName = user.username;
  }
  if (suffix) {
    fullName = `${fullName} ${suffix}`;
  }
  return (
    <Link
      to={`/@${user.username}`}
      className={`user-card ${className}`}
      onClick={(e) => e.stopPropagation()}
      target={target}
    >
      {showAvatar && (
        <>
          {user.avatarId && (
            <Image
              id={user.avatarId}
              forceWidth={avatarWidth}
              defaultWidth={avatarWidth}
              className=''
              alt={user.username}
            />
          )}
          {!user.avatarId && user.videoId && (
            <img
              src={`${config.imgHostUrl}/${user.videoId}_poster_240p.0000000.jpg`}
              alt={user.username}
            />
          )}
          {!user.avatarId && !user.videoId && (
            <img
              src={`${config.imgHostUrl}/default-avatar3.jpg`}
              alt='guide'
              className='avatar default-avatar'
            />
          )}
        </>
      )}
      {/* {user.videoId && (
        <div className='video-circle'>
          <VideoBox
            videoId={user.videoId}
            // autoPlay={Boolean(localStorageAutoplay)}
            autoPlay={false}
            muted
            loop
            playsInline
            controls={false}
            className=''
            isDynamicHeight={false}
            customResolution='480p'
            preload='metadata'
          />
        </div>
      )} */}
      <span>{fullName}</span>
      {showPublicPhone && user.publicPhone && (
        <span className='public-phone'>{user.publicPhone}</span>
      )}
      {suffixElement}
      {showWebsite && user.website && (
        <a
          href={user.website}
          target='_blank'
          rel='noopener noreferrer'
          onClick={(e) => e.stopPropagation()}
        >
          {user.website}
        </a>
      )}
    </Link>
  );
};

export const UserCardMedium = ({
  user,
  avatarWidth = 64,
  showAvatar = true,
  showPublicPhone,
  showWebsite,
  suffix,
  suffixElement,
}) => {
  let fullName = `${user.firstName} ${user.lastName}`;
  if (fullName.trim() === '') {
    fullName = user.username;
  }
  if (suffix) {
    fullName = `${fullName} ${suffix}`;
  }
  return (
    <Link
      to={`/@${user.username}`}
      className='user-card user-card-medium'
      onClick={(e) => e.stopPropagation()}
    >
      {showAvatar && (
        <>
          {user.avatarId && (
            <Image
              id={user.avatarId}
              forceWidth={avatarWidth}
              defaultWidth={avatarWidth}
              className=''
              alt={user.username}
            />
          )}
          {!user.avatarId && user.videoId && (
            <img
              src={`${config.imgHostUrl}/${user.videoId}_poster_240p.0000000.jpg`}
              alt={user.username}
            />
          )}
          {!user.avatarId && !user.videoId && (
            <img
              src={`${config.imgHostUrl}/default-avatar3.jpg`}
              alt='guide'
              className='avatar default-avatar'
            />
          )}
        </>
      )}
      {/* {user.videoId && (
        <div className='video-circle'>
          <VideoBox
            videoId={user.videoId}
            // autoPlay={Boolean(localStorageAutoplay)}
            autoPlay={false}
            muted
            loop
            playsInline
            controls={false}
            className=''
            isDynamicHeight={false}
            customResolution='480p'
            preload='metadata'
          />
        </div>
      )} */}
      <div className='user-card-details'>
        <span>{fullName}</span>
        <span>{suffixElement}</span>
      </div>
      {showPublicPhone && user.publicPhone && (
        <span className='public-phone'>{user.publicPhone}</span>
      )}
      {showWebsite && user.website && (
        <a
          href={user.website}
          target='_blank'
          rel='noopener noreferrer'
          onClick={(e) => e.stopPropagation()}
        >
          {user.website}
        </a>
      )}
    </Link>
  );
};
