import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { ReactComponent as RegularClock } from '../../svgs/regular/clock.svg';
import { ReactComponent as RegularFaceSmile } from '../../svgs/regular/face-smile.svg';
import { ReactComponent as RegularLocationDot } from '../../svgs/regular/location-dot.svg';
import { ReactComponent as RegularShieldCheck } from '../../svgs/regular/shield-check.svg';
// import { ReactComponent as RegularCalendar } from '../../svgs/regular/calendar.svg';
import { ReactComponent as RegularCheck } from '../../svgs/regular/check.svg';
import { ReactComponent as SolidCirclePlus } from '../../svgs/solid/circle-plus.svg';
import { ReactComponent as SolidCircleMinus } from '../../svgs/solid/circle-minus.svg';
import { ReactComponent as RegularCircleExclamation } from '../../svgs/regular/circle-exclamation.svg';
import { ReactComponent as RegularMobileSignal } from '../../svgs/regular/mobile-signal.svg';

import { ReactComponent as CardsBlank } from '../../svgs/regular/cards-blank.svg';
import { ReactComponent as SolidStar } from '../../svgs/solid/star.svg';
import { ReactComponent as MessagePlus } from '../../svgs/regular/message-plus.svg';

import { useIsInViewport } from '../../core/useIsInViewport';
import { SignUp } from '../SignUp/SignUp';
// import { config } from '../../config';
import { labels } from '../../labels';
import { formatRating, language, isMobile } from '../../core/utils';
import { Dialog } from './Dialog';
import { EventSlotCard } from './EventSlotCard';
import * as appSlice from '../../store/app/appSlice';
import * as eventsSlice from '../../store/app/eventsSlice';
import * as ordersSlice from '../../store/app/ordersSlice';
import * as eventSlotsSlice from '../../store/app/eventSlotsSlice';

import { Loading } from '../Loading/Loading';
import { EventsList } from './EventsList';
// import { VideoBox } from '../Feed/VideoBox';
import { UserCard, UserCardMedium } from '../User/UserCard';
import { STATUS_APPROVED, priceCategories } from '../../consts';
import { ImageSlider } from '../Common/Image';
import { Reviews } from '../Common/Reviews';

const formatAge = ({ ageFrom, ageTo }) => {
  if (language === 'en') {
    if (ageFrom && !ageTo) {
      return `From ${ageFrom} years`;
    }
    if (!ageFrom && ageTo) {
      return `To ${ageTo} years`;
    }
    return `${ageFrom} - ${ageTo} years`;
  }

  if (ageFrom && !ageTo) {
    return `Od ${ageFrom} lat`;
  }
  if (!ageFrom && ageTo) {
    return `Do ${ageTo} lat`;
  }
  return `${ageFrom} - ${ageTo} lat`;
};

export const EventDetails = () => {
  // const [localStorageAutoplay, setLocalStorageAutoplay] = useLocalStorage(
  //   'autoplay',
  //   0
  // );
  const [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const paramBuyTickets = searchParams.get('buyTickets');

  const formBookRef = useRef();
  const isFormBookInViewport = useIsInViewport(formBookRef);

  const startDate = searchParams.get('startDate') || '';
  const endDate = searchParams.get('endDate') || '';
  const exactDate = searchParams.get('exactDate') || '';

  useEffect(() => {
    if (!formBookRef?.current) return;
    dispatch(appSlice.setIsFormBookInViewport(isFormBookInViewport));
  }, [isFormBookInViewport, formBookRef?.current]);

  const loggedUser = useSelector(appSlice.selectUserData);
  const isFullyLogged = Boolean(loggedUser?.email);
  const isRoleAdmin = Boolean(loggedUser?.isRoleAdmin);
  const tickets = useSelector(appSlice.selectTickets);

  const event = useSelector(eventsSlice.selectItem);
  const participants = useSelector(eventSlotsSlice.selectParticipants);
  const isMine = Boolean(loggedUser?.userId === event?.userId);

  const isStatusPending = useSelector(ordersSlice.selectIsStatusPending);

  useEffect(() => {
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
        startDate,
        endDate,
        exactDate,
      },
    });
  }, [eventId]);

  const [isEventSelectorVisible, setIsEventSelectorVisible] = useState(false);

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);

  const [selectedEventSlot, setSelectedEventSlot] = useState();

  // useEffect(() => {
  //   if (!selectedEventSlot) return;
  //   dispatch({
  //     type: 'FETCH_EVENT_SLOT_PARTICIPANTS',
  //     payload: selectedEventSlot,
  //   });
  // }, [selectedEventSlot]);

  const updateTicketsNumber = ({ priceCategoryId, value, addValue }) => {
    if (addValue) {
      if (addValue < 0 && !tickets[priceCategoryId]) {
        return;
      }

      if (!tickets[priceCategoryId]) {
        dispatch(
          appSlice.setTickets({
            ...tickets,
            [priceCategoryId]: addValue,
          })
        );
        return;
      }

      dispatch(
        appSlice.setTickets({
          ...tickets,
          [priceCategoryId]: tickets[priceCategoryId] + addValue,
        })
      );
      return;
    }

    if (value == '') {
      dispatch(
        appSlice.setTickets({
          ...tickets,
          [priceCategoryId]: '',
        })
      );
      return;
    }

    dispatch(
      appSlice.setTickets({
        ...tickets,
        [priceCategoryId]: Number(value),
      })
    );
  };

  const totalTicketsNumber = Object.values(tickets).reduce(
    (acc, val) => acc + (isNaN(val) ? 0 : Number(val)),
    0
  );

  const totalAmount = event?.eventPrices?.reduce((acc, ep) => {
    const numberOfTickets = isNaN(tickets[ep.priceCategoryId])
      ? 0
      : tickets[ep.priceCategoryId];
    return acc + numberOfTickets * (ep.price ?? 0);
  }, 0);

  const availableEventSlots =
    event?.eventId === eventId
      ? event?.eventSlots?.filter((e) => {
          const ticketsAvailable = e.ticketsCount - e.ticketsSoldCount;
          return ticketsAvailable >= totalTicketsNumber;
        }) ?? []
      : [];

  useEffect(() => {
    setIsDescriptionVisible(!Boolean(paramBuyTickets));
  }, [eventId, paramBuyTickets]);

  useEffect(() => {
    if (
      !availableEventSlots.length ||
      (selectedEventSlot &&
        availableEventSlots.find(
          (a) => a.eventSlotId === selectedEventSlot?.eventSlotId
        ))
    )
      return;
    setSelectedEventSlot(availableEventSlots[0]);
  }, [availableEventSlots, selectedEventSlot]);

  useEffect(() => {
    if (!event || !selectedEventSlot) return;
    if (event?.eventId !== selectedEventSlot?.eventId) {
      setSelectedEventSlot(null);
    }
  }, [selectedEventSlot, event]);

  const handleSumbmit = () => {
    dispatch({
      type: 'CREATE_ORDER',
      payload: {
        products: [
          {
            event,
            eventSlotId: selectedEventSlot.eventSlotId,
            tickets,
            totalAmount,
            quantity: totalTicketsNumber,
          },
        ],
      },
    });

    // showNotification({
    //   message: 'Bilety zostały dodane do koszyka',
    //   type: 'success',
    // });
  };

  const isEventSlotAvailable =
    totalTicketsNumber <=
    (selectedEventSlot?.ticketsCount ?? 0) -
      (selectedEventSlot?.ticketsSoldCount ?? 0);

  const isDiscounted = priceCategories
    .filter((p) => p.discount)
    .find((pc) => {
      return tickets[pc.priceCategoryId] > 0;
    });

  const linkPrivate = `/private-tour/?tourType=0&guideUserId=${event?.userId}&eventId=${event?.eventId}`;

  if (!event || isStatusPending || event?.eventId !== eventId) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content event-info event-view'>
      <Helmet>
        <title>{event.name} | Bimbash</title>
        <meta
          name='description'
          content={event.description}
        />
        <meta
          property='og:title'
          content={event.name}
        />
        <meta
          property='og:description'
          content={event.description}
        />
        <meta
          property='og:url'
          content={window.location.href}
        />
        <meta
          name='twitter:title'
          content={event.name}
        />
        <meta
          name='twitter:description'
          content={event.description}
        />
      </Helmet>
      {!paramBuyTickets && !isMobile && (
        <div
          className='event-videos'
          // onClick={() => {
          //   if (isMobile && !isVideoPlaying) {
          //     setIsVideoPlaying(true);
          //   }
          // }}
        >
          {/* {event.eventVideos?.map((video) => (
            <VideoBox
              key={video.eventVideoId}
              videoId={video.videoId}
              // autoPlay={Boolean(localStorageAutoplay)}
              autoPlay={Boolean(localStorageAutoplay)}
              isDynamicHeight={false}
              muted={false}
              onPause={() => {
                setLocalStorageAutoplay(0);
                // setIsVideoPlaying(false);
              }}
              onPlay={() => {
                setLocalStorageAutoplay(1);
                //  setIsVideoPlaying(true);
              }}
              // loop
              playsInline={!isIOS}
              // playsInline={false}
              controls
              className={classNames({
                'event-video-small': !isVideoPlaying,
              })}
            />
          ))} */}

          {/* {!Boolean(event.eventVideos?.length) && event.videoId && (
            <VideoBox
              videoId={event.videoId}
              // autoPlay={Boolean(localStorageAutoplay)}
              autoPlay={Boolean(localStorageAutoplay)}
              isDynamicHeight={false}
              muted={false}
              onPause={() => {
                setLocalStorageAutoplay(0);
                setIsVideoPlaying(false);
              }}
              onPlay={() => {
                setLocalStorageAutoplay(1);
                setIsVideoPlaying(true);
              }}
              // loop
              playsInline={!isIOS}
              controls
              className={classNames({
                'event-video-small': !isVideoPlaying,
                'event-video-big': isVideoPlaying,
              })}
            />
          )} */}

          {/* {!Boolean(event.eventImages?.length) &&
            event.avatarId &&
            !event.videoId && (
              <img
                src={`${config.imgHostUrl}/${event.avatarId}_1024.jpg`}
                alt='Krakow'
              />
            )} */}

          {/* {event.eventImages?.map((image) => (
            <Image
              key={image.imageId}
              id={image.imageId}
              defaultWidth={isMobile ? 0 : 400}
              alt={event.name}
            />
          ))} */}

          {!isMobile && Boolean(event?.eventImages?.length) && (
            <ImageSlider
              items={event?.eventImages}
              showThumbnails={(event?.eventImages?.length ?? 0) > 1}
              showPlayButton={false}
              swipeThreshold={5}
              showNav={false}

              // showPlayButton={(event?.eventImages?.length ?? 0) > 1}
            />
          )}
        </div>
      )}

      {isMobile && Boolean(event?.eventImages?.length) && (
        <ImageSlider
          items={event?.eventImages}
          showThumbnails={false}
          showPlayButton={false}
          swipeThreshold={5}
          showNav={false}
          showBullets={true}
          // showPlayButton={(event?.eventImages?.length ?? 0) > 1}
        />
      )}

      <div className='event-description'>
        <div className='event-description-content'>
          <div className='event-type'>{labels[language].guidedTour}</div>
          <div className='event-head'>
            <div className='event-name'>{event.name}</div>
          </div>

          {isDescriptionVisible && (
            <>
              <div className='event-details-line'>
                <div className='event-header'>
                  <div className='event-rating'>
                    <SolidStar className='icon' />
                    <div className='event-rating-value'>
                      {formatRating({ rating: event.rating })}
                    </div>
                    {event.reviewsCount && (
                      <>
                        <div className='event-rating-label'>
                          ({event.reviewsCount})
                        </div>
                      </>
                    )}
                  </div>

                  <div className='event-price'>
                    <CardsBlank className='icon icon-medium' />
                    <span>
                      <span className='event-price-value'>
                        {Number(
                          event?.eventPrices?.find(
                            (e) => e.priceCategoryId === 'adult'
                          )?.price
                        )}{' '}
                        zł
                      </span>
                      {/* <span> {labels[language].perPerson}</span> */}
                    </span>
                  </div>

                  <div className=''>
                    <UserCard
                      user={{
                        avatarId: event.supplierAvatarId,
                        videoId: event.supplierVideoId,
                        firstName: event.supplierFirstName,
                        lastName: event.supplierLastName,
                        username: event.supplierUsername,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className='event-desc'
                // dangerouslySetInnerHTML={{ __html: event.description }}
              >
                {event.description}
              </div>

              <div className='about'>
                <div className='header-semi'>{labels[language].details}</div>
                <div className='activities'>
                  <div className='item'>
                    <div className='icon'>
                      <RegularClock />
                    </div>
                    <div className='item-details'>
                      <div className='item-header'>
                        {labels[language].duration}
                      </div>
                      <div className='item-subdesc'>
                        {Number(Number(event.durationHoursFrom).toFixed(1))}
                        {''}
                        {Boolean(Number(event.durationHoursTo))
                          ? ` - ${Number(
                              Number(event.durationHoursTo).toFixed(1)
                            )} `
                          : ''}{' '}
                        {labels[language].hours}
                      </div>
                      {/* <div className='item-subdesc'>
                  Check availability to see starting times
                </div> */}
                    </div>
                  </div>

                  {/* <div className='item'>
              <div className='icon'>
                <i className='fa-regular fa-bolt' />
              </div>
              <div className='item-details'>
                <div className='item-header'>Wchodzimy bez kolejki</div>
                <div className='item-subdesc'>
                  Przewodnik kupuje bilety w osobnej kasie
                </div>
              </div>
            </div> */}

                  {/* <div className='item-header'>
                  Skip the line through a separate entrance
                </div>
                <div className='item-subdesc'>Save up to 2-3 hours</div> */}

                  {event?.eventSlots?.[0]?.ticketsCount < 21 && (
                    <div className='item'>
                      <div className='icon'>
                        <RegularFaceSmile />
                      </div>
                      <div className='item-details'>
                        {/* <div className='item-header'>Free cancellation</div>
                  <div className='item-subdesc'>
                    Cancel up to 24 hours in advance for a full refund
                  </div> */}

                        <div className='item-header'>
                          {labels[language].smallGroups}
                        </div>
                        <div className='item-subdesc'>
                          {labels[language].smallGroupsDescription}
                          {event?.eventSlots?.[0]?.ticketsCount}{' '}
                          {labels[language].people}
                        </div>
                      </div>
                    </div>
                  )}

                  {event?.included && (
                    <div className='item'>
                      <div className='icon'>
                        <RegularCheck />
                      </div>
                      <div className='item-details'>
                        {/* <div className='item-header'>Free cancellation</div>
                  <div className='item-subdesc'>
                    Cancel up to 24 hours in advance for a full refund
                  </div> */}
                        <div className='item-header'>
                          {labels[language].includedInTicet}
                        </div>
                        <div className='item-subdesc item-list'>
                          {event?.included}
                        </div>
                      </div>
                    </div>
                  )}

                  {event?.important && (
                    <div className='item'>
                      <div className='icon'>
                        <RegularCircleExclamation />
                      </div>
                      <div className='item-details'>
                        {/* <div className='item-header'>Free cancellation</div>
                  <div className='item-subdesc'>
                    Cancel up to 24 hours in advance for a full refund
                  </div> */}

                        <div className='item-header'>
                          {labels[language].important}
                        </div>
                        <div className='item-subdesc item-list'>
                          {event?.important}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='item'>
                    <div className='icon'>
                      <RegularLocationDot />
                    </div>
                    <div className='item-details'>
                      {/* <div className='item-header'>Free cancellation</div>
                <div className='item-subdesc'>
                  Cancel up to 24 hours in advance for a full refund
                </div> */}
                      <div className='item-header'>
                        {labels[language].startPlace}
                      </div>
                      <div className='item-subdesc'>
                        <a
                          href={event?.startPlaceLink}
                          target='_blank'
                          className='link-basic'
                        >
                          {event?.startPlaceName}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='item'>
                    <div className='icon'>
                      <RegularShieldCheck />
                    </div>
                    <div className='item-details'>
                      {/* <div className='item-header'>Free cancellation</div>
                <div className='item-subdesc'>
                  Cancel up to 24 hours in advance for a full refund
                </div> */}

                      <div className='item-header'>
                        {labels[language].simpleReturn}
                      </div>
                      <div className='item-subdesc'>
                        {labels[language].simpleReturnDescription}
                      </div>
                    </div>
                  </div>
                  {/* <div className='item'>
                <div className='icon'>
                  <i className='fa-regular fa-users' />
                </div>
                <div className='item-details'>
                  <div className='item-header'>
                    Private or small groups available
                  </div>
                  <div className='item-subdesc'>
                    <Link to='/@margaret'>Private Tour</Link>
                  </div> 
                </div>
              </div> */}
                </div>
              </div>
            </>
          )}

          {(isMine || isRoleAdmin) && (
            <div className='event-actions'>
              <Link
                to={`/my-events/edit/${eventId}`}
                className='button-secondary'
              >
                Edytuj
              </Link>

              <Link
                to={`/my-events/edit-event-slots/${eventId}`}
                className='button-secondary'
              >
                Terminy
              </Link>

              <Link
                to={`/my-events/images/${event.eventId}`}
                className='button-secondary'
              >
                Zdjęcia
              </Link>
              {/* <Link
                to={`/my-events/videos/${event.eventId}`}
                className='button-secondary'
              >
                Filmy
              </Link> */}
            </div>
          )}

          {isRoleAdmin && event.status !== STATUS_APPROVED && (
            <div className='event-actions'>
              <div
                className='button-secondary'
                onClick={() => {
                  dispatch({
                    type: 'ADMIN_APPROVE_EVENT',
                    payload: {
                      eventId: event.eventId,
                    },
                  });
                }}
              >
                Approve
              </div>
            </div>
          )}

          {isMine && event.status !== STATUS_APPROVED && (
            <div className='status-pending'>
              Oczekuje na zatwierdzenie przez administratora
            </div>
          )}

          <div
            className='form-book'
            ref={formBookRef}
          >
            <div className='form-header'>{labels[language].buyTickets}</div>

            <div className='form-items'>
              {/* <div className='header-h2'>Wybierz ilość uczestników</div> */}

              {event?.eventPrices?.map((ep) => {
                return (
                  <div
                    className='form-item'
                    key={ep.eventPriceId}
                  >
                    <div className='form-item-info'>
                      <div className='form-item-label event-price-name'>
                        {labels[language][ep.priceCategoryId]}
                      </div>
                      {(Boolean(ep.ageFrom) || Boolean(ep.ageTo)) && (
                        <div className='event-price-age'>
                          {formatAge({
                            ageFrom: ep.ageFrom,
                            ageTo: ep.ageTo,
                          })}
                        </div>
                      )}
                    </div>
                    <div className='form-item-info form-item-price'>
                      {Number(ep.price)} zł
                    </div>
                    <div className='form-inputs'>
                      <div className='form-number-selector'>
                        <SolidCircleMinus
                          className={classNames('icon-button', {
                            'icon-button-disabled':
                              !tickets[ep.priceCategoryId],
                          })}
                          onClick={() => {
                            updateTicketsNumber({
                              priceCategoryId: ep.priceCategoryId,
                              addValue: -1,
                            });
                          }}
                        />
                        <input
                          type='number'
                          value={tickets[ep.priceCategoryId] ?? ''}
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              return;
                            }
                            if (isNaN(Number(e.target.value))) {
                              return;
                            }

                            updateTicketsNumber({
                              priceCategoryId: ep.priceCategoryId,
                              value:
                                e.target.value == ''
                                  ? ''
                                  : Number(e.target.value),
                            });
                          }}
                          onBlur={(e) => {
                            if (e.target.value == '') {
                              updateTicketsNumber({
                                priceCategoryId: ep.priceCategoryId,
                                value: 0,
                              });
                            }
                          }}
                        />
                        <SolidCirclePlus
                          className={classNames('icon-button', {})}
                          onClick={() => {
                            updateTicketsNumber({
                              priceCategoryId: ep.priceCategoryId,
                              addValue: 1,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              {isEventSlotAvailable && (
                <div className='form-item'>
                  <div className='form-item-info'>
                    <div className='form-item-label event-price-name'>
                      {labels[language].total}
                    </div>
                  </div>
                  <div className='form-item-info form-item-price'>
                    {totalAmount.toFixed(2)} zł
                  </div>
                </div>
              )}

              {isDiscounted && (
                <div className='box-info'>
                  {labels[language].discountRequirement}
                </div>
              )}

              {/* <div className='form-item'>
              <div className='form-item-info'>
                <div className='form-item-label'>Language</div>
              </div>
              <div className='form-date-selector'>
                <select>
                  <option>English</option>
                  <option>Polski</option>
                </select>
              </div>
            </div> */}

              {!Boolean(availableEventSlots.length) && (
                <div className='form-box-warning'>
                  {labels[language].noSlotForSuchLargeGroup}
                  <Link
                    to={linkPrivate}
                    className='link-regular'
                  >
                    {labels[language].lookingForPrivateTourCTA}
                  </Link>
                  .
                </div>
              )}

              {totalTicketsNumber > 5 && (
                <div className='form-box-info'>
                  <div>
                    {labels[language].forSuchLargeGroup}{' '}
                    <Link
                      to={linkPrivate}
                      className='link-regular'
                    >
                      {labels[language].lookingForPrivateTourCTA}
                    </Link>
                    .
                  </div>
                </div>
              )}

              {Boolean(availableEventSlots.length) && (
                <>
                  <div className='form-items'>
                    <div className='header-h2 header-h2-space'>
                      {labels[language].datetime}
                    </div>
                  </div>
                  {selectedEventSlot && (
                    <div className='event-selector'>
                      <EventSlotCard
                        eventSlot={selectedEventSlot}
                        event={event}
                        showIcon={availableEventSlots.length > 1}
                        onClick={() => {
                          setIsEventSelectorVisible(true);
                        }}
                      />
                    </div>
                  )}

                  {!selectedEventSlot && (
                    <div className='event-selector'>
                      <EventSlotCard
                        showIcon={true}
                        onClick={() => {
                          setIsEventSelectorVisible(true);
                        }}
                      />
                    </div>
                  )}

                  {!isEventSlotAvailable && (
                    <div className='form-box-warning'>
                      Ten termin nie posiada {totalTicketsNumber} wolnych
                      biletów.
                    </div>
                  )}
                </>
              )}

              {isEventSelectorVisible && (
                <Dialog
                  onClose={() => {
                    setIsEventSelectorVisible(false);
                  }}
                  title={labels[language].datetime}
                >
                  <div className='event-selector dialog-event-selector'>
                    {availableEventSlots.map((eventSlot) => {
                      return (
                        <EventSlotCard
                          key={eventSlot.eventSlotId}
                          eventSlot={eventSlot}
                          event={event}
                          onClick={() => {
                            setSelectedEventSlot(eventSlot);
                            setIsEventSelectorVisible(false);
                          }}
                          className='event-button'
                        />
                      );
                    })}
                  </div>
                </Dialog>
              )}

              {false && participants?.length > 0 && (
                <>
                  <div className='form-items'>
                    <div className='header-h2 header-h2-space'>
                      {labels[language].otherParticipants}
                    </div>
                    <div className='participants'>
                      {participants?.map((participant) => {
                        return (
                          <UserCard
                            key={participant.userId}
                            user={{
                              userId: participant.userId,
                              avatarId: participant.avatarId,
                              username: participant.username,
                              firstName: participant.firstName,
                              lastName: participant.lastName,
                            }}
                            suffix={
                              participant.ticketsCount > 1 &&
                              `(+${participant.ticketsCount - 1})`
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {/*
            <div className='form-item'>
              <div className='form-item-info'>
                <div className='form-item-label'>Data</div>
              </div>
              <div className='form-date-selector'>
                <DatePicker
                  locale='pl'
                  // showIcon
                  // minDate={new Date()}
                  // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                  includeDates={availableDatetimes}
                  calendarStartDay={1}
                  dateFormat='yyyy/MM/dd HH:ii'
                  selected={selectedEventSlot?.datetime}
                  onChange={(date) => {
                    const findFirstEventByDate = availableEvents.find(
                      (e) => e.datetime === date
                    );
                    setSelectedEventSlot(findFirstEventByDate);
                  }}
                />
              </div>
            </div>

            <div className='form-item'>
              <div className='form-item-info'>
                <div className='form-item-label'>Godzina</div>
              </div>
              <div className='form-date-selector'>10:00</div>
            </div>
              */}
            </div>

            {/* <div className=' form-items'>
                        <div className='form-item'>
                          <div className='form-item-label'>Do zapłaty</div>
                          <div className='form-price-value'>
                            {totalAmount} zł
                          </div>
                        </div>
                      </div> */}

            {!isFullyLogged && (
              <SignUp
                isInline={true}
                subHeader={
                  <div>{labels[language].createAccountToCheckout}</div>
                }
              />
            )}

            {isFullyLogged && (
              <div className='form-actions'>
                <button
                  className={classNames('form-book-button button-primary', {
                    'button-disabled':
                      !isFullyLogged || !isEventSlotAvailable || !totalAmount,
                  })}
                  onClick={
                    isFullyLogged &&
                    isEventSlotAvailable &&
                    Boolean(totalAmount)
                      ? handleSumbmit
                      : undefined
                  }
                >
                  {labels[language].buyTickets}
                </button>
              </div>
            )}
          </div>

          <div className='about'>
            <div className='header-semi'>Alternatywne opcje wycieczki</div>
            <div className='activities'>
              <div className='item'>
                <div className='icon'>
                  <MessagePlus />
                </div>
                <div className='item-details'>
                  <div className='item-header'>
                    <Link to={linkPrivate}>
                      {labels[language].lookingForPrivateTour}
                    </Link>
                  </div>
                  <div className='item-subdesc'>
                    <Link
                      to={linkPrivate}
                      className='link-regular'
                    >
                      {labels[language].lookingForPrivateTourCTA}
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className='item'>
                <div className='icon'>
                  <RegularMobileSignal />
                </div>
                <div className='item-details'>
                  <div className='item-header'>
                    Audio AI wirtualny przewodnik
                  </div>
                  <div className='item-subdesc'>
                    <a
                      href='#'
                      target='_blank'
                      className='link-basic'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch({
                          type: 'EVENT_AI',
                          payload: {
                            eventId: event.eventId,
                            product: event?.name,
                          },
                        });
                      }}
                    >
                      Jeśli szukasz wirtualnego przewodnika na komórkę - kliknij
                      tutaj
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className='about'>
            <div className='header-semi'>Organizator</div>
            <UserCardMedium
              user={{
                avatarId: event.supplierAvatarId,
                videoId: event.supplierVideoId,
                firstName: event.supplierFirstName,
                lastName: event.supplierLastName,
                username: event.supplierUsername,
              }}
              suffixElement={
                <div className='guide-details'>
                  {Boolean(event.supplierReviewsCount) && (
                    <div
                      className='icon-pair'
                      data-tooltip-id='tooltip'
                      data-tooltip-content='Ocena'
                    >
                      <SolidStar className='icon' />
                      {Number(Number(event?.supplierRating ?? 0).toFixed(2))} (
                      {event.supplierReviewsCount})
                    </div>
                  )}
                </div>
              }
            />
            <div className='supplier-description'>
              {event.supplierDescription}
            </div>
          </div>
        </div>

        <Reviews
          reviews={event.reviews}
          title='Opinie'
        />

        {!event.reviews?.length && (
          <Reviews
            reviews={event.supplierReviews}
            title='Opinie'
          />
        )}
        {isDescriptionVisible && (
          <EventsList
            events={event.similarEvents}
            title={labels[language].similarEvents}
          />
        )}
      </div>
    </div>
  );
};

export default EventDetails;
