import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { Helmet } from 'react-helmet';
import pl from 'date-fns/locale/pl';

import * as placesSlice from '../../../store/app/placesSlice';
import { Loading } from '../../Loading/Loading';

registerLocale('pl', pl);

export const AdminEditPlace = () => {
  const dispatch = useDispatch();
  const { placeId } = useParams();
  const place = useSelector(placesSlice.selectItem);
  const isStatusPending = useSelector(placesSlice.selectIsStatusPending);

  const initialState = {
    placeId: '',
    name: '',
    link: '',
    address: '',
  };
  const [data, setData] = useState(initialState);

  useEffect(() => {
    if (!placeId || isStatusPending) return;
    if (place?.placeId === placeId) return;
    dispatch({
      type: 'FETCH_PLACE',
      payload: {
        placeId,
      },
    });
  }, [place, placeId]);

  useEffect(() => {
    if (!place) return;
    setData({
      ...initialState,
      ...place,
    });
  }, [place]);

  if (!place && placeId) {
    return (
      <div className='content-filler'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='content'>
      <Helmet>
        <title>Place | Bimbash</title>
      </Helmet>
      <div className='h3'>Place</div>

      <div className='event-info form'>
        <div>Nazwa</div>
        <input
          type='text'
          value={data.name}
          onChange={(e) => {
            setData({
              ...data,
              name: e.target.value,
            });
          }}
        />
      </div>

      <div className='event-info form'>
        <div>Link</div>
        <input
          type='text'
          value={data.link}
          onChange={(e) => {
            setData({
              ...data,
              link: e.target.value,
            });
          }}
        />
      </div>

      <div className='event-info form'>
        <div>Adres</div>
        <textarea
          value={data.address}
          onChange={(e) => {
            setData({
              ...data,
              address: e.target.value,
            });
          }}
        />
      </div>

      <div className='event-actions'>
        <div
          onClick={() => {
            dispatch({
              type: 'SAVE_PLACE',
              payload: data,
            });
          }}
          className='button button-primary'
        >
          Save
        </div>
      </div>
    </div>
  );
};
