import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  showNotification,
  getCountryCode,
  getLanguageCode,
  language,
} from '../../core/utils';
import * as appSlice from '../../store/app/appSlice';
import { Loading } from '../Loading/Loading';
import { labels } from '../../labels';
import classNames from 'classnames';

export const SignUp = ({ darkTheme, isInline, onChange, subHeader }) => {
  const dispatch = useDispatch();
  const isStatusPending = useSelector(appSlice.selectIsStatusPending);
  const utm = useSelector(appSlice.selectUtm);
  const referralUserId = useSelector(appSlice.selectReferralUserId);

  const languageId = getLanguageCode();
  const initialData = {
    darkTheme: darkTheme ? 1 : 0,
    email: '',
    phone: '',
    password: '',
    repeatPassword: '',
    countryId: getCountryCode(),
    languageId,
    isInline,
    referralUserId,
    refPartnerId: '',
    ...utm,
  };

  const [data, setData] = useState(initialData);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    if (!data.email || !data.email.includes('@')) {
      const element = document.getElementById('email');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].email}`,
        type: 'error',
      });
      // const element = document.getElementById('email');
      // element?.scrollIntoView?.(element);
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(data.email).toLowerCase())) {
      const element = document.getElementById('email');
      element?.scrollIntoView?.(element);
      showNotification({
        message: labels[language].invalidEmail,
        type: 'error',
      });
      return;
    }

    if (!data.password) {
      const element = document.getElementById('password');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].password}`,
        type: 'error',
      });
      return;
    }

    if (!data.phone) {
      const element = document.getElementById('phone');
      element?.scrollIntoView?.(element);
      element?.focus?.();
      showNotification({
        message: `${labels[language].fieldIsRequired}: ${labels[language].phoneGeneral}`,
        type: 'error',
      });
      return;
    }

    // if (data.password !== data.repeatPassword) {
    //   const element = document.getElementById('password');
    //   element?.scrollIntoView?.(element);
    //   showNotification({
    //     message: `Passwords doesn't match`,
    //     type: 'error',
    //   });
    //   setData({
    //     ...data,
    //     password: '',
    //     repeatPassword: '',
    //   });
    //   return;
    // }

    dispatch({
      type: 'SIGN_UP',
      payload: data,
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const code = e.which || e.keyCode;
      let charCode = String.fromCharCode(code).toLowerCase();
      if (((e.ctrlKey || e.metaKey) && charCode === 's') || code === 13) {
        handleSave(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleSave]);

  return (
    <form
      className={classNames('form-basic form-sign-up form-centered', {
        'form-inline': isInline,
      })}
      onSubmit={handleSave}
    >
      {!isInline && (
        <Helmet>
          <title>{labels[language].newAccountTourist} | Bimbash</title>
        </Helmet>
      )}
      {!isStatusPending && (
        <>
          <div className='form-header'>
            {labels[language].newAccountTourist}
          </div>
          {subHeader}

          <div className='line line-email'>
            <div
              className='label'
              // data-tooltip-id='tooltip'
              // data-tooltip-content={`We'll send an activation link to this address`}
            >
              {labels[language].email}
            </div>
            <input
              id='email'
              name='email'
              type='text'
              required
              autoComplete='email'
              value={data.email}
              onChange={(e) => {
                const trimmedValue = e.target.value?.trim?.();
                setData({
                  ...data,
                  email: trimmedValue,
                });
                onChange?.({
                  ...data,
                  email: trimmedValue,
                });
              }}
            />
            {/* <div className='info-label'>
              We'll send an activation link to this address
            </div> */}
          </div>

          <div className='line line-email'>
            <div className='label'>{labels[language].phoneGeneral}</div>
            <input
              id='phone'
              name='phone'
              type='text'
              required
              autoComplete='tel'
              value={data.phone}
              onChange={(e) => {
                const trimmedValue = e.target.value?.trim?.();
                setData({
                  ...data,
                  phone: trimmedValue,
                });
                onChange?.({
                  ...data,
                  phone: trimmedValue,
                });
              }}
            />
          </div>

          <div className='line'>
            <div className='label'>{labels[language].password}</div>
            <input
              id='password'
              name='password'
              type={passwordFocused ? 'text' : 'password'}
              autoComplete='new-password'
              value={data.password}
              required
              onFocus={() => {
                setPasswordFocused(true);
              }}
              onBlur={() => {
                setPasswordFocused(false);
              }}
              onClick={() =>
                setData({
                  ...data,
                  password: '',
                  repeatPassword: '',
                })
              }
              onChange={(e) => {
                const trimmedValue = e.target.value?.trim?.();
                setData({
                  ...data,
                  password: trimmedValue,
                });
                onChange?.({
                  ...data,
                  password: trimmedValue,
                });
              }}
            />
          </div>

          {/* <div className='line line-email'>
            <div className='label'>{labels[language].refPartnerId}</div>
            <input
              id='ref-partner-id'
              name='ref-partner-id'
              type='number'
              optional
              value={data.refPartnerId}
              onChange={(e) => {
                const trimmedValue = e.target.value?.trim?.();
                setData({
                  ...data,
                  refPartnerId: trimmedValue,
                });
                onChange?.({
                  ...data,
                  refPartnerId: trimmedValue,
                });
              }}
            />
          </div> */}

          {/* {data.password && data.password !== data.repeatPassword && (
                <div className='line'>
                  <div className='label'>{labels[language].repeatPassword}</div>
                  <input
                    id='repeatPassword'
                    type='password'
                    value={data.repeatPassword}
                    autoComplete='new-password'
                    onClick={() =>
                      setData({
                        ...data,
                        repeatPassword: '',
                      })
                    }
                    onChange={(e) =>
                      setData({
                        ...data,
                        repeatPassword: e.target.value,
                      })
                    }
                  />
                </div>
              )} */}

          <div className=''>
            <div className='line info'>
              <input
                type='checkbox'
                required
                checked={true}
                className='input-checkbox'
                onClick={() => {
                  alert(
                    'Akceptacja regulaminu jest wymagana. Jeśli nie akceptujesz regulaminu, nie możesz założyć konta.'
                  );
                }}
                onChange={() => {}}
              />
              {labels[language].agreeWithTerms}{' '}
              {language === 'en' && (
                <a
                  href='/terms.html'
                  target='_blank'
                  className='link-basic'
                >
                  {labels[language].terms}
                </a>
              )}
              {language === 'pl' && (
                <a
                  href='/regulamin.html'
                  target='_blank'
                  className='link-basic'
                >
                  {labels[language].terms}
                </a>
              )}
            </div>
          </div>

          {/* <submit
            onClick={handleSave}
            type='button'
            className='submit button-submit'
            value={labels[language].createAccount}
          /> */}

          <button
            type='submit'
            className='submit button-submit'
          >
            {labels[language].createAccount}
          </button>

          {!isInline && (
            <>
              <div className='form-info-box'>
                <div>
                  {labels[language].areYouASupplier}{' '}
                  <Link
                    to='/sign-up/supplier'
                    className='link-basic'
                  >
                    {labels[language].createAccountSupplier}
                  </Link>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {isStatusPending && <Loading />}
    </form>
  );
};
