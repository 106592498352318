import { createSlice } from '@reduxjs/toolkit';

const STATUS_DEFAULT = 0;
const STATUS_PENDING = 1;
// const STATUS_ERROR = 2;

const initialState = {
  status: STATUS_DEFAULT,
  lastFetchKey: null,
  item: null,
  items: [],
  isUsernameTaken: 0,
  totalCount: 0,
};

export const aiSlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { app } = payload;
      if (!app) return;
      for (const prop in initialState) {
        if (typeof app[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    reset: (state) => {
      for (const prop in initialState) {
        state[prop] = initialState[prop];
      }
    },
    setStatusPending: (state) => {
      state.status = STATUS_PENDING;
    },
    setStatusDefault: (state) => {
      state.status = STATUS_DEFAULT;
    },
    setLastFetchKey: (state, { payload }) => {
      state.lastFetchKey = payload;
    },
    setItem: (state, { payload }) => {
      state.item = payload;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    setTotalCount: (state, { payload }) => {
      state.totalCount = payload;
    },
    setIsUsernameTaken: (state, { payload }) => {
      state.isUsernameTaken = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  reset,
  setStatusPending,
  setStatusDefault,
  setLastFetchKey,
  setItems,
  setIsUsernameTaken,
  setItem,
  setTotalCount,
} = aiSlice.actions;

export const selectUsers = (state = window.store?.getState?.()) => state?.ai;
export const selectItems = (state) => selectUsers(state)?.items;
export const selectItem = (state) => selectUsers(state)?.item;

export const selectIsStatusPending = (state) =>
  selectUsers(state)?.status === STATUS_PENDING;
export const selectLastFetchKey = (state) => selectUsers(state)?.lastFetchKey;

export const selectIsUsernameTaken = (state) =>
  selectUsers(state)?.isUsernameTaken;
export const selectTotalCount = (state) => selectUsers(state)?.totalCount;

export default aiSlice.reducer;
