import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as aiSlice from '../../store/app/aiSlice';
import { Loading } from '../Loading/Loading';

import { language, cleanLink, showNotification } from '../../core/utils';
import { labels } from '../../labels';

export const EditAI = () => {
  const dispatch = useDispatch();

  let { username = '' } = useParams();
  username = username.replace('@', '');

  const ai = useSelector(aiSlice.selectItem);
  const isUsernameTaken = Boolean(useSelector(aiSlice.selectIsUsernameTaken));
  // const isRoleSupplier = Boolean(user?.isRoleSupplier);

  const initialData = {
    userId: '',
    firstName: '',
    lastName: '',
    description: '',
    website: '',
    username: '',
    publicPhone: '',
    description: '',
    promptShared: '',
    firstMessageText: '',
  };

  const [data, setData] = useState(initialData);

  const checkUsername = () => {
    if (data?.username === username) return;
    dispatch({
      type: 'USERS_CHECK_USERNAME',
      payload: {
        username: data.username,
        paramUsername: username,
      },
    });
  };

  const replaceDefaultUsername = () => {
    if (!data?.username || data.username.includes('user')) {
      setData({
        ...data,
        username: cleanLink(`${data.firstName}-${data.lastName}`),
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'FETCH_AI',
      payload: { username },
    });
  }, [username]);

  useEffect(() => {
    if (!ai) return;
    const values = {};
    Object.keys(ai).forEach((key) => {
      values[key] =
        ai[key] !== null && ai[key] !== undefined ? ai[key] : initialData[key];
    });
    setData({
      ...values,
    });
  }, [ai]);

  const handleSave = () => {
    // if (!data.username) {
    //   showNotification({
    //     message: labels[language].usernameIsRequired,
    //     type: 'error',
    //   });
    //   return;
    // }
    if (isUsernameTaken) {
      showNotification({
        message: labels[language].usernameIsTaken,
        type: 'error',
      });
      return;
    }
    dispatch({
      type: 'SAVE_AI',
      payload: data,
    });
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      const code = e.which || e.keyCode;
      let charCode = String.fromCharCode(code).toLowerCase();
      if ((e.ctrlKey || e.metaKey) && charCode === 's') {
        e.preventDefault();
        handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleSave]);

  const [debouncedValue, setDebouncedValue] = useState(data.username);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(data.username);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [data.username]); // Only re-run the effect if inputValue changes

  useEffect(() => {
    checkUsername();
  }, [debouncedValue]);

  if (!ai) {
    return <Loading />;
  }

  return (
    <div className='content profile'>
      <Helmet>
        <title>{labels[language].editProfile} | Bimbash</title>
      </Helmet>

      <div className='profile-content'>
        <div className='profile-header'>
          <div className=''>
            <h3>{labels[language].editProfile}</h3>
            <div className='form form-profile'>
              <div className='form-line'>
                <label htmlFor='name'>{labels[language].firstName}</label>
                <input
                  type='text'
                  id='name'
                  name='firstName'
                  value={data.firstName}
                  onChange={(e) => {
                    setData({
                      ...data,
                      firstName: e.target.value,
                    });
                  }}
                  onBlur={(e) =>
                    setData({
                      ...data,
                      firstName: e.target.value.trim(),
                    })
                  }
                />
              </div>
              <div className='form-line'>
                <label htmlFor='surname'>{labels[language].lastName}</label>
                <input
                  type='text'
                  id='surname'
                  name='lastName'
                  value={data.lastName}
                  onChange={(e) =>
                    setData({
                      ...data,
                      lastName: e.target.value,
                    })
                  }
                  onBlur={replaceDefaultUsername}
                />
              </div>

              <div className='form-line'>
                <label htmlFor='username'>{labels[language].username}</label>
                <input
                  type='text'
                  id='username'
                  name='username'
                  value={data.username}
                  onChange={(e) =>
                    setData({
                      ...data,
                      username: cleanLink(e.target.value)
                        .replace(/ |\.|\,|\@/gi, '-')
                        .trim(),
                    })
                  }
                />
                {isUsernameTaken && (
                  <div className='box-info'>
                    {labels[language].usernameIsTaken}
                  </div>
                )}
              </div>

              {/* <div className='form-line'>
                <label htmlFor='description'>
                  {labels[language].description}
                </label>
                <textarea
                  id='description'
                  className='description'
                  value={data.description}
                  onChange={(e) =>
                    setData({
                      ...data,
                      description: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    setData({
                      ...data,
                      description: e.target.value.trim(),
                    });
                  }}
                />
              </div> */}

              <div className='form-line'>
                <label htmlFor='description'>
                  {labels[language].promptShared}
                </label>
                <textarea
                  id='description'
                  className='description'
                  value={data.promptShared}
                  onChange={(e) =>
                    setData({
                      ...data,
                      promptShared: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    setData({
                      ...data,
                      promptShared: e.target.value.trim(),
                    });
                  }}
                />
              </div>

              <div className='form-line'>
                <label htmlFor='description'>
                  {labels[language].firstMessageText}
                </label>
                <textarea
                  id='description'
                  className='description'
                  value={data.firstMessageText}
                  onChange={(e) =>
                    setData({
                      ...data,
                      firstMessageText: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    setData({
                      ...data,
                      firstMessageText: e.target.value.trim(),
                    });
                  }}
                />
              </div>

              <div className='form-line form-actions'>
                <button
                  onClick={handleSave}
                  className='button-primary'
                >
                  {labels[language].save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
