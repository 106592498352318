import { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

import { useConnectionSpeed } from '../../core/useConnectionSpeed';
import { getImageWidthAndQuality, isMobile } from '../../core/utils';
import { config } from '../../config';

export const Image = ({
  id,
  defaultWidth,
  forceWidth,
  forceQuality,
  ...props
}) => {
  const [connectionSpeed, updateConnectionSpeed] = useConnectionSpeed();
  useEffect(() => {
    if (!window.lastConnectionSpeed) return;
    updateConnectionSpeed(window.lastConnectionSpeed);
  }, []);

  if (!connectionSpeed) return null;

  if (!id) {
    return (
      <img
        src={`${config.imgHostUrl}/default-avatar3.jpg`}
        alt='guide'
        className='avatar default-avatar'
      />
    );
  }

  return (
    <img
      src={`${config.imgHostUrl}/${getImageWidthAndQuality({
        connectionSpeed,
        width: defaultWidth,
        forceWidth,
        forceQuality,
      })}___${id}_original.jpg`}
      {...props}
    />
  );
};

export const ImageSlider = ({ items, ...props }) => {
  const [connectionSpeed, updateConnectionSpeed] = useConnectionSpeed();
  useEffect(() => {
    if (!window.lastConnectionSpeed) return;
    updateConnectionSpeed(window.lastConnectionSpeed);
  }, []);

  if (!items?.length || !connectionSpeed) return null;

  const defaultWidth = isMobile ? 0 : 400;

  return (
    <ImageGallery
      items={
        items
          .map((image) => ({
            original: `${config.imgHostUrl}/${getImageWidthAndQuality({
              connectionSpeed,
              width: defaultWidth,
              // forceWidth,
              // forceQuality,
            })}___${image.imageId}_original.jpg`,
            thumbnail: `${config.imgHostUrl}/${getImageWidthAndQuality({
              connectionSpeed,
              width: defaultWidth,
              forceWidth: 100,
              // forceQuality,
            })}___${image.imageId}_original.jpg`,
          }))
          .filter((p) => p) ?? []
      }
      slideDuration={300}
      slideInterval={2000}
      {...props}
    />
  );
};
