import { createSlice } from '@reduxjs/toolkit';

const STATUS_DEFAULT = 0;
const STATUS_PENDING = 1;

const initialState = {
  status: STATUS_DEFAULT,
  item: null,
  items: [],
  generatedItem: null,
};

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { app } = payload;
      if (!app) return;
      for (const prop in initialState) {
        if (typeof app[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    reset: (state) => {
      for (const prop in initialState) {
        state[prop] = initialState[prop];
      }
    },
    setStatusPending: (state) => {
      state.status = STATUS_PENDING;
    },
    setStatusDefault: (state) => {
      state.status = STATUS_DEFAULT;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    setItem: (state, { payload }) => {
      state.item = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  reset,
  setStatusPending,
  setStatusDefault,
  setItems,
  setItem,
} = placesSlice.actions;

export const selectEventSlots = (state = window.store?.getState()) =>
  state?.places;
export const selectItems = (state) => selectEventSlots(state)?.items;
export const selectItem = (state) => selectEventSlots(state)?.item;
export const selectIsStatusPending = (state) =>
  selectEventSlots(state)?.status === STATUS_PENDING;
export const selectIsStatusDefault = (state) =>
  selectEventSlots(state)?.status === STATUS_DEFAULT;

export default placesSlice.reducer;
