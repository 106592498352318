import { useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import * as eventsSlice from '../../store/app/eventsSlice';
import { language } from '../../core/utils';
import { labels } from '../../labels';
// import { UploadImage } from '../User/UploadImage';
import { UploadVideo } from '../User/UploadVideo';
import { Loading } from '../Loading/Loading';
import { VideoBox } from '../Feed/VideoBox';
import { Card } from './Card';

export const Videos = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const event = useSelector(eventsSlice.selectItem);

  const dispatchFetchEvent = () => {
    dispatch({
      type: 'FETCH_EVENT',
      payload: {
        eventId,
        includePendingVideos: true,
      },
    });
  };

  useEffect(() => {
    dispatchFetchEvent();
  }, []);

  useEffect(() => {
    if (!event?.eventVideos) return;
    event?.eventVideos.find((video) => {
      if (!video.status) {
        window.mediaTimeout = setTimeout(() => {
          dispatchFetchEvent();
        }, 1000);
      }
    });
    return () => {
      if (window.mediaTimeout) {
        clearTimeout(mediaTimeout);
      }
    };
  }, [event]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const newImages = [...event.eventImages];
      const dragCard = newImages[dragIndex];
      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragCard);

      dispatch({
        type: 'UPDATE_EVENT_IMAGES_ORDER',
        payload: {
          eventImages: newImages,
        },
      });
    },
    [event]
  );

  if (!event) {
    <Loading />;
  }

  return (
    <div className='page-content my-events'>
      <Helmet>
        <title>{labels[language].videos} | Bimbash</title>
      </Helmet>

      <div className='h3'>{labels[language].videos}</div>
      <div>
        <Link
          to={`/event/${event?.eventId}`}
          className='link-regular'
        >
          {event?.name}
        </Link>
      </div>

      <div className='media-actions'>
        <UploadVideo
          title='Wgraj film'
          onUploaded={() => {
            dispatchFetchEvent();
          }}
          eventId={eventId}
        />
      </div>

      <div className='event-video-list'>
        {event?.eventVideos?.map((video, index) => (
          <Card
            key={video.eventVideoId}
            index={index}
            id={video.eventVideoId}
            moveCard={moveCard}
          >
            <div className='event-video'>
              {!Boolean(video.status) && (
                <div className='event-image-processing'>
                  <Loading /> Trwa przetwarzanie pliku...
                </div>
              )}
              {/* <Image
                id={image.imageId}
                forceWidth={image.isProcessing ? 360 : 0}
                forceQuality={image.isProcessing ? 80 : 0}
                defaultWidth={isMobile ? 0 : 400}
                className='event-image'
              /> */}
              {Boolean(video.status) && (
                <VideoBox
                  key={video.eventVideoId}
                  videoId={video.videoId}
                  // autoPlay={Boolean(localStorageAutoplay)}
                  autoPlay={false}
                  isDynamicHeight={false}
                  muted={false}
                  // onPause={() => {
                  //   setLocalStorageAutoplay(0);
                  //   setIsVideoPlaying(false);
                  // }}
                  // onPlay={() => {
                  //   setLocalStorageAutoplay(1);
                  //   setIsVideoPlaying(true);
                  // }}
                  // loop
                  // playsInline={!isIOS}
                  controls
                  className={classNames({
                    // 'event-video-small': !isVideoPlaying,
                  })}
                />
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (!confirm('Czy na pewno chcesz usunąć?')) return;
                  dispatch({
                    type: 'DELETE_EVENT_VIDEO',
                    payload: {
                      eventId: event.eventId,
                      eventVideoId: video.eventVideoId,
                    },
                  });
                }}
                className='button-secondary button-danger'
              >
                Usuń
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
