import { createSlice } from '@reduxjs/toolkit';
// import { tmpAuthToken } from '../../core/utils';

const STATUS_DEFAULT = 0;
const STATUS_PENDING = 1;
// const STATUS_ERROR = 2;
const STATUS_SENT = 3;

const params = new URL(document?.location)?.searchParams;
const authParam = params?.get('auth');
if (authParam) {
  localStorage?.setItem('authToken', authParam);
}
const authToken = localStorage?.getItem('authToken');

if (params?.get('setIsAdmin')) {
  localStorage?.setItem('isAdmin', '1');
}

const referralUserIdFromLocalStorage = localStorage?.getItem('referralUserId');
const referralUserId =
  params?.get('u') ||
  params?.get('ref') ||
  params?.get('referralUserId') ||
  params?.get('userId') ||
  referralUserIdFromLocalStorage ||
  '';
if (referralUserId && referralUserId !== referralUserIdFromLocalStorage) {
  localStorage?.setItem('referralUserId', referralUserId);
}

// save utm_ into localStorage
const utmSourceFromLocalStorage = localStorage?.getItem('utm_source');
let utmSource = params?.get('utm_source') || utmSourceFromLocalStorage || '';

const fbclid = params?.get('fbclid');
if (fbclid && !utmSource) {
  utmSource = 'facebook';
}

if (params?.has('ttclid') || params?.has('ttq')) {
  utmSource = 'tiktok';
}

if (utmSource && utmSource !== utmSourceFromLocalStorage) {
  localStorage?.setItem('utm_source', utmSource);
}

const utmMediumFromLocalStorage = localStorage?.getItem('utm_medium');
const utmMedium = params?.get('utm_medium') || utmMediumFromLocalStorage || '';
if (utmMedium && utmMedium !== utmMediumFromLocalStorage) {
  localStorage?.setItem('utm_medium', utmMedium);
}

const utmCampaignFromLocalStorage = localStorage?.getItem('utm_campaign');
const utmCampaign =
  params?.get('utm_campaign') || utmCampaignFromLocalStorage || '';
if (utmCampaign && utmCampaign !== utmCampaignFromLocalStorage) {
  localStorage?.setItem('utm_campaign', utmCampaign);
}

const utmContentFromLocalStorage = localStorage?.getItem('utm_content');
const utmContent =
  params?.get('utm_content') || utmContentFromLocalStorage || '';
if (utmContent && utmContent !== utmContentFromLocalStorage) {
  localStorage?.setItem('utm_content', utmContent);
}

const utmTermFromLocalStorage = localStorage?.getItem('utm_term');
const utmTerm = params?.get('utm_term') || utmTermFromLocalStorage || '';
if (utmTerm && utmTerm !== utmTermFromLocalStorage) {
  localStorage?.setItem('utm_term', utmTerm);
}

const initialState = {
  status: STATUS_DEFAULT,
  authToken,
  userData: null,
  users: [],
  voices: [],
  prices: {
    eur: 1,
    usd: 1,
    pln: 0.25,
  },
  imageId: 0,
  paymentClientSecret: '',
  usersCount: 0,
  testQuestionsCount: 0,
  order: null,
  impersonateCharacter: null,
  autoStartMode: null,
  redirectUrl: '',
  isMenuOpen: false,
  isFormBookInViewport: false,
  utm: {
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
  },
  referralUserId,
  stats: null,
  tickets: {
    adult: 1,
  },
  isEmailSent: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    preloadState: (state, { payload }) => {
      const { app } = payload;
      if (!app) return;
      for (const prop in initialState) {
        if (typeof app[prop] === 'undefined') {
          state[prop] = initialState[prop];
        }
      }
    },
    reset: (state) => {
      for (const prop in initialState) {
        state[prop] = initialState[prop];
      }
    },
    setActiveWindowId: (state, { payload }) => {
      state.activeWindowId = payload.windowId;
    },
    setStatusPending: (state) => {
      state.status = STATUS_PENDING;
    },
    setStatusDefault: (state) => {
      state.status = STATUS_DEFAULT;
    },
    setStatusSent: (state) => {
      state.status = STATUS_SENT;
    },
    setAuthToken: (state, { payload: { authToken } }) => {
      state.authToken = authToken;
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    setVoices: (state, { payload }) => {
      state.voices = payload;
    },
    setRedirectUrl: (state, { payload }) => {
      state.redirectUrl = payload;
    },
    setUserDataImpersonateCharacter: (state, { payload }) => {
      if (!state.userData) return;
      state.userData.impersonateCharacter = payload;
    },
    setUserDataVoiceAutoSend: (state, { payload }) => {
      state.userData.voiceAutoSend = payload;
    },
    setUserDataAccountUsd: (state, { payload }) => {
      if (!state.userData) return;
      state.userData.accountUsd = payload;
    },
    setProfileData: (state, { payload }) => {
      state.profileData = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setPrices: (state, { payload }) => {
      state.prices = payload;
    },
    setImageId: (state, { payload }) => {
      state.imageId = payload;
    },
    setOrder: (state, { payload }) => {
      state.order = payload;
    },
    setAutoStartMode: (state, { payload }) => {
      state.autoStartMode = payload;
    },
    setHomeData: (state, { payload }) => {
      state.usersCount = payload.usersCount;
      state.testQuestionsCount = payload.testQuestionsCount;
    },
    setIsMenuOpen: (state, { payload }) => {
      state.isMenuOpen = payload;
    },
    setIsFormBookInViewport: (state, { payload }) => {
      state.isFormBookInViewport = payload;
    },
    setStats: (state, { payload }) => {
      state.stats = payload;
    },
    setTickets: (state, { payload }) => {
      state.tickets = payload;
    },
    setIsEmailSent: (state, { payload }) => {
      state.isEmailSent = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  preloadState,
  reset,
  setStatusPending,
  setStatusDefault,
  setAuthToken,
  setUserData,
  setProfileData,
  setUsers,
  setPrices,
  setImageId,
  setOrder,
  setHomeData,
  setUserDataAccountUsd,
  setUserDataImpersonateCharacter,
  setUserDataVoiceAutoSend,
  setVoices,
  setAutoStartMode,
  setStatusSent,
  setRedirectUrl,
  setIsMenuOpen,
  setIsFormBookInViewport,
  setStats,
  setTickets,
  setIsEmailSent,
} = appSlice.actions;

export const selectApp = (state = window.store?.getState()) => state?.app;
export const selectActiveWindowId = (state) => selectApp(state)?.activeWindowId;
export const selectIsStatusPending = (state) =>
  selectApp(state)?.status === STATUS_PENDING;
export const selectIsStatusSent = (state) =>
  selectApp(state)?.status === STATUS_SENT;
export const selectAuthToken = (state) => selectApp(state)?.authToken;
export const selectUserData = (state) => selectApp(state)?.userData;
export const selectProfileData = (state) => selectApp(state)?.profileData;
export const selectUsers = (state) => selectApp(state)?.users;
export const selectVoices = (state) => selectApp(state)?.voices;
export const selectRedirectUrl = (state) => selectApp(state)?.redirectUrl;

export const selectVoicesOptions = (state) => {
  return selectVoices(state)?.map((i) => ({
    label: i.name,
    value: i.voice_id,
    isPublic: i.category !== 'cloned',
    ...i,
  }));
};

export const selectDefaultVoiceOption = (state) => {
  return selectVoicesOptions(state)?.find((i) =>
    i.name.toLowerCase().includes('default')
  );
};

export const selectDefaultVoiceId = (state) => {
  return selectVoices(state)?.find((i) =>
    i.name.toLowerCase().includes('default')
  )?.value;
};

export const selectPrices = (state) => selectApp(state)?.prices;
export const selectImageId = (state) => selectApp(state)?.imageId;
export const selectOrder = (state) => selectApp(state)?.order;
export const selectUsersCount = (state) => selectApp(state)?.usersCount;
export const selectTestQuestionsCount = (state) =>
  selectApp(state)?.testQuestionsCount;
export const selectUserAccountUsd = selectUserData()?.accountUsd ?? 0;
export const selectAutoStartMode = (state) => selectApp(state)?.autoStartMode;
export const selectIsMenuOpen = (state) => selectApp(state)?.isMenuOpen;
export const selectIsFormBookInViewport = (state) =>
  selectApp(state)?.isFormBookInViewport;
export const selectUtm = (state) => selectApp(state)?.utm;
export const selectReferralUserId = (state) => selectApp(state)?.referralUserId;
export const selectStats = (state) => selectApp(state)?.stats;
export const selectTickets = (state) => selectApp(state)?.tickets;
export const selectIsEmailSent = (state) => selectApp(state)?.isEmailSent;

export default appSlice.reducer;
