import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from '../Loading/Loading';
import * as eventsSlice from '../../store/app/eventsSlice';
import { UploadAvatar } from '../User/UploadAvatar';
import { MODE_EVENT } from '../../consts';
// import { UploadVideo } from '../User/UploadVideo';

export const EditEventMedia = ({}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { eventId } = params;
  const dispatch = useDispatch();
  const event = useSelector(eventsSlice.selectItem);
  const isStatusPending = useSelector(eventsSlice.selectIsStatusPending);

  useEffect(() => {
    if (eventId) {
      dispatch({
        type: 'FETCH_EVENT',
        payload: {
          eventId,
        },
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (event) {
      setData({
        ...initialData,
        ...event,
      });
    }
  }, [event]);

  return (
    <div className='page-content'>
      <Helmet>
        <title>Wgraj plik video lub zdjęcie | Bimbash</title>
      </Helmet>
      <div className='h3'>Wgraj plik video lub zdjęcie</div>

      <div className='container-basic'>
        <UploadAvatar
          title='Wgraj zdjęcie'
          onUploaded={() => {
            navigate('/my-events');
          }}
          eventId={eventId}
          mode={MODE_EVENT}
        />
        {/* <UploadVideo
          title='Wgraj video'
          onUploaded={() => {
            navigate('/my-events');
          }}
          eventId={eventId}
        /> */}
      </div>

      <div className='form form-event'>{isStatusPending && <Loading />}</div>
    </div>
  );
};
